define("sportly/templates/components/x-forms/textfield-inset", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "sportly/templates/components/x-forms/textfield-inset.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "clear-value");
        dom.setAttribute(el1, "type", "button");
        dom.setAttribute(el1, "tabindex", "-1");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "flaticon stroke x-2");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [4]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'for');
        morphs[1] = dom.createMorphAt(element0, 0, 0);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createElementMorph(element1);
        return morphs;
      },
      statements: [["attribute", "for", ["concat", [["get", "inputId", ["loc", [null, [1, 14], [1, 21]]]]]]], ["content", "labelText", ["loc", [null, [1, 25], [1, 38]]]], ["inline", "input", [], ["type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [2, 13], [2, 17]]]]], [], []], "id", ["subexpr", "@mut", [["get", "inputId", ["loc", [null, [2, 21], [2, 28]]]]], [], []], "class", "form-control", "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [2, 56], [2, 61]]]]], [], []]], ["loc", [null, [2, 0], [2, 63]]]], ["element", "action", ["clear"], [], ["loc", [null, [3, 28], [3, 46]]]]],
      locals: [],
      templates: []
    };
  })());
});