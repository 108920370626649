define('sportly/components/x-forms/date-input', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        tagName: 'input',
        defaultLayout: null,
        type: 'text',
        attributeBindings: ['placeholder', 'value', 'text'],
        placeholder: '',
        value: '',

        change: function change(e) {
            this.sendAction('onChangeDate', e.target.value);
        },

        didInsertElement: function didInsertElement() {
            this.$().pickadate({
                format: 'dd/mm/yyyy',
                min: moment()
            });
        }
    });
});