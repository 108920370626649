define('sportly/routes/football-game/play', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsFootballGameRsvp) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        setupController: function setupController() {
            var _this = this,
                member = this.controllerFor('footballGame').get('sessionMember');

            if (!this.controllerFor('footballGame').get('isActive')) {
                return _this.transitionTo('footballGame.index');
            }

            if (this.get('controller.isFull')) {
                member.set('rsvpId', _sportlyEnumsFootballGameRsvp['default'].RESERVE);
            } else {
                member.set('rsvpId', _sportlyEnumsFootballGameRsvp['default'].PLAYING);
            }
            return member.save().then(function () {
                _this.transitionTo('footballGame.index');
            });
        }
    });
});