define('sportly/components/x-profiles/friends', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-profiles--friends', 'hasFriends'],
        user: null,
        hasFriends: _ember['default'].computed.notEmpty('friendUsers'),

        friendUsers: _ember['default'].computed('user.followers.@each.user', 'user.following.@each.followed', function () {
            var followerUsers = this.get('user.followers').mapBy('user'),
                followingUsers = this.get('user.following').mapBy('followed');
            return followingUsers.filter(function (followingUser) {
                return followerUsers.findBy('id', followingUser.get('id'));
            });
        })

    });
});