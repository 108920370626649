define('sportly/routes/group/members', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        model: function model() {
            return this.modelFor('group').get('members');
        },

        afterModel: function afterModel(members) {
            return _ember['default'].RSVP.all(members.mapBy('user')).then(function (users) {
                return _ember['default'].RSVP.all(users.mapBy('profile'));
            });
        }
    });
});