define('sportly/components/x-groups/group', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-groups--group'],
        group: null,

        sixMembers: _ember['default'].computed('group.members.[]', function () {
            return this.get('group.members').slice(0, 6);
        }),

        fixtureCount: _ember['default'].computed('group.games.@each.timestamp', 'group.games.@each.isCancelled', function () {
            var now = moment().format('X');
            return this.get('group.games').filter(function (game) {
                if (_ember['default'].isEmpty(game) || game.get('isCancelled')) {
                    return false;
                }
                return game.get('timestamp') > now;
            }).get('length');
        })
    });
});