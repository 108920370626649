define('sportly/routes/group/invite', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsGroupStatus) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    session: _ember['default'].inject.service('session'),

    beforeModel: function beforeModel(transition) {
      var user = this.get('session.user'),
          group = this.modelFor('group');

      this._super(transition);

      return user.get('followers').then(function (followers) {
        return _ember['default'].RSVP.all(followers.mapBy('user'));
      }).then(function () {
        return user.get('following');
      }).then(function (following) {
        return _ember['default'].RSVP.all(following.mapBy('followed'));
      }).then(function (users) {
        return _ember['default'].RSVP.all(users.mapBy('profile'));
      }).then(function () {
        return group.get('members');
      }).then(function (members) {
        return _ember['default'].RSVP.all(members.mapBy('user'));
      }).then(function () {
        return group.get('pendingInvites');
      }).then(function (invites) {
        return _ember['default'].RSVP.all(invites.mapBy('receiver'));
      });
    },
    afterModel: function afterModel() {
      if (this.modelFor('group').get('status') === _sportlyEnumsGroupStatus['default'].DELETED) {
        return this.transitionTo('group.index');
      }
    }

  });
});