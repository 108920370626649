define('sportly/components/x-utils/selectable', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':x-utils--selectable', 'isSelected:is-selected'],
    isSelected: false,

    click: function click() {
      this.toggleProperty('isSelected');
      if (this.get('isSelected')) {
        this.attrs.select();
      } else {
        this.attrs.deselect();
      }
    }

  });
});