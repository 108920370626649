define('sportly/controllers/group/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    group: _ember['default'].inject.controller('group'),
    newPost: null,

    isAdmin: _ember['default'].computed.alias('group.isAdmin'),
    isDeleted: _ember['default'].computed.alias('group.isDeleted'),

    isActive: _ember['default'].computed.alias('group.isActive'),
    canCreateGame: _ember['default'].computed.alias('group.canCreateGame'),
    canEditGame: _ember['default'].computed.alias('group.canEditGame'),
    canCancelGame: _ember['default'].computed.alias('group.canCancelGame'),
    canInvite: _ember['default'].computed.alias('group.canInvite'),
    canJoin: _ember['default'].computed.alias('group.canJoin'),
    canPost: _ember['default'].computed.alias('group.canPost'),

    canShowInvitations: _ember['default'].computed.alias('group.canShowInvites'),
    hasNoInvitations: _ember['default'].computed.not('canShowInvitations'),
    showJoinInHeader: _ember['default'].computed.and('hasNoInvitations', 'canJoin'),

    fixtures: _ember['default'].computed.alias('group.fixtures'),

    myPendingInvites: _ember['default'].computed.alias('group.myPendingInvites')

  });
});