define('sportly/components/x-btn', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    classNameBindings: [':x-btn', 'themeClass', 'hasIcon:has-icon', 'isFullWidth:is-full-width', 'isSmall:is-small', 'isLoading:is-loading'],

    themeClass: _ember['default'].computed('attrs.theme', function () {
      return this.getAttr('theme') + '-theme';
    }),

    hasIcon: _ember['default'].computed.notEmpty('icon'),

    isDisabledState: _ember['default'].computed.or('isDisabled', 'isLoading'),

    actions: {
      click: function click() {
        this.sendAction();
      }
    }

  });
});