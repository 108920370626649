define("sportly/templates/football-game/edit/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["multiple-nodes", "wrong-type"]
          },
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "sportly/templates/football-game/edit/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("header");
          var el2 = dom.createTextNode("\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h4");
          var el3 = dom.createTextNode("Edit football game");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n	");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "x-football-games/edit-form", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [8, 9], [8, 15]]]]], [], []], "homeTeamName", ["subexpr", "@mut", [["get", "model.homeTeam.name", ["loc", [null, [9, 15], [9, 34]]]]], [], []], "awayTeamName", ["subexpr", "@mut", [["get", "model.awayTeam.name", ["loc", [null, [10, 16], [10, 35]]]]], [], []], "venue", ["subexpr", "@mut", [["get", "model.venue", ["loc", [null, [11, 8], [11, 19]]]]], [], []], "gameTypeId", ["subexpr", "@mut", [["get", "model.gameTypeId", ["loc", [null, [12, 14], [12, 30]]]]], [], []], "gameLength", ["subexpr", "@mut", [["get", "model.gameLength", ["loc", [null, [13, 14], [13, 30]]]]], [], []], "playerLimit", ["subexpr", "@mut", [["get", "model.playerLimit", ["loc", [null, [14, 15], [14, 32]]]]], [], []], "localTime", ["subexpr", "@mut", [["get", "model.localTime", ["loc", [null, [15, 13], [15, 28]]]]], [], []], "date", ["subexpr", "@mut", [["get", "model.localDate", ["loc", [null, [16, 8], [16, 23]]]]], [], []], "rsvpOpenTimeDelta", ["subexpr", "@mut", [["get", "model.rsvpOpenTimeDelta", ["loc", [null, [17, 20], [17, 43]]]]], [], []], "rsvpCloseTimeDelta", ["subexpr", "@mut", [["get", "model.rsvpCloseTimeDelta", ["loc", [null, [18, 21], [18, 45]]]]], [], []], "isSaving", ["subexpr", "@mut", [["get", "isSaving", ["loc", [null, [19, 11], [19, 19]]]]], [], []], "cancel", "cancel", "save", "save", "chooseVenue", "chooseVenue"], ["loc", [null, [7, 1], [23, 3]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 0
          }
        },
        "moduleName": "sportly/templates/football-game/edit/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "x-forms/full-form", [], ["class", "edit"], 0, null, ["loc", [null, [1, 0], [25, 22]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});