define('sportly/components/x-group-members/thumbs', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-group-members--thumbs'],

    hasMax: _ember['default'].computed.gt('max', 0),
    showAll: _ember['default'].computed.not('hasMax'),
    memberCount: _ember['default'].computed.alias('members.length'),

    moreCount: _ember['default'].computed('attrs.max', 'memberCount', function () {
      return this.get('memberCount') - this.getAttr('max');
    }),

    hasMore: _ember['default'].computed('attrs.max', 'memberCount', function () {
      return this.get('memberCount') > this.getAttr('max');
    }),

    shownMembers: _ember['default'].computed('attrs.members.[]', function () {
      if (this.get('showAll')) {
        return this.getAttr('members');
      }
      return this.getAttr('members').slice(0, this.getAttr('max'));
    })

  });
});