define('sportly/services/geolocation', ['exports', 'ember', 'sportly/config/environment'], function (exports, _ember, _sportlyConfigEnvironment) {
    exports['default'] = _ember['default'].Object.extend({

        geocoder: null,

        init: function init() {
            L.mapbox.accessToken = _sportlyConfigEnvironment['default'].APP['mapbox-access-token'];
            this.set('geocoder', L.mapbox.geocoder('mapbox.places-v1'));
        },

        geolocate: function geolocate(query) {
            var _this = this;
            return new _ember['default'].RSVP.Promise(function (resolve) {
                _this.get('geocoder').query(query, function (err, data) {
                    resolve(data.latlng);
                });
            });
        }

    });
});