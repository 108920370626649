define('sportly/routes/welcome/profile', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        session: _ember['default'].inject.service('session'),

        model: function model() {
            return this.get('session.user').then(function (user) {
                return user.get('profile');
            });
        },

        actions: {
            'continue': function _continue() {
                var _this = this,
                    profile = this.get('currentModel');

                this.get('geolocationService').geolocate(profile.get('location')).then(function (latlng) {
                    if (_ember['default'].isEmpty(latlng)) {
                        profile.get('errors').add('location', "errors.failed-to-gelocate");
                        return _ember['default'].RSVP.reject();
                    }
                    profile.set('latitude', latlng[0]);
                    profile.set('longitude', latlng[1]);
                    return profile.save();
                }).then(function () {
                    _this.get('mixpanel').peopleSet({
                        "$first_name": profile.get('firstName'),
                        "$last_name": profile.get('lastName'),
                        "location": profile.get('location'),
                        "gender": profile.get('gender'),
                        "dobDay": profile.get('dobDay'),
                        "dobMonth": profile.get('dobMonth'),
                        "dobYear": profile.get('dobYear')
                    });
                    _this.transitionTo('welcome.groups');
                }, function () {});
            },

            didUploadPhoto: function didUploadPhoto(filename) {
                var _this = this;
                this.store.createRecord('photo', {
                    filename: filename
                }).save().then(function (photo) {
                    _this.set('controller.model.photo', photo);
                });
            }
        },

        deactivate: function deactivate() {
            if (this.get('currentModel.hasDirtyAttributes')) {
                this.get('currentModel').rollbackAttributes();
            }
        }
    });
});