define('sportly/routes/welcome/finished', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group-invite/status'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsGroupInviteStatus) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        model: function model() {
            return this.get('session.user.groupInvites').then(function (invites) {
                return invites.filterBy('status', _sportlyEnumsGroupInviteStatus['default'].PENDING);
            });
        },

        afterModel: function afterModel(invites) {
            return _ember['default'].RSVP.all(invites.toArray()).then(function (invites) {
                return _ember['default'].RSVP.all(invites.mapBy('group'));
            }).then(function () {
                return _ember['default'].RSVP.all(invites.mapBy('sender'));
            }).then(function (senders) {
                return _ember['default'].RSVP.all(senders.mapBy('profile'));
            }).then(function (profiles) {
                return _ember['default'].RSVP.all(profiles.mapBy('photo'));
            });
        }

    });
});