define('sportly/routes/profile/follow', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        setupController: function setupController() {

            var _this = this,
                controller = this.controllerFor('profile');

            if (controller.get('iAmFollowing')) {
                return this.transitionTo('profile');
            }

            return this.store.createRecord('follower', {
                user: this.get('session.user'),
                followed: this.get('currentModel.user')
            }).save().then(function (follower) {
                return _this.store.createRecord('story/user-follow', {
                    user: follower.get('user'),
                    followed: follower.get('followed')
                }).save();
            }).then(function (story) {
                _this.get('currentModel.stories').pushObject(story);
                _this.transitionTo('profile');
            })['catch'](function () {
                _this.transitionTo('profile');
            });
        }
    });
});