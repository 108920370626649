define('sportly/components/x-profiles/following', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-profiles--following', 'hasFollowees'],
        user: null,
        hasFollowees: _ember['default'].computed.notEmpty('followingUsers'),

        followingUsers: _ember['default'].computed('user.followers.@each.user', 'user.following.@each.followed', function () {
            var followerUsers = this.get('user.followers').mapBy('user'),
                followingUsers = this.get('user.following').mapBy('followed');
            return followingUsers.filter(function (followingUser) {
                return !followerUsers.isAny('id', followingUser.get('id'));
            });
        })

    });
});