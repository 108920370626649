define('sportly/components/x-groups/upcoming-fixtures', ['exports', 'ember', 'sportly/enums/group/member-role', 'sportly/enums/group/status'], function (exports, _ember, _sportlyEnumsGroupMemberRole, _sportlyEnumsGroupStatus) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-groups--upcoming-fixtures'],
        session: _ember['default'].inject.service('session'),
        group: null,

        hasFixtures: _ember['default'].computed.notEmpty('fixtures'),
        isActive: _ember['default'].computed.equal('group.status', _sportlyEnumsGroupStatus['default'].ACTIVE),
        showCreateGame: _ember['default'].computed.and('isAdmin', 'isActive'),

        fixtures: _ember['default'].computed('group.games.@each.timestamp', 'group.games.@each.isCancelled', function () {
            var now = moment().format('X');
            return this.get('group.games').filter(function (game) {
                if (_ember['default'].isEmpty(game) || game.get('isCancelled')) {
                    return false;
                }
                return game.get('timestamp') > now;
            }).sort(function (a, b) {
                return a.get('timestamp') - b.get('timestamp');
            }).slice(0, 3);
        }),

        isAdmin: _ember['default'].computed('group.members.@each.user', 'group.members.@each.roleId', 'session.user.id', function () {
            return this.get('group.members').filterBy('roleId', _sportlyEnumsGroupMemberRole['default'].ADMIN).any(function (member) {
                return member.get('user.id') === this.get('session.user.id');
            }, this);
        })
    });
});