define('sportly/routes/account-settings/password', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('account-settings').set('pageTitle', 'Change password');
        },

        actions: {
            changePassword: function changePassword() {
                var _this = this,
                    user = this.get('currentModel');

                if (_ember['default'].isEmpty(user.get('newPassword'))) {
                    user.get('errors').add('newPassword', 'New password is required');
                }
                if (_ember['default'].isEmpty(user.get('password'))) {
                    user.get('errors').add('password', 'Password is required');
                }

                if (user.get('errors.length')) {
                    return;
                }

                user.save().then(function () {
                    _this.transitionTo('accountSettings.index');
                })['catch'](function () {});
            },

            cancel: function cancel() {
                this.transitionTo('accountSettings.index');
            }

        },

        deactivate: function deactivate() {
            if (this.get('currentModel.hasDirtyAttributes')) {
                this.get('currentModel').rollbackAttributes();
            }
        }
    });
});