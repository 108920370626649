define('sportly/components/x-map/map', ['exports', 'ember', 'sportly/config/environment'], function (exports, _ember, _sportlyConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-map--map'],

        map: null,
        mapId: null,

        attributionControl: false,
        closePopupOnClick: false,
        defaultZoom: 14,
        latitude: 51.52241547215775,
        longitude: -0.7219000000000131,
        minZoom: 13,
        scrollWheelZoom: false,
        zoom: 14,
        query: '',
        places: [],
        placeId: null,
        infobox: null,

        geocoder: null,
        markerClusterGroup: null,
        markers: [],
        marker: null,
        isLocating: false,

        markerIconOptions: {
            'marker-size': 'large',
            'marker-color': '009FDA',
            'marker-symbol': 'marker'
        },
        markerOptions: {},

        hasInfobox: _ember['default'].computed.notEmpty('infobox'),

        didInsertElement: function didInsertElement() {

            var _this = this,
                map = null,
                markerClusterGroup = null;

            L.mapbox.accessToken = _sportlyConfigEnvironment['default'].APP['mapbox-access-token'];

            map = L.mapbox.map(this.$('.x-map-container').get(0), this.get('mapId'), {
                scrollWheelZoom: this.get('scrollWheelZoom'),
                minZoom: this.get('minZoom'),
                closePopupOnClick: this.get('closePopupOnClick'),
                center: [this.get('latitude'), this.get('longitude')],
                zoom: this.get('zoom'),
                attributionControl: this.get('attributionControl')
            });
            map.on('locationfound', function (e) {
                map.fitBounds(e.bounds);
                map.setZoom(_this.get('defaultZoom'));
                _this.sendAction('onLocationFound');
            });
            map.on('moveend', function () {
                _this.sendMapMoveAction();
            });
            this.set('map', map);

            markerClusterGroup = new L.MarkerClusterGroup({
                animateAddingMarkers: true
            });
            map.addLayer(markerClusterGroup);
            this.set('markerClusterGroup', markerClusterGroup);
            this.set('markers', []);
            this.set('geocoder', L.mapbox.geocoder('mapbox.places-v1'));

            this.sendMapMoveAction();
            this.placesDidUpdate();
        },

        willDestroyElement: function willDestroyElement() {
            this.get('map').remove();
        },

        sendMapMoveAction: function sendMapMoveAction() {
            var map = this.get('map');
            this.sendAction('onMapMove', {
                latitude: map.getCenter().lat,
                longitude: map.getCenter().lng,
                zoom: map.getZoom(),
                latitudeStart: map.getBounds().getSouthWest().lat,
                latitudeFinish: map.getBounds().getNorthEast().lat,
                longitudeStart: map.getBounds().getSouthWest().lng,
                longitudeFinish: map.getBounds().getNorthEast().lng
            });
        },

        placesDidUpdate: _ember['default'].observer('places', function () {
            var _this = this,
                markers = this.get('markers'),
                markerClusterGroup = this.get('markerClusterGroup'),
                places = this.get('places'),
                newMarkers = [],
                selectedMarker = null,
                markerOptions = this.get('markerOptions'),
                hasInfobox = this.get('hasInfobox');

            markerOptions.icon = L.mapbox.marker.icon(this.get('markerIconOptions'));

            var removedMarkers = markers.filter(function (marker) {
                return !places.findBy('id', marker.place.get('id'));
            });

            places.forEach(function (place) {
                var marker = null;

                marker = markers.findBy('place', place);
                if (!_ember['default'].isEmpty(marker)) {
                    return;
                }

                markerOptions.title = place.get('name');

                marker = window.L.marker(new window.L.LatLng(place.get('latitude'), place.get('longitude')), markerOptions);

                marker.place = place;

                if (hasInfobox) {
                    marker.on('popupopen', function () {
                        _this.onPopupOpen(this);
                    });
                    marker.on('popupclose', function () {
                        _this.onPopupClose(this);
                    });
                    marker.bindPopup();
                }

                if (markerOptions.draggable) {
                    marker.on('dragend', function () {
                        marker.place.setProperties({
                            latitude: marker.getLatLng().lat,
                            longitude: marker.getLatLng().lng
                        });
                    });
                }

                newMarkers.pushObject(marker);

                if (_this.get('placeId') === place.get('id')) {
                    selectedMarker = marker;
                }
            });

            markers.removeObjects(removedMarkers);
            markers.pushObjects(newMarkers);
            markerClusterGroup.removeLayers(removedMarkers);
            markerClusterGroup.addLayers(newMarkers);

            if (hasInfobox && !_ember['default'].isEmpty(selectedMarker) && this.get('marker') !== selectedMarker) {
                selectedMarker.openPopup();
            }
        }),

        onPopupOpen: function onPopupOpen(marker) {
            this.set('marker', marker);
            this.sendAction('onSelectPlace', marker.place);
        },

        onPopupClose: function onPopupClose() {
            this.set('marker', null);
            this.sendAction('onDeselectPlace');
        },

        searchMap: _ember['default'].observer('query', function () {
            var map = this.get('map');

            this.get('geocoder').query(this.get('query'), function (err, data) {
                if (data.lbounds) {
                    map.fitBounds(data.lbounds);
                } else if (data.latlng) {
                    map.setView([data.latlng[0], data.latlng[1]], 13);
                }
            });
        }),

        onLocateUser: _ember['default'].observer('isLocatingUser', function () {
            if (this.get('isLocatingUser')) {
                this.get('map').locate();
            }
        }),

        actions: {
            onSetInfoboxContent: function onSetInfoboxContent(html) {
                this.get('marker').setPopupContent(html);
            }
        }

    });
});