define('sportly/helpers/str-nl2br', ['exports', 'ember'], function (exports, _ember) {
    exports.strNl2br = strNl2br;

    function strNl2br(input) {
        var breakTag = '<br />';

        return new _ember['default'].Handlebars.SafeString((input + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2'));
    }

    exports['default'] = _ember['default'].Helper.helper(strNl2br);
});