define('sportly/routes/football-game/create/choose-venue', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        queryParams: {
            latitude: {
                replace: true
            },
            longitude: {
                replace: true
            },
            zoom: {
                replace: true
            },
            query: {
                replace: true
            }
        },

        setupController: function setupController(controller) {

            var profile = this.get('session.user.profile'),
                footballGame = this.modelFor('footballGame.create');

            if (controller.get('latitude') !== 0 && controller.get('longitude') !== 0) {
                return;
            }

            this.controllerFor('football-game').set('pageTitle', 'Choose venue');

            controller.set('latitude', parseFloat(profile.get('latitude')));
            controller.set('longitude', parseFloat(profile.get('longitude')));

            if (!_ember['default'].isEmpty(footballGame.get('venue'))) {
                controller.set('latitude', parseFloat(footballGame.get('venue.latitude')));
                controller.set('longitude', parseFloat(footballGame.get('venue.longitude')));
            }
        },

        actions: {

            chooseVenue: function chooseVenue(venue) {
                this.controllerFor('footballGame.create.index').set('venue', venue);
                this.transitionTo('footballGame.create.index');
            },

            addVenue: function addVenue() {
                this.transitionTo('footballGame.create.addVenue', {
                    queryParams: {
                        latitude: this.get('controller.latitude'),
                        longitude: this.get('controller.longitude'),
                        zoom: this.get('controller.zoom')
                    }
                });
            },

            cancel: function cancel() {
                this.transitionTo('footballGame.create');
            }

        }

    });
});