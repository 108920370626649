define('sportly/components/x-profiles/upcoming-fixtures', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-profiles--upcoming-fixtures'],
        session: _ember['default'].inject.service('session'),
        profile: null,

        hasFixtures: _ember['default'].computed.notEmpty('fixtures'),
        games: _ember['default'].computed.mapBy('profile.user.gameMembers', 'footballGame'),

        isSessionUser: _ember['default'].computed('profile.user.id', 'session.user.id', function () {
            return this.get('profile.user.id') === this.get('session.user.id');
        }),

        fixtures: _ember['default'].computed('games.@each.timestamp', 'games.@each.isCancelled', function () {
            var now = moment().format('X');
            return this.get('games').filter(function (game) {
                if (_ember['default'].isEmpty(game) || game.get('isCancelled')) {
                    return false;
                }
                return game.get('timestamp') > now;
            }).sort(function (a, b) {
                return a.get('timestamp') - b.get('timestamp');
            }).slice(0, 3);
        })
    });
});