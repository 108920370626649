define('sportly/routes/guest/reset-password/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.createRecord('reset-token');
    },

    actions: {
      willTransition: function willTransition() {
        var model = this.get('currentModel');
        if (model.get('isNew')) {
          model.deleteRecord();
        }
      }
    }
  });
});