define('sportly/routes/football-game/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        model: function model() {
            return this.modelFor('footballGame');
        },

        setupController: function setupController(controller, footballGame) {

            this.controllerFor('footballGame').set('isHeaderHidden', true);
            if (!this.controllerFor('footballGame').get('isAdmin') || footballGame.get('isFinished') || footballGame.get('isCancelled')) {
                this.transitionTo('footballGame.index');
            }

            controller.get('errors').clear();
            controller.set('model', footballGame);
        },

        actions: {
            save: function save(params) {

                var _this = this,
                    footballGame = this.get('currentModel'),
                    controller = this.get('controller');

                footballGame.set('homeTeam.name', params.homeTeamName);
                footballGame.set('awayTeam.name', params.awayTeamName);
                footballGame.setProperties({
                    venue: params.venue,
                    gameTypeId: params.gameTypeId,
                    gameLength: params.gameLength,
                    playerLimit: params.playerLimit,
                    localTime: params.localTime,
                    localDate: params.localDate,
                    rsvpOpenTimeDelta: params.rsvpOpenTimeDelta,
                    rsvpCloseTimeDelta: params.rsvpCloseTimeDelta
                });

                _ember['default'].RSVP.all([footballGame.get('homeTeam').save(), footballGame.get('awayTeam').save(), footballGame.save()]).then(function () {
                    _this.transitionTo('footballGame.index');
                })['catch'](function (e) {
                    //TODO: This wont catch server team name errors
                    e.errors.forEach(function (error) {
                        var key = error.source.pointer.substring(16);
                        controller.get('errors').add(key, error.detail);
                    });
                    controller.set('isSaving', false);
                });
            },

            cancel: function cancel() {
                this.transitionTo('footballGame.settings');
            }

        },

        deactivate: function deactivate() {

            this.controllerFor('footballGame').set('isHeaderHidden', false);

            var footballGame = this.get('currentModel');
            if (footballGame.get('hasDirtyAttributes')) {
                footballGame.rollbackAttributes();
            }
            if (footballGame.get('homeTeam.hasDirtyAttributes')) {
                footballGame.get('homeTeam').rollbackAttributes();
            }
            if (footballGame.get('awayTeam.hasDirtyAttributes')) {
                footballGame.get('awayTeam').rollbackAttributes();
            }
        }

    });
});