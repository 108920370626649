define('sportly/routes/notifications', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        model: function model() {
            return this.get('session.user.notifications');
        },

        actions: {
            willTransition: function willTransition() {
                var notifications = this.get('currentModel');
                notifications.invoke('set', 'unread', false);
                _ember['default'].RSVP.all(notifications.invoke('save').toArray());
                return true;
            }
        }

    });
});