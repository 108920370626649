define('sportly/routes/football-game/cancel', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        model: function model() {
            return this.modelFor('footballGame');
        },

        setupController: function setupController(controller, footballGame) {
            controller.set('model', footballGame);
            if (!this.controllerFor('footballGame').get('canEditGame')) {
                this.transitionTo('footballGame.index');
            }
            this.controllerFor('football-game').set('pageTitle', 'Cancel game');
        },

        actions: {
            cancelGame: function cancelGame() {
                var _this = this,
                    footballGame = this.get('currentModel');
                footballGame.set('isCancelled', true);
                footballGame.save().then(function () {
                    return _this.store.createRecord('story/football-game-cancel', {
                        user: _this.get('session.user'),
                        footballGame: footballGame
                    }).save();
                }).then(function (story) {
                    footballGame.get('stories').pushObject(story);
                    _this.transitionTo('footballGame.index');
                });
            },

            cancel: function cancel() {
                this.transitionTo('footballGame.settings');
            }
        },

        deactivate: function deactivate() {
            if (this.get('currentModel.hasDirtyAttributes')) {
                this.get('currentModel').rollbackAttributes();
            }
        }

    });
});