define('sportly/components/x-football-games/rsvps', ['exports', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, _ember, _sportlyEnumsFootballGameRsvp) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-football-games--rsvps', 'isTiny'],
        footballGame: null,
        showPlayerTooltips: true,
        linkPlayers: true,
        isTiny: false,

        hasUnlimitedSpaces: _ember['default'].computed.equal('footballGame.playerLimit', 0),

        players: _ember['default'].computed('footballGame.members.@each.rsvpId', function () {
            return this.get('footballGame.members').filterBy('rsvpId', _sportlyEnumsFootballGameRsvp['default'].PLAYING);
        }),

        spaces: _ember['default'].computed('footballGame.playerLimit', 'players.[]', function () {
            var spaces = [],
                players = this.get('players');
            if (this.get('footballGame.playerLimit') === 0) {
                return spaces;
            }
            for (var i = 0; i < this.get('footballGame.playerLimit'); i++) {
                if (!_ember['default'].isEmpty(players[i])) {
                    spaces.pushObject({
                        index: i + 1,
                        player: players[i]
                    });
                } else {
                    spaces.pushObject({
                        index: i + 1,
                        player: null
                    });
                }
            }
            return spaces;
        })
    });
});