define('sportly/models/group', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({

        url: _emberData['default'].attr('string'),
        name: _emberData['default'].attr('string'),
        description: _emberData['default'].attr('string'),
        venue: _emberData['default'].belongsTo('venue', { async: true, inverse: 'groups' }),
        games: _emberData['default'].hasMany('football-game', { async: true, inverse: 'group' }),
        members: _emberData['default'].hasMany('group-member', { async: true, inverse: 'group' }),
        pendingInvites: _emberData['default'].hasMany('group-invite', { async: true }),
        status: _emberData['default'].attr('number'),
        stories: _emberData['default'].hasMany('story', { async: true, polymorphic: true })
    });
});