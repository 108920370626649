define('sportly/routes/guest/reset-password/change-password', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    model: function model(params) {
      var _this = this;
      return this.store.queryRecord('reset-token', {
        guid: params.guid
      }).then(function (resetToken) {
        return _ember['default'].RSVP.hash({
          resetToken: resetToken,
          passwordChange: _this.store.createRecord('password-change', {
            resetTokenGuid: resetToken.get('guid'),
            password: ''
          })
        });
      });
    },

    afterModel: function afterModel(models) {
      return models.resetToken.get('user').then(function (user) {
        return user.get('profile');
      }).then(function (profile) {
        return profile.get('photo');
      });
    },

    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('isDone', false);
    }

  });
});