define('sportly/controllers/profile-image', ['exports', 'ember', 'sportly/config/environment'], function (exports, _ember, _sportlyConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({

        session: _ember['default'].inject.service('session'),

        api: {
            host: _sportlyConfigEnvironment['default'].API.host,
            namespace: _sportlyConfigEnvironment['default'].API.namespace
        },
        type: 'photo',

        //Doesnt work. Em doesnt seem to update the button (and removed disabled attr) when this value changes
        saveDisabled: _ember['default'].computed('model', 'session.user.profile.photo', function () {
            return this.get('model') === this.get('session.user.profile.photo');
        })
    });
});