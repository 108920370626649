define('sportly/components/x-groups/groups-summary', ['exports', 'ember', 'sportly/enums/group/status'], function (exports, _ember, _sportlyEnumsGroupStatus) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-groups--groups-summary'],
        user: null,
        showActions: false,

        hasGroups: _ember['default'].computed.notEmpty('groups'),

        groups: _ember['default'].computed('user.groupMembers.@each.group', function () {
            return this.get('user.groupMembers').mapBy('group').filter(function (group) {
                return group.get('status') !== _sportlyEnumsGroupStatus['default'].DELETED;
            }).slice(0, 5);
        })

    });
});