define('sportly/routes/guest/join/invitation', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {

        afterModel: function afterModel(appInvite) {
            return appInvite.get('sender').then(function (sender) {
                return sender.get('profile');
            }).then(function (profile) {
                return profile.get('photo');
            });
        },

        setupController: function setupController(controller, appInvite) {
            controller.set('model', appInvite);
            this.controllerFor('guest.join').set('appInvite', appInvite);
            this.controllerFor('guest.join').set('email', appInvite.get('emailAddress'));
        },

        deactivate: function deactivate() {
            this.controllerFor('guest.join').set('appInvite', null);
            this.controllerFor('guest.join').set('email', null);
        }

    });
});