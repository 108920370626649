define('sportly/controllers/group/invite', ['exports', 'ember', 'sportly/enums/group/status'], function (exports, _ember, _sportlyEnumsGroupStatus) {
  exports['default'] = _ember['default'].Controller.extend({

    session: _ember['default'].inject.service('session'),

    queryParams: ['query'],

    query: '',

    group: _ember['default'].inject.controller('group'),

    isInviting: false,

    actions: {
      done: function done() {
        var group = this.get('model'),
            _this = this;

        if (group.get('status') === _sportlyEnumsGroupStatus['default'].NOOB) {
          group.set('status', _sportlyEnumsGroupStatus['default'].ACTIVE);
          group.save().then(function () {
            _this.transitionToRoute('group', group);
          });
        } else {
          _this.transitionToRoute('group', group);
        }
      },

      invite: function invite(friends, emailAddresses) {
        var controller = this,
            group = this.get('model');

        this.set('isInviting', true);

        if (_ember['default'].isEmpty(friends) && _ember['default'].isEmpty(emailAddresses)) {
          return;
        }

        this.inviteEmailAddresses(emailAddresses).then(function () {
          return controller.inviteFriends(friends);
        }).then(function () {
          if (group.get('status') === _sportlyEnumsGroupStatus['default'].NOOB) {
            group.set('status', _sportlyEnumsGroupStatus['default'].ACTIVE);
          }
          return group.save();
        }).then(function () {
          controller.set('isInviting', false);
          controller.transitionToRoute('group.index', controller.get('model'));
        });
      }
    },

    inviteEmailAddresses: function inviteEmailAddresses(emailAddresses) {
      var _this = this,
          group = this.get('model'),
          sender = this.get('session.user');

      return this.store.query('user', {
        emailAddresses: emailAddresses
      }).then(function (users) {
        var userEmailAddresses = users.mapBy('email');
        return emailAddresses.filter(function (emailAddress) {
          return !userEmailAddresses.contains(emailAddress);
        });
      }).then(function (newEmailAddresses) {
        var appInvites = [];
        newEmailAddresses.forEach(function (emailAddress) {
          appInvites.pushObject(_this.store.createRecord('app-invite', {
            sender: sender,
            emailAddress: emailAddress,
            suppressNotification: true
          }));
        });
        return _ember['default'].RSVP.all(appInvites.invoke('save'));
      }).then(function () {
        return _this.store.query('user', {
          emailAddresses: emailAddresses
        });
      }).then(function (receivers) {
        return _ember['default'].RSVP.all(receivers.map(function (receiver) {
          return _this.store.createRecord('group-invite', {
            group: group,
            sender: sender,
            receiver: receiver,
            status: 1
          }).save();
        }));
      });
    },

    inviteFriends: function inviteFriends(friends) {
      var _this = this,
          sender = this.get('session.user'),
          group = this.get('model');

      return _ember['default'].RSVP.all(friends.map(function (user) {
        return _this.store.createRecord('group-invite', {
          group: group,
          sender: sender,
          receiver: user,
          status: 1
        });
      }).invoke('save'));
    }

  });
});