define("sportly/templates/group/about", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 1
              },
              "end": {
                "line": 10,
                "column": 1
              }
            },
            "moduleName": "sportly/templates/group/about.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("		");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "x-group/invitation", [], ["group", ["subexpr", "@mut", [["get", "groups", ["loc", [null, [6, 9], [6, 15]]]]], [], []], "myPendingInvites", ["subexpr", "@mut", [["get", "myPendingInvites", ["loc", [null, [7, 20], [7, 36]]]]], [], []], "join", ["subexpr", "action", ["join"], ["target", ["get", "group", ["loc", [null, [8, 30], [8, 35]]]]], ["loc", [null, [8, 8], [8, 36]]]]], ["loc", [null, [5, 2], [9, 4]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.3",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 1
              },
              "end": {
                "line": 34,
                "column": 1
              }
            },
            "moduleName": "sportly/templates/group/about.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("\n		");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n		");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n		");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            return morphs;
          },
          statements: [["inline", "x-group/header", [], ["name", ["subexpr", "@mut", [["get", "group.model.name", ["loc", [null, [15, 8], [15, 24]]]]], [], []], "members", ["subexpr", "@mut", [["get", "group.model.members", ["loc", [null, [16, 11], [16, 30]]]]], [], []], "canJoin", ["subexpr", "@mut", [["get", "showJoinInHeader", ["loc", [null, [17, 11], [17, 27]]]]], [], []], "canInvite", ["subexpr", "@mut", [["get", "group.canInvite", ["loc", [null, [18, 13], [18, 28]]]]], [], []], "isMember", ["subexpr", "@mut", [["get", "group.isMember", ["loc", [null, [19, 12], [19, 26]]]]], [], []], "status", ["subexpr", "@mut", [["get", "group.model.status", ["loc", [null, [20, 10], [20, 28]]]]], [], []], "selectMembers", ["subexpr", "action", ["selectMembers"], ["target", ["get", "group", ["loc", [null, [21, 48], [21, 53]]]]], ["loc", [null, [21, 17], [21, 54]]]], "inviteMembers", ["subexpr", "action", ["inviteMembers"], ["target", ["get", "group", ["loc", [null, [22, 48], [22, 53]]]]], ["loc", [null, [22, 17], [22, 54]]]], "join", ["subexpr", "action", ["join"], ["target", ["get", "group", ["loc", [null, [23, 30], [23, 35]]]]], ["loc", [null, [23, 8], [23, 36]]]]], ["loc", [null, [14, 2], [24, 4]]]], ["content", "x-group/nav", ["loc", [null, [26, 2], [26, 17]]]], ["inline", "x-group/about", [], ["description", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [29, 15], [29, 32]]]]], [], []], "venue", ["subexpr", "@mut", [["get", "model.venue", ["loc", [null, [30, 9], [30, 20]]]]], [], []], "isAdmin", ["subexpr", "@mut", [["get", "group.isAdmin", ["loc", [null, [31, 11], [31, 24]]]]], [], []]], ["loc", [null, [28, 2], [32, 4]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type", "multiple-nodes"]
          },
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 35,
              "column": 0
            }
          },
          "moduleName": "sportly/templates/group/about.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "canShowInvitations", ["loc", [null, [4, 7], [4, 25]]]]], [], 0, null, ["loc", [null, [4, 1], [10, 8]]]], ["block", "x-group/layout", [], [], 1, null, ["loc", [null, [12, 1], [34, 20]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 18
          }
        },
        "moduleName": "sportly/templates/group/about.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "x-global/navs", [], ["pageTitle", "About"], 0, null, ["loc", [null, [1, 0], [35, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});