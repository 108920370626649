define('sportly/routes/invite-friends', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        setupController: function setupController(controller) {
            controller.set('invited', []);
        },

        actions: {

            inviteFriends: function inviteFriends() {

                var route = this,
                    promises = [],
                    emailAddresses = this.get('controller.emailAddresses');

                if (_ember['default'].isEmpty(emailAddresses)) {
                    return false;
                }

                emailAddresses.forEach(function (emailAddress) {
                    promises.pushObject(route.store.createRecord('app-invite', {
                        sender: route.get('session.user'),
                        emailAddress: emailAddress
                    }));
                });

                _ember['default'].RSVP.all(promises.invoke('save')).then(function () {
                    route.set('controller.emailAddressesInput', null);
                    route.set('controller.invited', emailAddresses);
                }, function () {
                    route.set('controller.emailAddressesInput', null);
                    route.set('controller.invited', emailAddresses);
                });
            },

            cancel: function cancel() {
                this.set('controller.emailAddressesInput', null);
                this.set('controller.invited', []);
                this.transitionTo('dashboard');
            }
        }
    });
});