define('sportly/components/x-welcome/profile-form', ['exports', 'ember', 'sportly/config/environment'], function (exports, _ember, _sportlyConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-welcome--profile-form'],
        profile: null,

        api: {
            host: _sportlyConfigEnvironment['default'].API.host,
            namespace: _sportlyConfigEnvironment['default'].API.namespace
        },
        uploadType: 'photo',

        genders: _ember['default'].computed(function () {
            return [{ label: 'Male', value: 'm' }, { label: 'Female', value: 'f' }];
        }),

        dobDays: _ember['default'].computed('profile.dobMonth', 'profile.dobYear', function () {
            var daysInMonth = null,
                days = [];

            daysInMonth = moment().startOf('year').daysInMonth();
            if (!_ember['default'].isEmpty(this.get('profile.dobYear')) && !_ember['default'].isEmpty(this.get('profile.dobMonth'))) {
                daysInMonth = moment(this.get('profile.dobYear') + '-' + this.get('profile.dobMonth'), 'YYYY-MM').daysInMonth();
            }

            for (var day = 1; day <= daysInMonth; day++) {
                days.pushObject({ label: day, value: day });
            }
            return days;
        }),

        dobMonths: _ember['default'].computed(function () {
            return [{ label: 'January', value: 1 }, { label: 'February', value: 2 }, { label: 'March', value: 3 }, { label: 'April', value: 4 }, { label: 'May', value: 5 }, { label: 'June', value: 6 }, { label: 'July', value: 7 }, { label: 'August', value: 8 }, { label: 'September', value: 9 }, { label: 'October', value: 10 }, { label: 'November', value: 11 }, { label: 'December', value: 12 }];
        }),

        dobYears: _ember['default'].computed(function () {
            var years = [],
                lastYear = moment().format('YYYY') - 1;
            for (var year = lastYear; year > 1900; year--) {
                years.pushObject({
                    label: year, value: year
                });
            }
            return years;
        }),

        hasDobError: _ember['default'].computed('profile.errors.dobDay', 'profile.errors.dobMonth', 'profile.errors.dobYear', function () {
            return !_ember['default'].isEmpty(this.get('profile.errors.dobDay')) || !_ember['default'].isEmpty(this.get('profile.errors.dobMonth')) || !_ember['default'].isEmpty(this.get('profile.errors.dobyear'));
        }),

        actions: {
            'continue': function _continue() {
                var profile = this.get('profile');

                if (_ember['default'].isEmpty(profile.get('gender'))) {
                    profile.get('errors').add('gender', 'Gender is required');
                }
                if (_ember['default'].isEmpty(profile.get('dobDay'))) {
                    profile.get('errors').add('dobDay', 'Day is required');
                }
                if (_ember['default'].isEmpty(profile.get('dobMonth'))) {
                    profile.get('errors').add('dobMonth', 'Month is required');
                }
                if (_ember['default'].isEmpty(profile.get('dobYear'))) {
                    profile.get('errors').add('dobYear', 'Year is required');
                }
                if (_ember['default'].isEmpty(profile.get('location'))) {
                    profile.get('errors').add('location', 'Location is required');
                }

                if (!profile.get('isValid')) {
                    return;
                }

                this.sendAction('continue');
            },

            didUploadPhoto: function didUploadPhoto(filename) {
                this.sendAction('didUploadPhoto', filename);
            }
        }
    });
});