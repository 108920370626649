define('sportly/components/x-utils/menu', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-utils--menu', 'showMore', 'hasMoreItems'],

        hasMoreItems: false,
        showMore: false,

        click: function click(e) {

            if (!this.get('hasMoreItems') || _ember['default'].$(e.target).is(this.$('.more'))) {
                return;
            }

            if (this.get('showMore')) {
                this.set('showMore', false);
            }
        },

        actions: {
            toggleMore: function toggleMore() {
                this.toggleProperty('showMore');
            }
        }
    });
});