define('sportly/components/x-title-bar/component', ['exports', 'ember'], function (exports, _ember) {

  var titleBarComponent = _ember['default'].Component.extend({
    classNames: ['x-title-bar']
  });
  titleBarComponent.reopenClass({
    positionalParams: ['title']
  });

  exports['default'] = titleBarComponent;
});