define('sportly/helpers/str-truncate', ['exports', 'ember'], function (exports, _ember) {
  exports.strTruncate = strTruncate;

  function strTruncate(str, params) {
    if (_ember['default'].isEmpty(str[0])) {
      return '';
    }

    if (str[0].length > params.limit) {
      return str[0].substring(0, params.limit - 3) + '...';
    }
    return str[0];
  }

  exports['default'] = _ember['default'].Helper.helper(strTruncate);
});