define('sportly/models/group-invite', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({

        group: _emberData['default'].belongsTo('group', { async: true }),
        sender: _emberData['default'].belongsTo('user', { async: true }),
        receiver: _emberData['default'].belongsTo('user', { async: true }),
        timestamp: _emberData['default'].attr('number'),
        status: _emberData['default'].attr('number')

    });
});