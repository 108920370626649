define('sportly/components/x-global/nav', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':x-global--nav', 'theme', 'isShiftedOut'],
    user: null,
    profile: _ember['default'].computed.alias('user.profile'),
    isLoading: false,
    isShiftedOut: false,
    title: null,
    theme: null,

    hasPageTitle: _ember['default'].computed.notEmpty('title'),

    unreadNotificationsCount: _ember['default'].computed.alias('unreadNotifications.length'),
    hasUnreadNotifications: _ember['default'].computed.gt('unreadNotifications.length', 0),
    unreadNotifications: _ember['default'].computed.filterBy('user.notifications', 'unread', 1)
  });
});