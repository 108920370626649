define('sportly/helpers/format-time', ['exports', 'ember'], function (exports, _ember) {
  exports.formatTime = formatTime;

  function formatTime(time, options) {
    if (options.parse) {
      return moment(time, options.parse).format(options.format);
    }
    return moment(time).format(options.format);
  }

  exports['default'] = _ember['default'].Helper.helper(formatTime);
});