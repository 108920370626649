define("sportly/templates/components/x-group/members", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "sportly/templates/components/x-group/members.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "group-members");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        return morphs;
      },
      statements: [["inline", "x-group/members-header", [], ["memberCount", ["subexpr", "@mut", [["get", "attrs.members.length", ["loc", [null, [3, 16], [3, 36]]]]], [], []], "canInvite", ["subexpr", "@mut", [["get", "attrs.canInvite", ["loc", [null, [4, 14], [4, 29]]]]], [], []]], ["loc", [null, [2, 2], [5, 4]]]], ["inline", "x-group-members/members", [], ["members", ["subexpr", "@mut", [["get", "attrs.members", ["loc", [null, [7, 36], [7, 49]]]]], [], []]], ["loc", [null, [7, 2], [7, 51]]]]],
      locals: [],
      templates: []
    };
  })());
});