define('sportly/components/x-utils/uploader', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-utils--uploader'],
        session: _ember['default'].inject.service('session'),

        type: null,
        host: null,
        namespace: null,
        callToAction: 'Upload picture',

        didInsertElement: function didInsertElement() {

            var _this = this;
            this.$('.btn-upload').fileupload({
                dataType: 'json',
                url: this.get('host') + '/' + this.get('namespace') + '/uploads/' + this.get('type'),
                paramName: 'upload',
                beforeSend: function beforeSend(xhr) {
                    xhr.setRequestHeader("Authorization", "Bearer " + _this.get('session.token'));
                },
                done: function done(e, data) {
                    _this.sendAction('didUpload', data.result.filename);
                }
            });
        }
    });
});