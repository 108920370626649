define('sportly/components/x-group/invite-friends-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    init: function init() {
      this._super.apply(this, arguments);
      this.inviteUsers = [];
      this.inviteEmails = [];
    },

    classNames: ['x-group--invite-friends-form'],

    hasNoFriends: _ember['default'].computed.not('hasFriends'),
    hasInviteables: _ember['default'].computed.notEmpty('inviteables'),
    hasResults: _ember['default'].computed.notEmpty('results'),
    noneSelected: _ember['default'].computed.equal('inviteCount', 0),

    hasNoInviteables: _ember['default'].computed.empty('inviteables.length'),
    allInvited: _ember['default'].computed.and('hasNoInviteables', 'hasFriends'),

    inviteCount: _ember['default'].computed('inviteUsers.length', 'inviteEmails.length', function () {
      return this.get('inviteUsers.length') + this.get('inviteEmails.length');
    }),

    submitLabel: _ember['default'].computed('inviteCount', function () {
      if (this.get('inviteCount') === 0) {
        return "Invite new members";
      }
      if (this.get('inviteCount') === 1) {
        return "Invite 1 new member";
      }
      return "Invite " + this.get('inviteCount') + " new members";
    }),

    results: _ember['default'].computed('attrs.query', 'attrs.inviteables.[]', function () {
      var query = this.getAttr('query');
      return this.getAttr('inviteables').filter(function (friend) {
        if (_ember['default'].isEmpty(query)) {
          return true;
        }
        var name = friend.get('profile.firstName') + ' ' + friend.get('profile.lastName');
        name = name.toLowerCase();
        return name.search(query.toLowerCase()) !== -1;
      });
    }),

    actions: {
      selectUser: function selectUser(user) {
        this.get('inviteUsers').pushObject(user);
      },

      deselectUser: function deselectUser(user) {
        this.get('inviteUsers').removeObject(user);
      },

      invite: function invite() {
        this.attrs.invite(this.get('inviteUsers'), this.get('inviteEmails'));
      }
    }

  });
});