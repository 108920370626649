define('sportly/components/x-group/upcoming-fixtures', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-group--upcoming-fixtures'],

    hasFixtures: _ember['default'].computed.notEmpty('upcomingFixtures'),

    upcomingFixtures: _ember['default'].computed(function () {
      return this.getAttr('fixtures').slice(0, 3);
    })

  });
});