define('sportly/routes/group/leave', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsGroupStatus) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    afterModel: function afterModel(group) {
      if (group.get('status') !== _sportlyEnumsGroupStatus['default'].ACTIVE) {
        return this.transitionTo('group.index');
      }
    }

  });
});