define('sportly/controllers/groups/find', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['latitude', 'longitude', 'zoom', 'venueId', 'query'],

        latitude: 51.52241547215775,
        longitude: -0.7219000000000131,
        zoom: 14,
        venueId: null,
        query: '',

        latitudeStart: 0,
        latitudeFinish: 0,
        longitudeStart: 0,
        longitudeFinish: 0,

        allGroups: [],
        venues: [],
        searchQuery: '',
        isLocatingUser: false,

        fetchVenues: function fetchVenues() {
            var _this = this;
            this.store.query('venue', {
                where: {
                    latitude: {
                        '>': this.get('latitudeStart'),
                        '<': this.get('latitudeFinish')
                    },
                    longitude: {
                        '>': this.get('longitudeStart'),
                        '<': this.get('longitudeFinish')
                    }
                }
            }).then(function (venues) {
                _this.set('venues', venues);
            });
        },

        fetchGroups: function fetchGroups() {
            var _this = this;
            this.store.query('group', {
                latitudeStart: this.get('latitudeStart'),
                latitudeFinish: this.get('latitudeFinish'),
                longitudeStart: this.get('longitudeStart'),
                longitudeFinish: this.get('longitudeFinish')
            }).then(function (groups) {
                _this.set('allGroups', groups);
            });
        },

        groups: _ember['default'].computed('allGroups.[]', 'venueId', function () {
            if (_ember['default'].isEmpty(this.get('venueId'))) {
                return this.get('allGroups');
            }
            return this.get('allGroups').filterBy('venue.id', this.get('venueId'));
        }),

        actions: {
            onMapMove: function onMapMove(props) {
                this.setProperties({
                    'latitude': props.latitude,
                    'longitude': props.longitude,
                    'zoom': props.zoom,
                    'latitudeStart': props.latitudeStart,
                    'latitudeFinish': props.latitudeFinish,
                    'longitudeStart': props.longitudeStart,
                    'longitudeFinish': props.longitudeFinish
                });
                this.fetchVenues();
                this.fetchGroups();
            },

            onSelectPlace: function onSelectPlace(venue) {
                this.set('venueId', venue.get('id'));
            },

            onDeselectPlace: function onDeselectPlace() {
                this.set('venueId', null);
            },

            onLocationFound: function onLocationFound() {
                this.set('isLocatingUser', false);
            },

            onSearch: function onSearch(query) {
                this.set('searchQuery', query);
            },

            onLocateUser: function onLocateUser() {
                this.set('isLocatingUser', true);
            }

        }
    });
});