define('sportly/models/game', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    url: _emberData['default'].attr('string'),
    timestamp: _emberData['default'].attr('number'),
    time: _emberData['default'].attr('string'),
    date: _emberData['default'].attr('string'),
    cancellationMessage: _emberData['default'].attr('string'),
    sportId: _emberData['default'].attr('number'),
    venue: _emberData['default'].belongsTo('venue', {
      async: false
    }),
    //group: 					DS.belongsTo('group'),
    gameLength: _emberData['default'].attr('number'),
    playerLimit: _emberData['default'].attr('number'),
    isCancelled: _emberData['default'].attr('number'),
    isFinished: _emberData['default'].attr('number'),
    members: _emberData['default'].hasMany('game-member', {
      async: false
    })
  });
});