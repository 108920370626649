define('sportly/controllers/game', ['exports', 'ember', 'sportly/enums/football-game/rsvp', 'sportly/enums/football-game/member-role', 'sportly/enums/group/member-role'], function (exports, _ember, _sportlyEnumsFootballGameRsvp, _sportlyEnumsFootballGameMemberRole, _sportlyEnumsGroupMemberRole) {
    exports['default'] = _ember['default'].Controller.extend({

        session: _ember['default'].inject.service('session'),

        group: _ember['default'].computed.alias('model.group'),

        spaces: _ember['default'].computed('model.playerLimit', 'attending.length', function () {
            return Math.max(0, this.get('model.playerLimit') - this.get('attending.length'));
        }),

        allowsUnlimitedPlayers: _ember['default'].computed('model.playerLimit', function () {
            return this.get('model.playerLimit') === 0;
        }),

        isFull: _ember['default'].computed('attending.length', 'model.playerLimit', function () {
            if (this.get('model.playerLimit') === 0) {
                return false;
            }
            return this.get('attending.length') >= this.get('model.playerLimit');
        }),

        isOverHalfFull: _ember['default'].computed('model.playerLimit', 'model.members.@each.attending', function () {
            if (this.get('model.playerLimit') === 0) {
                return false;
            }
            return this.get('attending.length') >= this.get('model.playerLimit') / 2;
        }),

        gameLengthMinutes: _ember['default'].computed('model.gameLength', function () {
            return this.get('model.gameLength') / 60;
        }),

        gameType: _ember['default'].computed('model.gameTypeId', function () {
            return this.get('model.gameTypeId') + 1;
        }),

        notCancelled: _ember['default'].computed.not('model.isCancelled'),
        notFinished: _ember['default'].computed.not('model.isFinished'),
        isActive: _ember['default'].computed.and('notCancelled', 'notFinished'),

        playing: _ember['default'].computed.filterBy('model.members', 'rsvpId', _sportlyEnumsFootballGameRsvp['default'].PLAYING),
        reserves: _ember['default'].computed.filterBy('model.members', 'rsvpId', _sportlyEnumsFootballGameRsvp['default'].RESERVE),
        pending: _ember['default'].computed.filterBy('model.members', 'rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING),
        declined: _ember['default'].computed.filterBy('model.members', 'rsvpId', _sportlyEnumsFootballGameRsvp['default'].DECLINED),
        banned: _ember['default'].computed.filterBy('model.members', 'rsvpId', _sportlyEnumsFootballGameRsvp['default'].BANNED),

        notAttending: _ember['default'].computed.filter('model.members', function (member) {
            return member.get('rsvpId') === _sportlyEnumsFootballGameRsvp['default'].DECLINED || member.get('rsvpId') === _sportlyEnumsFootballGameRsvp['default'].BANNED;
        }),

        attending: _ember['default'].computed('model.members.@each.rsvpId', function () {
            return this.get('model.members').filter(function (member) {
                return member.get('rsvpId') === _sportlyEnumsFootballGameRsvp['default'].PLAYING || member.get('rsvpId') === _sportlyEnumsFootballGameRsvp['default'].RESERVE;
            });
        }),

        memberUsers: _ember['default'].computed.mapBy('model.members', 'user'),

        sessionMember: _ember['default'].computed('memberUsers.@each.user', 'model.members.@each.user', 'session.user.id', function () {
            var userId = this.get('session.user.id');
            return this.get('model.members').find(function (member) {
                return member.get('user.id') === userId;
            });
        }),

        myMember: _ember['default'].computed('memberUsers.@each.user', 'model.members.@each.user', 'session.user.id', function () {
            var userId = this.get('session.user.id');
            return this.get('model.members').find(function (member) {
                return member.get('user.id') === userId;
            });
        }),

        iHaveAdminRole: _ember['default'].computed.equal('myMember.roleId', _sportlyEnumsFootballGameMemberRole['default'].ADMIN),
        iAmAdmin: _ember['default'].computed.and('iAmAMember', 'iHaveAdminRole'),
        iAmAMember: _ember['default'].computed.notEmpty('myMember'),
        iAmNotAMember: _ember['default'].computed.not('iAmAMember'),

        iCanRSVP: _ember['default'].computed.and('isActive', 'iAmAMember', 'iAmNotBanned'),

        iAmAttending: _ember['default'].computed.or('iAmPlaying', 'iAmAReserve'),
        iAmPlaying: _ember['default'].computed.equal('myMember.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PLAYING),
        iAmAReserve: _ember['default'].computed.equal('myMember.rsvpId', _sportlyEnumsFootballGameRsvp['default'].RESERVE),
        iAmPending: _ember['default'].computed.equal('myMember.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING),
        iHaveDeclined: _ember['default'].computed.equal('myMember.rsvpId', _sportlyEnumsFootballGameRsvp['default'].DECLINED),
        iAmBanned: _ember['default'].computed.equal('myMember.rsvpId', _sportlyEnumsFootballGameRsvp['default'].BANNED),
        iAmNotBanned: _ember['default'].computed.not('iAmBanned'),

        isCreator: _ember['default'].computed('session.user.id', 'creator.id', function () {
            return this.get('session.user.id') === this.get('creator.id');
        }),

        iCanJoin: _ember['default'].computed.and('isActive', 'iAmNotAMember'),

        isAdmin: _ember['default'].computed('session.user.id', 'model.group.members.@each.user', 'model.group.members.@each.roleId', function () {
            var userId = this.get('session.user.id');
            return this.get('model.group.members').any(function (groupMember) {
                return groupMember.get('user.id') === userId && groupMember.get('roleId') === _sportlyEnumsGroupMemberRole['default'].ADMIN;
            });
        }),

        canEditGame: _ember['default'].computed('isActive', 'isAdmin', function () {
            return this.get('isActive') && this.get('isAdmin');
        })

    });
});