define('sportly/controllers/football-game/member/index', ['exports', 'ember', 'sportly/enums/football-game/member-role', 'sportly/enums/football-game/rsvp'], function (exports, _ember, _sportlyEnumsFootballGameMemberRole, _sportlyEnumsFootballGameRsvp) {
  exports['default'] = _ember['default'].Controller.extend({

    session: _ember['default'].inject.service('session'),

    profile: _ember['default'].computed.alias('model.user.profile'),

    sessionIsAdmin: _ember['default'].computed('model.footballGame.members.@each.user', 'session.user.id', function () {
      return this.get('model.footballGame.members').findBy('user.id', this.get('session.user.id')).get('roleId') === _sportlyEnumsFootballGameMemberRole['default'].ADMIN;
    }),

    iCanUnban: _ember['default'].computed.and('sessionIsAdmin', 'isBanned'),
    iCanBan: _ember['default'].computed.and('sessionIsAdmin', 'isNotMe', 'isNotBanned'),

    isPlaying: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PLAYING),
    isReserve: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].RESERVE),
    isPending: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING),
    hasDeclined: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].DECLINED),
    isBanned: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].BANNED),
    isNotBanned: _ember['default'].computed.not('isBanned'),

    isMe: _ember['default'].computed('model.profile.user.id', 'session.user.id', function () {
      return this.get('model.user.id') === this.get('session.user.id');
    }),
    isNotMe: _ember['default'].computed.not('isMe'),
    isAdmin: _ember['default'].computed.equal('model.roleId', _sportlyEnumsFootballGameRsvp['default'].ADMIN),

    actions: {
      unban: function unban(member) {
        member.set('rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING);
        member.save();
      }
    }
  });
});