define('sportly/controllers/football-game/create/index', ['exports', 'ember', 'sportly/enums/group/member-role', 'sportly/enums/group/status'], function (exports, _ember, _sportlyEnumsGroupMemberRole, _sportlyEnumsGroupStatus) {
    exports['default'] = _ember['default'].Controller.extend({

        session: _ember['default'].inject.service('session'),

        footballGameCreate: _ember['default'].inject.controller('footballGame/create'),

        errors: _ember['default'].computed.alias('footballGameCreate.errors'),
        homeTeamName: _ember['default'].computed.alias('footballGameCreate.homeTeamName'),
        awayTeamName: _ember['default'].computed.alias('footballGameCreate.awayTeamName'),
        venue: _ember['default'].computed.alias('footballGameCreate.venue'),
        group: _ember['default'].computed.alias('footballGameCreate.group'),
        gameTypeId: _ember['default'].computed.alias('footballGameCreate.gameTypeId'),
        gameLength: _ember['default'].computed.alias('footballGameCreate.gameLength'),
        playerLimit: _ember['default'].computed.alias('footballGameCreate.playerLimit'),
        localTime: _ember['default'].computed.alias('footballGameCreate.localTime'),
        frequency: _ember['default'].computed.alias('footballGameCreate.frequency'),
        startDate: _ember['default'].computed.alias('footballGameCreate.startDate'),
        finishDate: _ember['default'].computed.alias('footballGameCreate.finishDate'),
        isSaving: false,

        adminGroups: _ember['default'].computed('session.user.groupMembers.@each.roleId', 'session.user.groupMembers.@each.group', function () {
            return this.get('session.user.groupMembers').filterBy('roleId', _sportlyEnumsGroupMemberRole['default'].ADMIN).mapBy('group').filter(function (group) {
                return group.get('status') !== _sportlyEnumsGroupStatus['default'].DELETED;
            }).sortBy('name');
        }),

        hasAdminGroups: _ember['default'].computed.gt('adminGroups.length', 0)
    });
});