define('sportly/components/x-map/controls', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-map--controls'],
        query: '',

        actions: {
            locateUser: function locateUser() {
                this.sendAction('onLocateUser');
            },

            search: function search() {
                this.sendAction('onSearch', this.get('query'));
            }
        }
    });
});