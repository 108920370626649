define('sportly/routes/account-settings/edit-general', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        model: function model() {
            return this.get('session.user.profile');
        },

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('account-settings').set('pageTitle', 'Edit general');
        },

        actions: {
            save: function save() {

                var _this = this,
                    profile = this.get('currentModel');

                if (_ember['default'].isEmpty(profile.get('firstName'))) {
                    profile.get('errors').add('firstName', 'First name is required');
                }
                if (_ember['default'].isEmpty(profile.get('lastName'))) {
                    profile.get('errors').add('lastName', 'Last name is required');
                }
                if (_ember['default'].isEmpty(profile.get('gender'))) {
                    profile.get('errors').add('gender', 'Gender is required');
                }
                if (_ember['default'].isEmpty(profile.get('dobMonth')) || _ember['default'].isEmpty(profile.get('dobYear'))) {
                    profile.get('errors').add('dob', 'Date of birth is required');
                }
                if (_ember['default'].isEmpty(profile.get('location'))) {
                    profile.get('errors').add('location', 'Location is required');
                }

                if (!profile.get('isValid')) {
                    return;
                }

                this.get('geolocationService').geolocate(profile.get('location')).then(function (latlng) {
                    profile.set('latitude', latlng[0]);
                    profile.set('longitude', latlng[1]);
                    return profile.save();
                }).then(function () {
                    _this.transitionTo('accountSettings.general');
                })['catch'](function () {});
            },

            cancel: function cancel() {
                this.transitionTo('accountSettings.general');
            }

        },

        deactivate: function deactivate() {
            if (this.get('currentModel.hasDirtyAttributes')) {
                this.get('currentModel').rollbackAttributes();
            }
        }

    });
});