define("sportly/components/x-global/navs/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 24,
              "column": 0
            }
          },
          "moduleName": "sportly/components/x-global/navs/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "yield", ["loc", [null, [22, 2], [22, 11]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 18
          }
        },
        "moduleName": "sportly/components/x-global/navs/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "x-global/side-menu", [], ["isShowing", ["subexpr", "@mut", [["get", "isSideMenuVisible", ["loc", [null, [2, 12], [2, 29]]]]], [], []], "user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [3, 7], [3, 11]]]]], [], []], "signOut", ["subexpr", "action", ["signOut"], [], ["loc", [null, [4, 10], [4, 28]]]], "toggleSideMenu", ["subexpr", "action", ["toggleSideMenu"], [], ["loc", [null, [5, 17], [5, 42]]]]], ["loc", [null, [1, 0], [6, 2]]]], ["inline", "x-global/nav", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [9, 7], [9, 11]]]]], [], []], "title", ["subexpr", "@mut", [["get", "attrs.pageTitle", ["loc", [null, [10, 8], [10, 23]]]]], [], []], "isLoading", ["subexpr", "@mut", [["get", "attrs.isLoading", ["loc", [null, [11, 12], [11, 27]]]]], [], []], "isShiftedOut", ["subexpr", "@mut", [["get", "isSideMenuVisible", ["loc", [null, [12, 15], [12, 32]]]]], [], []], "signOut", ["subexpr", "action", ["signOut"], [], ["loc", [null, [13, 10], [13, 28]]]], "toggleSideMenu", ["subexpr", "action", ["toggleSideMenu"], [], ["loc", [null, [14, 17], [14, 42]]]]], ["loc", [null, [8, 0], [15, 2]]]], ["block", "x-global/page", [], ["isShiftedOut", ["subexpr", "@mut", [["get", "isSideMenuVisible", ["loc", [null, [18, 15], [18, 32]]]]], [], []], "toggleSideMenu", ["subexpr", "action", ["toggleSideMenu"], [], ["loc", [null, [19, 17], [19, 42]]]]], 0, null, ["loc", [null, [17, 0], [24, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});