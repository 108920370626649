define('sportly/controllers/group-results', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        sortProperties: ['timestamp'],
        sortAscending: false,
        itemController: 'game',

        arrangedContent: _ember['default'].computed('content.@each.timestamp', 'content.isLoaded', function () {

            if (_ember['default'].isEmpty(this.get('content'))) {
                return this.get('content');
            }

            if (this.get('content.isLoaded') === false) {
                return this.get('content');
            }

            var now = moment().format('X');
            return this.get('content').filter(function (game) {
                return game.get('timestamp') < now;
            });
        })

    });
});