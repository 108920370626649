define('sportly/components/x-group/invitation/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-group--invitation'],

    primarySender: _ember['default'].computed.alias('myPendingInvites.lastObject.sender'),
    hasMultipleInvites: _ember['default'].computed.gt('myPendingInvites.length', 1),
    othersCount: _ember['default'].computed('myPendingInvites.length', function () {
      return this.get('myPendingInvites.length' - 1);
    })
  });
});