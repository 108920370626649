define('sportly/routes/profile/groups', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsGroupStatus) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        beforeModel: function beforeModel(transition) {
            this._super(transition);
            return this.modelFor('profile').get('user.groupMembers');
        },

        model: function model() {
            return this.modelFor('profile').get('user.groupMembers').mapBy('group').filter(function (group) {
                return group.get('status') !== _sportlyEnumsGroupStatus['default'].DELETED;
            });
        },

        afterModel: function afterModel(groups) {
            return _ember['default'].RSVP.all(groups.mapBy('games')).then(function () {
                return _ember['default'].RSVP.all(groups.mapBy('members'));
            }).then(function () {
                return _ember['default'].RSVP.all(groups.mapBy('venue'));
            });
        },

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('profile').set('pageTitle', 'Groups');
        }

    });
});