define("sportly/templates/guest/sign-in", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 2
          }
        },
        "moduleName": "sportly/templates/guest/sign-in.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "x-guest/sign-in", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [2, 11], [2, 17]]]]], [], []], "identification", ["subexpr", "@mut", [["get", "identification", ["loc", [null, [3, 19], [3, 33]]]]], [], []], "password", ["subexpr", "@mut", [["get", "password", ["loc", [null, [4, 13], [4, 21]]]]], [], []], "authenticate", ["subexpr", "action", ["authenticate"], [], ["loc", [null, [5, 17], [5, 40]]]]], ["loc", [null, [1, 0], [6, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});