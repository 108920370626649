define('sportly/components/x-stories/profile-post-story', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-stories--profile-post-story', 'isLoading'],

        isLoading: _ember['default'].computed.not('isLoaded'),
        isLoaded: _ember['default'].computed.and('story.user.profile.isLoaded', 'story.user.profile.photo.isLoaded', 'story.post.isLoaded'),

        contextIsProfile: _ember['default'].computed('story.targetUser.profile.content', 'source', function () {
            return this.get('story.targetUser.profile.content') === this.get('source');
        })
    });
});