define('sportly/controllers/football-game/member/ban', ['exports', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, _ember, _sportlyEnumsFootballGameRsvp) {
  exports['default'] = _ember['default'].Controller.extend({

    profile: _ember['default'].computed.alias('model.user.profile'),

    isPlaying: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PLAYING),
    isReserve: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].RESERVE),
    isPending: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING),
    hasDeclined: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].DECLINED)

  });
});