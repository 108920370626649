define('sportly/components/x-guest/sign-in-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-guest--sign-in-form'],
    errors: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('errorCount', 0);
    },

    showForgotPasswordPrompt: _ember['default'].computed.gte('errorCount', 3),

    actions: {
      authenticate: function authenticate() {
        var _this = this;
        this.attrs.authenticate().then(function (result) {
          if (result === 'ERROR') {
            _this.incrementProperty('errorCount');
          }
        });
      }
    }
  });
});