define('sportly/controllers/account-settings/general', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        isMale: _ember['default'].computed('model.gender', function () {
            return this.get('model.gender') === 'm';
        }),

        dobMonthPretty: _ember['default'].computed('model.dobMonth', 'model.dobYear', function () {
            return moment(this.get('model.dobYear') + '-' + this.get('model.dobMonth')).format('MMMM');
        })
    });
});