define('sportly/routes/football-game/member', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsFootballGameRsvp) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    afterModel: function afterModel(member) {
      return member.get('user').then(function (user) {
        return user.get('profile');
      });
    },

    actions: {
      unban: function unban(gameMember) {
        gameMember.set('rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING);
        gameMember.save();
      }
    }
  });
});