define('sportly/controllers/guest/join', ['exports', 'ember', 'ember-data', 'sportly/enums/app-invite/status'], function (exports, _ember, _emberData, _sportlyEnumsAppInviteStatus) {
    exports['default'] = _ember['default'].Controller.extend({
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        appInvite: null,
        errors: new _emberData['default'].Errors(),

        pending: _sportlyEnumsAppInviteStatus['default'].PENDING,

        hasAppInvite: _ember['default'].computed.notEmpty('appInvite'),
        appInviteIsPending: _ember['default'].computed.equal('appInvite.status', _sportlyEnumsAppInviteStatus['default'].PENDING),
        appInviteIsNotPending: _ember['default'].computed.not('appInviteIsPending'),
        appInviteIsUsed: _ember['default'].computed.and('hasAppInvite', 'appInviteIsNotPending')
    });
});