define('sportly/controllers/football-game/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        session: _ember['default'].inject.service('session'),

        sessionIsMember: _ember['default'].computed.notEmpty('sessionMember'),
        notCancelled: _ember['default'].computed.not('model.isCancelled'),

        sessionMember: _ember['default'].computed('model.members.@each.user', 'session.user.id', function () {
            var userId = this.get('session.user.id');
            return this.get('model.members').find(function (member) {
                return member.get('user.id') === userId;
            });
        }),

        sessionCanPost: _ember['default'].computed.and('sessionIsMember', 'notCancelled')
    });
});