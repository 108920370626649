define('sportly/controllers/group/delete', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        group: _ember['default'].inject.controller(),

        actions: {
            deleteGroup: function deleteGroup() {

                var controller = this,
                    group = this.get('group.model'),
                    games = this.get('group.fixtures');

                if (games.get('length') > 0) {
                    games.forEach(function (game) {
                        game.set('isCancelled', true);
                    });
                    games.invoke('save');
                    _ember['default'].RSVP.all(games).then(function () {
                        group.set('status', 2);
                        return group.save();
                    }).then(function () {
                        controller.transitionToRoute('group.index', group);
                    });
                    return;
                }

                group.set('status', 2);
                group.save().then(function () {
                    controller.transitionToRoute('group.index', group);
                });
            },

            cancel: function cancel() {
                this.transitionToRoute('group.settings');
            }
        }
    });
});