define('sportly/components/x-football-games/edit-form', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-football-games--edit-form'],

        errors: null,
        homeTeamName: '',
        awayTeamName: '',
        venue: null,
        gameTypeId: 4,
        gameLength: 3600,
        playerLimit: 0,
        localTime: '18:00',
        rsvpOpenTimeDelta: 0,
        rsvpCloseTimeDelta: 0,
        isSaving: false,

        date: moment().add(1, 'd').format('YYYY-MM-DD'),

        prettyDate: _ember['default'].computed('date', {
            get: function get() {
                return moment(this.get('date')).format('DD/MM/YYYY');
            },
            set: function set(key, value) {
                this.set('date', moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'));
                return moment(this.get('date')).format('DD/MM/YYYY');
            }
        }),

        playerLimitMax: 40,

        playerLimitMin: _ember['default'].computed('gameTypeId', function () {
            return (this.get('gameTypeId') + 1) * 2;
        }),

        timeOptions: _ember['default'].computed(function () {
            var options = [];
            for (var idx = 0; idx <= 85500; idx += 900) {
                options[idx / 900] = {
                    name: ('0' + Math.floor(idx / 3600)).slice(-2) + ':' + ('0' + idx % 3600 / 60).slice(-2),
                    value: ('0' + Math.floor(idx / 3600)).slice(-2) + ':' + ('0' + idx % 3600 / 60).slice(-2)
                };
            }
            return options;
        }),

        gameLengthOptions: _ember['default'].computed(function () {
            var gameLengthOptions = [];
            for (var i = 2; i <= 90; i++) {
                gameLengthOptions.pushObject({
                    name: i + " minutes",
                    value: i * 60
                });
            }
            gameLengthOptions.pushObjects([{ name: '2 hours', value: 7200 }, { name: '3 hours', value: 10800 }, { name: '4 hours', value: 14400 }]);
            return gameLengthOptions;
        }),

        gameTypeOptions: _ember['default'].computed(function () {
            var gameTypeOptions = [];
            for (var i = 1; i < 11; i++) {
                gameTypeOptions.pushObject({
                    name: i + 1 + " a-side",
                    value: i
                });
            }
            return gameTypeOptions;
        }),

        playerLimitOptions: _ember['default'].computed('playerLimitMin', 'playerLimitMax', function () {
            var playerLimits = [{ name: "Unlimited", "value": 0 }];
            for (var i = this.get('playerLimitMin'); i <= this.get("playerLimitMax"); i++) {
                playerLimits.pushObject({
                    name: i + " players",
                    value: i
                });
            }
            return playerLimits;
        }),

        rsvpOpenTimeDeltaOptions: _ember['default'].computed('rsvpCloseTimeDelta', function () {

            var options = [{ name: 'Straight away', value: 0 }],
                rsvpCloseTimeDelta = this.get('rsvpCloseTimeDelta');
            [{ 'value': 0.5 * 3600, 'name': '30 minutes before game' }, { 'value': 1 * 3600, 'name': '1 hour before game' }, { 'value': 2 * 3600, 'name': '2 hours before game' }, { 'value': 3 * 3600, 'name': '3 hours before game' }, { 'value': 4 * 3600, 'name': '4 hours before game' }, { 'value': 5 * 3600, 'name': '5 hours before game' }, { 'value': 6 * 3600, 'name': '6 hours before game' }, { 'value': 7 * 3600, 'name': '7 hours before game' }, { 'value': 8 * 3600, 'name': '8 hours before game' }, { 'value': 9 * 3600, 'name': '9 hours before game' }, { 'value': 10 * 3600, 'name': '10 hours before game' }, { 'value': 11 * 3600, 'name': '11 hours before game' }, { 'value': 12 * 3600, 'name': '12 hours before game' }, { 'value': 1 * 3600 * 24, 'name': '1 day before game' }, { 'value': 2 * 3600 * 24, 'name': '2 days before game' }, { 'value': 3 * 3600 * 24, 'name': '3 days before game' }, { 'value': 4 * 3600 * 24, 'name': '4 days before game' }, { 'value': 5 * 3600 * 24, 'name': '5 days before game' }, { 'value': 6 * 3600 * 24, 'name': '6 days before game' }, { 'value': 1 * 3600 * 24 * 7, 'name': '1 week before game' }, { 'value': 2 * 3600 * 24 * 7, 'name': '2 weeks before game' }, { 'value': 3 * 3600 * 24 * 7, 'name': '3 weeks before game' }].forEach(function (time) {
                if (time.value > rsvpCloseTimeDelta) {
                    options.pushObject(time);
                }
            });
            return options;
        }),

        rsvpCloseTimeDeltaOptions: _ember['default'].computed('rsvpOpenTimeDelta', function () {
            var options = [{ name: 'At game start', value: 0 }],
                rsvpOpenTimeDelta = this.get('rsvpOpenTimeDelta');
            [{ 'value': 0.5 * 3600, 'name': '30 minutes before game' }, { 'value': 1 * 3600, 'name': '1 hour before game' }, { 'value': 2 * 3600, 'name': '2 hours before game' }, { 'value': 3 * 3600, 'name': '3 hours before game' }, { 'value': 4 * 3600, 'name': '4 hours before game' }, { 'value': 5 * 3600, 'name': '5 hours before game' }, { 'value': 6 * 3600, 'name': '6 hours before game' }, { 'value': 7 * 3600, 'name': '7 hours before game' }, { 'value': 8 * 3600, 'name': '8 hours before game' }, { 'value': 9 * 3600, 'name': '9 hours before game' }, { 'value': 10 * 3600, 'name': '10 hours before game' }, { 'value': 11 * 3600, 'name': '11 hours before game' }, { 'value': 12 * 3600, 'name': '12 hours before game' }, { 'value': 1 * 3600 * 24, 'name': '1 day before game' }, { 'value': 2 * 3600 * 24, 'name': '2 days before game' }, { 'value': 3 * 3600 * 24, 'name': '3 days before game' }, { 'value': 4 * 3600 * 24, 'name': '4 days before game' }, { 'value': 5 * 3600 * 24, 'name': '5 days before game' }, { 'value': 6 * 3600 * 24, 'name': '6 days before game' }, { 'value': 1 * 3600 * 24 * 7, 'name': '1 week before game' }, { 'value': 2 * 3600 * 24 * 7, 'name': '2 weeks before game' }, { 'value': 3 * 3600 * 24 * 7, 'name': '3 weeks before game' }].forEach(function (time) {
                if (rsvpOpenTimeDelta === 0 || time.value < rsvpOpenTimeDelta) {
                    options.pushObject(time);
                }
            });
            return options;
        }),

        actions: {

            onChangeDate: function onChangeDate(date) {
                this.set('prettyDate', date);
            },

            chooseVenue: function chooseVenue() {
                this.sendAction('chooseVenue');
            },

            save: function save() {

                var errors = this.get('errors');

                //As venue doesnt seem to clear by itself
                errors.clear();

                if (_ember['default'].isEmpty(this.get('date'))) {
                    errors.add('date', 'Date is required');
                }

                if (_ember['default'].isEmpty(this.get('venue'))) {
                    errors.add('venue', 'Venue is required');
                }
                if (_ember['default'].isEmpty(this.get('homeTeamName'))) {
                    errors.add('homeTeamName', 'Home team name is required');
                } else if (this.get('homeTeamName.length') < 3) {
                    errors.add('homeTeamName', 'Team name must be at least 3 characters');
                }

                if (_ember['default'].isEmpty(this.get('awayTeamName'))) {
                    errors.add('awayTeamName', 'Away team name is required');
                } else if (this.get('awayTeamName.length') < 3) {
                    errors.add('awayTeamName', 'Team name must be at least 3 characters');
                }

                if (!errors.get('isEmpty')) {
                    return;
                }

                this.sendAction('save', {
                    homeTeamName: this.get('homeTeamName'),
                    awayTeamName: this.get('awayTeamName'),
                    venue: this.get('venue'),
                    gameTypeId: this.get('gameTypeId'),
                    gameLength: this.get('gameLength'),
                    playerLimit: this.get('playerLimit'),
                    localTime: this.get('localTime'),
                    localDate: this.get('date'),
                    rsvpOpenTimeDelta: this.get('rsvpOpenTimeDelta'),
                    rsvpCloseTimeDelta: this.get('rsvpCloseTimeDelta')
                });
            },

            cancel: function cancel() {
                this.sendAction('cancel');
            }
        }
    });
});