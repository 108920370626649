define('sportly/routes/profile/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('profile').set('pageTitle', 'Profile');
        },

        actions: {

            didPost: function didPost(body) {
                var _this = this;

                return this.store.createRecord('post/profile', {
                    user: _this.get('session.user'),
                    profile: _this.get('currentModel'),
                    body: body
                }).save().then(function (post) {
                    return _this.store.createRecord('story/profile-post', {
                        user: _this.get('session.user'),
                        targetUser: _this.get('currentModel.user'),
                        post: post
                    }).save();
                }).then(function (story) {
                    _this.get('currentModel.stories').pushObject(story);
                });
            }
        }
    });
});