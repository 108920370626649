define('sportly/controllers/dashboard', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        session: _ember['default'].inject.service('session'),

        games: _ember['default'].computed.mapBy('model.user.gameMembers', 'footballGame'),

        fixtures: _ember['default'].computed('games.@each.timestamp', 'games.@each.isCancelled', function () {
            var now = moment().format('X');
            return this.get('games').filter(function (game) {
                if (_ember['default'].isEmpty(game) || game.get('isCancelled')) {
                    return false;
                }
                return game.get('timestamp') > now;
            }).sort(function (a, b) {
                return a.get('timestamp') - b.get('timestamp');
            }).slice(0, 3);
        })

    });
});