define('sportly/instance-initializers/mixpanel', ['exports', 'sportly/config/environment'], function (exports, _sportlyConfigEnvironment) {
  exports.initialize = initialize;

  function initialize(appInstance) {
    if (_sportlyConfigEnvironment['default'].mixpanel.enabled) {
      if (typeof appInstance.lookup === 'undefined') {
        appInstance = appInstance.container;
      }
      var router = appInstance.lookup('router:main');
      router.on('didTransition', function () {
        appInstance.lookup('service:mixpanel').trackPageView(this.get('url'));
      });
    }
  }

  exports['default'] = {
    name: 'mixpanel',
    initialize: initialize
  };
});