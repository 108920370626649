define('sportly/services/ie-check', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    isIE: function isIE(version, comparison) {

      var cc = 'IE',
          b = document.createElement('B'),
          docElem = document.documentElement,
          ieTest;

      if (version) {
        cc += ' ' + version;
        if (comparison) {
          cc = comparison + ' ' + cc;
        }
      }

      b.innerHTML = '<!--[if ' + cc + ']><b id="iecctest"></b><![endif]-->';
      docElem.appendChild(b);
      ieTest = !!document.getElementById('iecctest');
      docElem.removeChild(b);
      return ieTest;
    }

  });
});