define('sportly/routes/group/join', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController() {
      var _this = this;

      if (this.controllerFor('group').get('isMember')) {
        return _this.transitionTo('group.index');
      }

      if (this.controllerFor('group').get('isNotActive')) {
        return _this.transitionTo('group.index');
      }
      this.send('join');
      this.transitionTo('group.index');
    }
  });
});