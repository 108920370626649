define('sportly/components/x-football-games/rsvp', ['exports', 'ember', 'sportly/config/environment'], function (exports, _ember, _sportlyConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':x-football-games--rsvp'],
    session: _ember['default'].inject.service('session'),
    footballGame: null,

    time: _ember['default'].computed(function () {
      return moment();
    }),

    notCancelled: _ember['default'].computed.not('footballGame.isCancelled'),
    notFinished: _ember['default'].computed.not('footballGame.isFinished'),
    isActive: _ember['default'].computed.and('notCancelled', 'notFinished'),
    showRSVP: _ember['default'].computed.and('sessionIsMember', 'isActive'),

    sessionIsMember: _ember['default'].computed.notEmpty('sessionMember'),

    sessionMember: _ember['default'].computed('footballGame.members.@each.user', 'session.user.id', function () {
      var userId = this.get('session.user.id');
      return this.get('footballGame.members').find(function (member) {
        return member.get('user.id') === userId;
      });
    }),

    rsvpCloseTime: _ember['default'].computed('footballGame.timestamp', 'footballGame.rsvpCloseTimeDelta', function () {
      return this.get('footballGame.timestamp') - this.get('footballGame.rsvpCloseTimeDelta');
    }),

    rsvpOpenTime: _ember['default'].computed('footballGame.timestamp', 'footballGame.rsvpOpenTimeDelta', function () {
      return this.get('footballGame.timestamp') - this.get('footballGame.rsvpOpenTimeDelta');
    }),

    hasRSVPOpened: _ember['default'].computed('rsvpOpenTime', 'footballGame.rsvpOpenTimeDelta', function () {
      if (this.get('footballGame.rsvpOpenTimeDelta') === 0) {
        return true;
      }
      return this.get('time').format('X') > this.get('rsvpOpenTime');
    }),

    hasRSVPClosed: _ember['default'].computed('rsvpCloseTime', function () {
      return this.get('time').format('X') > this.get('rsvpCloseTime');
    }),
    hasRSVPNotClosed: _ember['default'].computed.not('hasRSVPClosed'),
    hasRSVPNotOpened: _ember['default'].computed.not('hasRSVPOpened'),
    isRSVPOpen: _ember['default'].computed.and('hasRSVPOpened', 'hasRSVPNotClosed'),

    didInsertElement: function didInsertElement() {
      this.recomputeHasRSVPOpened();
    },

    recomputeHasRSVPOpened: function recomputeHasRSVPOpened() {
      var self = this;
      if (this.get('isDestroyed')) {
        return;
      }
      if (_sportlyConfigEnvironment['default'].environment !== 'test') {
        _ember['default'].run.later(function () {
          self.notifyPropertyChange('hasRSVPOpened');
          self.recomputeHasRSVPOpened();
        }, 10000);
      }
    },

    actions: {
      attend: function attend() {
        this.sendAction('attend');
      },

      decline: function decline() {
        this.sendAction('decline');
      },

      changeRSVP: function changeRSVP() {
        this.sendAction('changeRSVP');
      }
    }
  });
});