define('sportly/components/x-group-members/action-card/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':x-group-members--action-card', 'isDisabled'],

    profile: _ember['default'].computed.alias('member.user.profile'),

    name: _ember['default'].computed('profile.firstName', 'profile.lastName', function () {
      return this.get('profile.firstName') + ' ' + this.get('profile.lastName');
    }),

    img: _ember['default'].computed.alias('profile.photo.squareMedium')
  });
});