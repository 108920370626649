define('sportly/models/profile', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        user: _emberData['default'].belongsTo('user', { async: true }),
        firstName: _emberData['default'].attr('string'),
        lastName: _emberData['default'].attr('string'),
        url: _emberData['default'].attr('string'),
        photo: _emberData['default'].belongsTo('photo', { async: true }),
        gender: _emberData['default'].attr('string'),
        dobDay: _emberData['default'].attr('number'),
        dobMonth: _emberData['default'].attr('number'),
        dobYear: _emberData['default'].attr('number'),
        location: _emberData['default'].attr('string'),
        latitude: _emberData['default'].attr('string'),
        longitude: _emberData['default'].attr('string'),
        about: _emberData['default'].attr('string'),
        onboarding: _emberData['default'].attr('number'),
        stories: _emberData['default'].hasMany('story', { async: true, polymorphic: true })
    });
});