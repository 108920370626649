define('sportly/routes/profile/fixtures', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        beforeModel: function beforeModel(transition) {
            this._super(transition);
            return this.modelFor('profile').get('user.gameMembers').then(function (gameMembers) {
                return gameMembers.mapBy('user');
            });
        },

        model: function model() {
            var now = moment().format('X');

            return this.modelFor('profile').get('user.gameMembers').mapBy('footballGame').filter(function (footballGame) {
                return footballGame.get('timestamp') > now;
            });
        },

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('profile').set('pageTitle', 'Fixtures');
        }

    });
});