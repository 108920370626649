define('sportly/routes/group/edit/add-venue', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        model: function model(params) {
            return this.store.createRecord('venue', {
                name: '',
                description: '',
                latitude: params.latitude,
                longitude: params.longitude
            });
        },

        actions: {

            save: function save() {

                var route = this,
                    venue = this.get('currentModel');

                if (_ember['default'].isEmpty(venue.get('name'))) {
                    venue.get('errors').add('name', 'Name is required');
                }
                if (_ember['default'].isEmpty(venue.get('description'))) {
                    venue.get('errors').add('description', 'Description is required');
                }
                if (!venue.get('isValid')) {
                    return;
                }

                venue.save().then(function (venue) {
                    route.controllerFor('group.edit').set('model.venue', venue);
                    route.transitionTo('group.edit.index');
                })['catch'](function () {});
            },

            cancel: function cancel() {
                this.transitionTo('group.edit.chooseVenue');
            }
        },

        deactivate: function deactivate() {
            if (this.get('currentModel').get('isNew')) {
                this.get('currentModel').deleteRecord();
            }
        }

    });
});