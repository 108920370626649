define('sportly/controllers/group/admins', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        group: _ember['default'].inject.controller(),

        isAdmin: _ember['default'].computed.alias('group.isAdmin'),
        isActive: _ember['default'].computed.alias('group.isActive'),

        canEditAdmins: _ember['default'].computed.and('isAdmin', 'isActive'),

        isPlural: _ember['default'].computed.gt('model.length', 1)
    });
});