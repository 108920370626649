define('sportly/models/app-invite', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        sender: _emberData['default'].belongsTo('user', { async: true }),
        emailAddress: _emberData['default'].attr('string'),
        receiver: _emberData['default'].belongsTo('user', { async: true, inverse: 'appInvite' }),
        timestamp: _emberData['default'].attr('number'),
        status: _emberData['default'].attr('number'),
        suppressNotification: _emberData['default'].attr('boolean', {
            defaultValue: false
        })
    });
});