define('sportly/components/x-guest/join-form', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-guest--join-form'],
        errors: null,
        actions: {
            join: function join() {
                this.sendAction('join');
            }
        }
    });
});