define('sportly/routes/football-game/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsFootballGameRsvp) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        setupController: function setupController(controller, footballGame) {
            controller.set('content', footballGame);
            this.controllerFor('footballGame.member').set('model', this.controllerFor('footballGame').get('myMember'));
            this.controllerFor('football-game').set('pageTitle', 'Game');
        },

        actions: {

            didPost: function didPost(body) {
                var _this = this;

                return this.store.createRecord('post/football-game', {
                    user: _this.get('session.user'),
                    footballGame: _this.get('currentModel'),
                    body: body
                }).save().then(function (post) {
                    return _this.store.createRecord('story/football-game-post', {
                        user: _this.get('session.user'),
                        footballGame: _this.get('currentModel'),
                        post: post
                    }).save();
                }).then(function (story) {
                    _this.get('currentModel.stories').pushObject(story);
                });
            },

            unbanMyself: function unbanMyself() {
                this.set('controller.myMember.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING);
                return this.get('controller.myMember').save();
            }

        },

        deactivate: function deactivate() {
            var controller = this.get('controller');

            if (controller.get('myMember.hasDirtyAttributes')) {
                controller.get('myMember').rollbackAttributes();
            }
        }

    });
});