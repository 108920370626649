define('sportly/services/session', ['exports', 'ember', 'ember-data', 'ember-simple-auth/services/session'], function (exports, _ember, _emberData, _emberSimpleAuthServicesSession) {
  exports['default'] = _emberSimpleAuthServicesSession['default'].extend({
    store: _ember['default'].inject.service(),

    user: _ember['default'].computed('data.authenticated.userId', function () {
      var userId = this.get('data.authenticated.userId');
      if (!_ember['default'].isEmpty(userId)) {
        return _emberData['default'].PromiseObject.create({
          promise: this.get('store').find('user', userId)
        });
      }
    }),

    token: _ember['default'].computed('data.authenticated.token', function () {
      return this.get('data.authenticated.token');
    })
  });
});