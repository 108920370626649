define('sportly/routes/football-game/member/ban', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/rsvp', 'sportly/enums/football-game/member-role'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsFootballGameRsvp, _sportlyEnumsFootballGameMemberRole) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        afterModel: function afterModel(gameMember) {
            var _this = this;

            if (gameMember.get('rsvpId') === _sportlyEnumsFootballGameRsvp['default'].BANNED) {
                return this.transitionTo('footballGame.member.index', gameMember);
            }

            return this.modelFor('footballGame').get('members').then(function (gameMembers) {
                return gameMembers.findBy('user.id', _this.get('session.user.id'));
            }).then(function (sessionGameMember) {
                if (sessionGameMember.get('roleId') !== _sportlyEnumsFootballGameMemberRole['default'].ADMIN) {
                    return _this.transitionTo('footballGame.member.index', gameMember);
                }
            });
        },

        setupController: function setupController(controller, footballGameMember) {
            controller.set('model', footballGameMember);
            footballGameMember.set('banReason', null);
            this.controllerFor('football-game').set('pageTitle', 'Ban player');
        },

        actions: {
            ban: function ban() {
                var _this = this;
                this.set('currentModel.rsvpId', _sportlyEnumsFootballGameRsvp['default'].BANNED);
                this.get('currentModel').save().then(function () {
                    _this.transitionTo('footballGame.member');
                });
            },

            cancel: function cancel() {
                this.transitionTo('footballGame.member');
            }

        },

        deactivate: function deactivate() {
            if (this.get('currentModel.hasDirtyAttributes')) {
                this.get('currentModel').rollbackAttributes();
            }
        }

    });
});