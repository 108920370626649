define('sportly/components/x-menu-page/link-section/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-menu-page--link-section'],

    // attrs
    title: 'Placeholder',
    description: 'Submit',
    xBtnTheme: 'default',
    xBtnLabel: 'Submit',
    link: null
  });
});