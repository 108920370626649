define('sportly/components/x-utils/scroll-visibility', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-utils--scroll-visibility', 'inViewport'],
        inViewport: false,
        visibilityHandler: null,
        topOffset: 0,

        didInsertElement: function didInsertElement() {
            var _this = this;

            this.visibilityHandler = function () {
                _ember['default'].run.debounce(_this, function () {
                    var scrollTop = _ember['default'].$(window).scrollTop(),
                        windowHeight = _ember['default'].$(window).height();
                    if (_this.$().offset().top + _this.get('topOffset') < scrollTop + windowHeight) {
                        _this.set('inViewport', true);
                    }
                }, 150);
            };

            _ember['default'].$(window).on('DOMContentLoaded load resize scroll touchmove', this.visibilityHandler);
        },

        willDestroyElement: function willDestroyElement() {
            _ember['default'].$(window).off('DOMContentLoaded load resize scroll touchmove', this.visibilityHandler);
        }
    });
});