define('sportly/components/x-map-infoboxes/venue', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-map-infoboxes--venue'],
        place: null,

        placeSelected: _ember['default'].observer('place', function () {
            if (!_ember['default'].isEmpty(this.get('place'))) {
                _ember['default'].run.scheduleOnce('afterRender', this, function () {
                    var html = this.$().clone().find("script,noscript,style").remove().end().html();
                    this.sendAction('onSetInfoboxContent', html);
                });
            }
        })
    });
});