define('sportly/routes/group/create-football-game/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/rsvp', 'sportly/enums/group/status'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsFootballGameRsvp, _sportlyEnumsGroupStatus) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    session: _ember['default'].inject.service('session'),

    setupController: function setupController(controller) {
      controller.set('isSaving', false);
    },

    actions: {

      chooseVenue: function chooseVenue() {
        var venue = this.controllerFor('group.createFootballGame').get('venue');

        if (!_ember['default'].isEmpty(venue)) {
          return this.transitionTo('group.createFootballGame.chooseVenue', {
            queryParams: {
              latitude: venue.get('latitude'),
              longitude: venue.get('longitude')
            }
          });
        }
        this.transitionTo('group.createFootballGame.chooseVenue');
      },

      create: function create(footballGamesParams) {

        var route = this,
            footballGame = null,
            promises = [],
            controller = this.get('controller'),
            errors = controller.get('errors');

        controller.set('isSaving', true);

        footballGamesParams.forEach(function (footballGameParams) {

          footballGame = route.store.createRecord('football-game', {
            homeTeam: route.store.createRecord('football-team', {
              name: footballGameParams.homeTeamName
            }),
            awayTeam: route.store.createRecord('football-team', {
              name: footballGameParams.awayTeamName
            }),
            venue: footballGameParams.venue,
            group: footballGameParams.group,
            creator: route.get('session.user'),
            gameTypeId: footballGameParams.gameTypeId,
            url: '',
            timestamp: 0,
            localTime: footballGameParams.localTime,
            localDate: footballGameParams.localDate,
            cancellationMessage: '',
            gameLength: footballGameParams.gameLength,
            playerLimit: footballGameParams.playerLimit,
            isCancelled: false,
            isFinished: false,
            rsvpOpenTimeDelta: footballGameParams.rsvpOpenTimeDelta,
            rsvpCloseTimeDelta: footballGameParams.rsvpCloseTimeDelta
          });

          promises.pushObject(route.saveFootballGame(footballGame));
        });

        _ember['default'].RSVP.all(promises).then(function () {
          controller.set('isSaving', false);
          if (controller.get('isNoob')) {
            controller.set('group.status', _sportlyEnumsGroupStatus['default'].ACTIVE);
            controller.get('group').save().then(function (group) {
              route.transitionTo('group.index', group);
            });
            return;
          }
          if (promises.length > 1) {
            route.transitionTo('group.fixtures', controller.get('group'));
          } else {
            route.transitionTo('footballGame.index', promises.get('firstObject'));
          }
        })['catch'](function (e) {
          e.errors.forEach(function (error) {
            var key = error.source.pointer.substring(16);
            errors.add(key, error.detail);
          });
          controller.set('isSaving', false);
        });
      },

      skip: function skip() {
        var route = this,
            group = this.modelFor('group');

        group.set('status', 1);
        group.save().then(function (group) {
          route.transitionTo('group.index', group);
        });
      },

      cancel: function cancel() {
        var route = this,
            group = this.modelFor('group');
        if (this.get('controller.isNoob')) {
          group.set('status', _sportlyEnumsGroupStatus['default'].ACTIVE);
          group.save().then(function (group) {
            route.transitionTo('group.index', group);
          });
          return;
        }
        this.transitionTo('group.index', group);
      }

    },

    saveFootballGame: function saveFootballGame(footballGame) {
      var route = this;
      return footballGame.get('homeTeam').save().then(function () {
        return footballGame.get('awayTeam').save();
      }).then(function () {
        return footballGame.save();
      }).then(function () {
        return _ember['default'].RSVP.all([footballGame.get('creator.gameMembers'), footballGame.get('members'), footballGame.get('group.games'), footballGame.get('group.members')]);
      }).then(function () {
        return footballGame.get('group.members').mapBy('user');
      }).then(function () {
        var gameMembers = [];

        footballGame.get('group.games').pushObject(footballGame);

        footballGame.get('group.members').forEach(function (groupMember) {
          var gameMember = route.store.createRecord('game-member', {
            user: groupMember.get('user.content'),
            footballGame: footballGame,
            roleId: groupMember.get('roleId'),
            rsvpId: _sportlyEnumsFootballGameRsvp['default'].PENDING
          });
          footballGame.get('members').pushObject(gameMember);
          if (footballGame.get('creator.id') === groupMember.get('user.id')) {
            footballGame.get('creator.gameMembers').pushObject(gameMember);
          }
          gameMembers.pushObject(gameMember);
        });
        return gameMembers.invoke('save');
      }).then(function () {
        return footballGame;
      });
    }
  });
});