define('sportly/components/x-football-games/properties', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-football-games--properties'],
        footballGame: null,

        hasGroup: _ember['default'].computed.notEmpty('footballGame.group.id'),

        playerLimit: _ember['default'].computed.alias('footballGame.playerLimit'),
        hasPlayerLimit: _ember['default'].computed.gt('playerLimit', 0),

        gameLengthMinutes: _ember['default'].computed('footballGame.gameLength', function () {
            return this.get('footballGame.gameLength') / 60;
        }),

        gameType: _ember['default'].computed('footballGame.gameTypeId', function () {
            return this.get('footballGame.gameTypeId') + 1;
        })
    });
});