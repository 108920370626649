define("sportly/templates/group/create-football-game/choose-venue", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 7,
                    "column": 3
                  },
                  "end": {
                    "line": 15,
                    "column": 3
                  }
                },
                "moduleName": "sportly/templates/group/create-football-game/choose-venue.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("				");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn-add-venue");
                var el2 = dom.createTextNode("\n					");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("span");
                dom.setAttribute(el2, "class", "flaticon stroke plus-1");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("Add venue\n				");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element1);
                return morphs;
              },
              statements: [["element", "action", ["addVenue"], [], ["loc", [null, [12, 34], [12, 55]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 5
                  },
                  "end": {
                    "line": 38,
                    "column": 5
                  }
                },
                "moduleName": "sportly/templates/group/create-football-game/choose-venue.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("						");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "venue");
                var el2 = dom.createTextNode("\n							");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "thumb");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "flaticon solid location-pin-1");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n							");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "name");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n							");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "description");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n						");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element0);
                morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
                morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["chooseVenue", ["get", "venue", ["loc", [null, [33, 47], [33, 52]]]]], [], ["loc", [null, [33, 24], [33, 54]]]], ["content", "venue.name", ["loc", [null, [35, 25], [35, 39]]]], ["inline", "str-truncate", [["get", "venue.description", ["loc", [null, [36, 47], [36, 64]]]]], ["limit", 100], ["loc", [null, [36, 32], [36, 76]]]]],
              locals: ["venue"],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 38,
                    "column": 5
                  },
                  "end": {
                    "line": 40,
                    "column": 5
                  }
                },
                "moduleName": "sportly/templates/group/create-football-game/choose-venue.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("						");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "no-venues");
                var el2 = dom.createTextNode("No venues found");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 2
                },
                "end": {
                  "line": 45,
                  "column": 2
                }
              },
              "moduleName": "sportly/templates/group/create-football-game/choose-venue.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n			");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n			");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "results");
              var el2 = dom.createTextNode("\n				");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("ul");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("				");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n			");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5, 1]), 1, 1);
              return morphs;
            },
            statements: [["block", "x-map/controls", [], ["query", ["subexpr", "@mut", [["get", "query", ["loc", [null, [8, 10], [8, 15]]]]], [], []], "onLocateUser", "onLocateUser", "onSearch", "onSearch"], 0, null, ["loc", [null, [7, 3], [15, 22]]]], ["inline", "x-map/map", [], ["mapId", "sportly.map-v8jdk228", "places", ["subexpr", "@mut", [["get", "venues", ["loc", [null, [19, 11], [19, 17]]]]], [], []], "latitude", ["subexpr", "@mut", [["get", "latitude", ["loc", [null, [20, 13], [20, 21]]]]], [], []], "longitude", ["subexpr", "@mut", [["get", "longitude", ["loc", [null, [21, 14], [21, 23]]]]], [], []], "zoom", ["subexpr", "@mut", [["get", "zoom", ["loc", [null, [22, 9], [22, 13]]]]], [], []], "placeId", ["subexpr", "@mut", [["get", "venueId", ["loc", [null, [23, 12], [23, 19]]]]], [], []], "query", ["subexpr", "@mut", [["get", "searchQuery", ["loc", [null, [24, 10], [24, 21]]]]], [], []], "isLocatingUser", ["subexpr", "@mut", [["get", "isLocatingUser", ["loc", [null, [25, 19], [25, 33]]]]], [], []], "onMapMove", "onMapMove", "infobox", "x-map-infoboxes/venue"], ["loc", [null, [17, 3], [28, 5]]]], ["block", "each", [["get", "venues", ["loc", [null, [32, 13], [32, 19]]]]], ["key", "@identity"], 1, 2, ["loc", [null, [32, 5], [40, 14]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.3",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 1
              },
              "end": {
                "line": 46,
                "column": 1
              }
            },
            "moduleName": "sportly/templates/group/create-football-game/choose-venue.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "x-forms/full-form", [], ["class", "choose-venue"], 0, null, ["loc", [null, [5, 2], [45, 24]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 47,
              "column": 0
            }
          },
          "moduleName": "sportly/templates/group/create-football-game/choose-venue.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "x-group/layout", [], [], 0, null, ["loc", [null, [4, 1], [46, 20]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 47,
            "column": 18
          }
        },
        "moduleName": "sportly/templates/group/create-football-game/choose-venue.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "x-global/navs", [], ["pageTitle", "Choose venue"], 0, null, ["loc", [null, [1, 0], [47, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});