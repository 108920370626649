define('sportly/components/x-stories/profile-image-change-story', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-stories--profile-image-change-story', 'isLoading'],

        isLoading: _ember['default'].computed.not('isLoaded'),
        isLoaded: _ember['default'].computed.and('story.user.profile.isLoaded', 'story.user.profile.photo.isLoaded'),

        contextIsSessionUser: _ember['default'].computed('story.user', 'source', function () {
            return this.get('story.user.content') === this.get('source');
        })
    });
});