define('sportly/routes/football-game/settings', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        beforeModel: function beforeModel(transition) {
            this._super(transition);

            var footballGame = this.modelFor('footballGame');

            return footballGame.get('creator').then(function () {
                if (!_ember['default'].isEmpty(footballGame.get('group.content'))) {
                    return footballGame.get('group.members').then(function (groupMembers) {
                        return _ember['default'].RSVP.all(groupMembers.mapBy('user'));
                    });
                }
            });
        },

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('football-game').set('pageTitle', 'Settings');
        }

    });
});