define('sportly/adapters/application', ['exports', 'ember-data', 'sportly/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _sportlyConfigEnvironment, _emberSimpleAuthMixinsDataAdapterMixin) {

  var ApplicationAdapter = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], {
    authorizer: 'authorizer:application',
    coalesceFindRequests: true,
    host: _sportlyConfigEnvironment['default'].API.host,
    namespace: _sportlyConfigEnvironment['default'].API.namespace
  });

  exports['default'] = ApplicationAdapter;
});