define('sportly/routes/profile', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        model: function model(params) {
            return this.store.query('profile', {
                url: params.profileUrl
            }).then(function (profiles) {
                return profiles.get('firstObject');
            });
        },

        afterModel: function afterModel(profile) {
            return profile.get('photo').then(function () {
                return profile.get('user');
            }).then(function () {
                return profile.get('user.groupMembers');
            }).then(function (groupMembers) {
                return _ember['default'].RSVP.all(groupMembers.mapBy('group'));
            }).then(function () {
                return profile.get('user.gameMembers');
            }).then(function (gameMembers) {
                return _ember['default'].RSVP.all(gameMembers.mapBy('footballGame'));
            }).then(function () {
                return profile.get('user.followers');
            }).then(function (followers) {
                return _ember['default'].RSVP.all(followers.mapBy('user'));
            }).then(function () {
                return profile.get('user.following');
            }).then(function (following) {
                return _ember['default'].RSVP.all(following.mapBy('user'));
            }).then(function () {
                return _ember['default'].RSVP.all(profile.get('user.following').mapBy('followed'));
            }).then(function () {
                return _ember['default'].RSVP.all(profile.get('user.followers').mapBy('followed'));
            });
        },

        serialize: function serialize(profile) {
            return { profileUrl: _ember['default'].get(profile, 'url') };
        },

        actions: {
            follow: function follow() {
                var _this = this;
                return this.store.createRecord('follower', {
                    user: this.get('session.user'),
                    followed: this.get('currentModel.user')
                }).save().then(function (follower) {
                    return _this.store.createRecord('story/user-follow', {
                        user: follower.get('user'),
                        followed: follower.get('followed')
                    }).save();
                }).then(function (story) {
                    _this.get('currentModel.stories').pushObject(story);
                });
            }
        }

    });
});