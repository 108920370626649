define('sportly/routes/profile-image', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        beforeModel: function beforeModel(transition) {
            this._super(transition);

            return this.get('session.user').then(function (user) {
                return user.get('profile');
            }).then(function (profile) {
                return profile.get('photo');
            });
        },

        model: function model() {
            return this.get('session.user.profile.photo');
        },

        actions: {

            didUpload: function didUpload(filename) {
                var _this = this;
                this.store.createRecord('photo', {
                    filename: filename
                }).save().then(function (photo) {
                    _this.set('controller.model', photo);
                });
            },

            save: function save() {
                var _this = this,
                    profile = this.get('session.user.profile.content'),
                    photo = this.get('controller.model');

                profile.set('photo', photo);

                profile.save().then(function () {
                    return _this.store.createRecord('story/profile-image-change', {
                        user: profile.get('user'),
                        photo: photo
                    }).save();
                }).then(function (story) {
                    profile.get('stories').pushObject(story);
                    _this.transitionTo('profile', profile);
                });
            },

            cancel: function cancel() {
                this.transitionTo('profile', this.get('session.user.profile'));
            }
        },

        deactivate: function deactivate() {
            if (this.get('session.user.profile').get('hasDirtyAttributes')) {
                this.get('session.user.profile').rollbackAttributes();
            }
        }

    });
});