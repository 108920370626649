define('sportly/models/venue', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        name: _emberData['default'].attr('string'),
        description: _emberData['default'].attr('string'),
        latitude: _emberData['default'].attr('string'),
        longitude: _emberData['default'].attr('string'),
        timeZone: _emberData['default'].attr('string'),
        url: _emberData['default'].attr('string'),
        groups: _emberData['default'].hasMany('group', { async: true, inverse: 'venue' }),
        footballGames: _emberData['default'].hasMany('football-game', { async: true })
    });
});