define('sportly/components/x-football-games/join', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-football-games--join'],
        group: null,
        actions: {
            join: function join() {
                this.sendAction('join', this.get('group'));
            }
        }
    });
});