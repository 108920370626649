define('sportly/controllers/profile', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        session: _ember['default'].inject.service('session'),

        me: _ember['default'].computed('session.user.profile', function () {
            return this.get('session.user.profile');
        }),

        name: _ember['default'].computed('model.firstName', 'model.lastName', function () {
            return this.get('model.firstName') + ' ' + this.get('model.lastName');
        }),

        isMe: _ember['default'].computed('model.id', 'me.id', function () {
            return this.get('model.id') === this.get('me.id');
        }),

        isFriend: _ember['default'].computed('friends.[]', 'session.user.id', function () {
            return this.get('friends').isAny('id', this.get('session.user.id'));
        }),

        isFollowingMe: _ember['default'].computed('allFollowingUsers.@each.id', 'session.user.id', function () {
            return this.get('allFollowingUsers').isAny('id', this.get('session.user.id'));
        }),

        iAmFollowing: _ember['default'].computed('allFollowerUsers.@each.id', 'session.user.id', function () {
            return this.get('allFollowerUsers').isAny('id', this.get('session.user.id'));
        }),

        friends: _ember['default'].computed('allFollowingUsers.@each.isLoaded', 'allFollowerUsers.@each.isLoaded', function () {
            var controller = this;
            return this.get('allFollowingUsers').filter(function (followingUser) {
                return controller.get('allFollowerUsers').findBy('id', followingUser.get('id'));
            });
        }),

        isFemale: _ember['default'].computed('model.gender', function () {
            return this.get('model.gender') === 'f';
        }),

        followers: _ember['default'].computed('allFollowingUsers.@each.isLoaded', 'allFollowerUsers.@each.isLoaded', function () {
            var controller = this;
            return this.get('allFollowerUsers').filter(function (followerUser) {
                return !controller.get('allFollowingUsers').isAny('id', followerUser.get('id'));
            });
        }),

        following: _ember['default'].computed('allFollowingUsers.@each.isLoaded', 'allFollowerUsers.@each.isLoaded', function () {
            var controller = this;
            return this.get('allFollowingUsers').filter(function (followingUser) {
                return !controller.get('allFollowerUsers').isAny('id', followingUser.get('id'));
            });
        }),

        allFollowingUsers: _ember['default'].computed('model.user.following.@each.followed', function () {
            return this.get('model.user.following').mapBy('followed');
        }),

        allFollowerUsers: _ember['default'].computed('model.user.followers.@each.user', function () {
            return this.get('model.user.followers').mapBy('user');
        }),

        relationshipsLoaded: _ember['default'].computed('model.isLoaded', 'model.user.following.@each.isLoaded', 'model.user.followers.@each.isLoaded', 'allFollowingUsers.@each.isLoaded', 'allFollowerUsers.@each.isLoaded', function () {
            return this.get('model.isLoaded') && this.get('allFollowerUsers').everyBy('isLoaded') && this.get('allFollowingUsers').everyBy('isLoaded') && this.get('model.following').everyBy('isLoaded') && this.get('model.followers').everyBy('isLoaded');
        }),

        hasFriends: _ember['default'].computed('friends.length', function () {
            return this.get('friends.length') > 0;
        }),

        hasFollowers: _ember['default'].computed('model.followers.length', function () {
            return this.get('model.followers.length') > 0;
        }),

        hasFollowing: _ember['default'].computed('model.following.length', function () {
            return this.get('model.following.length') > 0;
        }),

        iCanFollow: _ember['default'].computed('iAmFollowing', 'isFriend', 'isMe', function () {
            return !this.get('iAmFollowing') && !this.get('isFriend') && !this.get('isMe');
        }),

        iCanPost: _ember['default'].computed('isFollowingMe', 'isFriend', 'isMe', function () {
            return this.get('isFollowingMe') || this.get('isFriend') || this.get('isMe');
        }),

        unreadNotifications: _ember['default'].computed('notifications.@each.unread', function () {
            return this.get('notifications').filterBy('unread', 1);
        }),
        unreadNotificationsCount: _ember['default'].computed.alias('unreadNotifications.length'),
        hasUnreadNotifications: _ember['default'].computed.notEmpty('unreadNotifications')

    });
});