define('sportly/routes/welcome', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        model: function model() {
            return this.get('session.user.profile');
        },

        actions: {

            willTransition: function willTransition(transition) {

                if (transition.targetName.split('.')[0] !== 'welcome') {
                    var profile = this.get('currentModel');
                    profile.set('onboarding', false);
                    return profile.save();
                }
            }
        }

    });
});