define('sportly/routes/group/create/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/member-role'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsGroupMemberRole) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        model: function model() {
            return this.modelFor('group.create');
        },

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('group.create').set('pageTitle', 'Create group');
        },

        actions: {

            create: function create() {

                var group = this.get('currentModel'),
                    _this = this,
                    user = this.get('session.user');

                //As venue doesnt seem to clear by itself
                group.get('errors').clear();

                if (_ember['default'].isEmpty(group.get('name'))) {
                    group.get('errors').add('name', 'Name is required');
                } else if (group.get('name') < 5) {
                    group.get('errors').add('name', 'Name must be at least 5 characters');
                }
                if (_ember['default'].isEmpty(group.get('description'))) {
                    group.get('errors').add('description', 'Description is required');
                }
                if (_ember['default'].isEmpty(group.get('venue'))) {
                    group.get('errors').add('venue', 'Location is required');
                }

                if (!group.get('isValid')) {
                    return;
                }

                group.save().then(function () {
                    return _ember['default'].RSVP.all([user.get('groupMembers'), group.get('members')]);
                }).then(function (groupMemberRelationships) {
                    var groupMember = _this.store.createRecord('group-member', {
                        user: user,
                        group: group,
                        roleId: _sportlyEnumsGroupMemberRole['default'].ADMIN
                    });
                    groupMemberRelationships[0].pushObject(groupMember);
                    groupMemberRelationships[1].pushObject(groupMember);
                    return groupMember.save();
                }).then(function () {
                    return _this.store.createRecord('story/group-create', {
                        user: user,
                        group: group
                    }).save();
                }).then(function () {
                    _this.transitionTo('group.invite', group);
                })['catch'](function () {});
            },

            chooseVenue: function chooseVenue() {
                var _this = this;
                this.get('currentModel.venue').then(function (venue) {
                    if (!_ember['default'].isEmpty(venue)) {
                        return _this.transitionTo('group.create.chooseVenue', {
                            queryParams: {
                                latitude: venue.get('latitude'),
                                longitude: venue.get('longitude')
                            }
                        });
                    }
                    _this.transitionTo('group.create.chooseVenue');
                });
            }

        }
    });
});