define("sportly/templates/components/x-group/invite-friends", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 6
          }
        },
        "moduleName": "sportly/templates/components/x-group/invite-friends.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "invite-friends-wrapper");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["inline", "x-group/invite-friends-form", [], ["inviteables", ["subexpr", "@mut", [["get", "inviteables", ["loc", [null, [3, 16], [3, 27]]]]], [], []], "hasFriends", ["subexpr", "@mut", [["get", "hasFriends", ["loc", [null, [4, 15], [4, 25]]]]], [], []], "query", ["subexpr", "@mut", [["get", "attrs.query", ["loc", [null, [5, 10], [5, 21]]]]], [], []], "isInviting", ["subexpr", "@mut", [["get", "attrs.isInviting", ["loc", [null, [6, 15], [6, 31]]]]], [], []], "done", ["subexpr", "@mut", [["get", "attrs.done", ["loc", [null, [7, 9], [7, 19]]]]], [], []], "search", ["subexpr", "@mut", [["get", "attrs.search", ["loc", [null, [8, 11], [8, 23]]]]], [], []], "invite", ["subexpr", "@mut", [["get", "attrs.invite", ["loc", [null, [9, 11], [9, 23]]]]], [], []]], ["loc", [null, [2, 2], [10, 4]]]]],
      locals: [],
      templates: []
    };
  })());
});