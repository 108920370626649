define('sportly/components/x-group-members/member', ['exports', 'ember', 'sportly/enums/group/member-role'], function (exports, _ember, _sportlyEnumsGroupMemberRole) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-group-members--member'],

    profile: _ember['default'].computed.alias('member.user.profile'),
    photo: _ember['default'].computed.alias('profile.photo.square'),

    isAdmin: _ember['default'].computed('member.roleId', function () {
      return this.getAttr('member').get('roleId') === _sportlyEnumsGroupMemberRole['default'].ADMIN;
    })
  });
});