define('sportly/routes/football-game/create', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'sportly/enums/football-game/frequency', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _sportlyEnumsFootballGameFrequency, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
        setupController: function setupController(controller) {
            controller.get('errors').clear();
            controller.setProperties({
                homeTeamName: '',
                awayTeamName: '',
                venue: null,
                group: null,
                gameTypeId: 4,
                gameLength: 3600,
                playerLimit: 0,
                localTime: '18:00',
                frequency: _sportlyEnumsFootballGameFrequency['default'].SINGLE,
                startDate: moment().add(1, 'd').format('DD/MM/YYYY'),
                finishDate: moment().add(14, 'd').format('DD/MM/YYYY')
            });
        }
    });
});