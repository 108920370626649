define('sportly/components/x-stories/app-join-story', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-stories--app-join-story', 'isLoading'],
        session: _ember['default'].inject.service('session'),

        isLoading: _ember['default'].computed.not('isLoaded'),
        isLoaded: _ember['default'].computed.and('story.user.profile.isLoaded', 'story.user.profile.photo.isLoaded'),

        isSessionUser: _ember['default'].computed('session.user.id', 'story.user.id', function () {
            return this.get('session.user.id') === this.get('story.user.id');
        })
    });
});