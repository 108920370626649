define('sportly/routes/guest/join', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service(),

        actions: {

            join: function join() {
                var _this = this,
                    mixpanel = this.get('mixpanel'),
                    errors = this.get('controller.errors'),
                    firstName = this.get('controller.firstName'),
                    lastName = this.get('controller.lastName'),
                    email = this.get('controller.email'),
                    password = this.get('controller.password'),
                    appInvite = this.get('controller.appInvite'),
                    profile = null,
                    user = null,
                    authenticator = 'authenticator:token';

                mixpanel.trackEvent("join: submitted", {
                    firstName: firstName,
                    lastName: lastName,
                    email: email
                });
                errors.clear();

                if (_ember['default'].isEmpty(firstName)) {
                    errors.add('firstName', 'First name is required');
                }
                if (_ember['default'].isEmpty(lastName)) {
                    errors.add('lastName', 'Last name is required');
                }
                if (_ember['default'].isEmpty(email) || email.length < 5) {
                    errors.add('email', 'Email is required');
                }
                if (_ember['default'].isEmpty(password) || password.length < 7) {
                    errors.add('password', 'Password is required and must be a minimum of 7 characters');
                }

                if (!errors.get('isEmpty')) {
                    mixpanel.trackEvent("join: error", {
                        'firstName': errors.has('firstName') ? errors.errorsFor('firstName')[0].message : '',
                        'lastName': errors.has('lastName') ? errors.errorsFor('lastName')[0].message : '',
                        'email': errors.has('email') ? errors.errorsFor('email')[0].message : '',
                        'password': errors.has('password') ? errors.errorsFor('password')[0].message : ''
                    });
                    return;
                }

                profile = this.store.createRecord('profile', {
                    firstName: firstName,
                    lastName: lastName
                });

                user = this.store.createRecord('user', {
                    email: email,
                    password: password,
                    profile: profile
                });

                if (!_ember['default'].isEmpty(appInvite)) {
                    user.set('appInvite', appInvite);
                }

                user.save().then(function () {
                    profile.set('user', user);
                    return profile.save();
                }).then(function () {
                    mixpanel.alias(email);
                    mixpanel.peopleSet({
                        "$email": email,
                        "$created": new Date(),
                        "$last_login": new Date()
                    });
                    return _this.get('session').authenticate(authenticator, {
                        identification: email,
                        password: password
                    });
                })['catch'](function (e) {
                    e.errors.forEach(function (error) {
                        var key = error.source.pointer.substring(16);
                        errors.add(key, error.detail);
                    });
                });
            },

            didTransition: function didTransition() {
                if (!this.get('controller.appInviteIsUsed')) {
                    this.controllerFor('guest').set('navIsHidden', true);
                }
                return true;
            },

            willTransition: function willTransition() {
                this.controllerFor('guest').set('navIsHidden', false);
                return true;
            }
        }

    });
});