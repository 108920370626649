define('sportly/routes/profile/friends', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        beforeModel: function beforeModel(transition) {
            var profile = this.modelFor('profile');

            this._super(transition);

            return profile.get('user').then(function (user) {
                return user.get('followers');
            }).then(function (followers) {
                return _ember['default'].RSVP.all(followers.mapBy('user'));
            }).then(function (followerUsers) {
                return _ember['default'].RSVP.all(followerUsers.mapBy('profile'));
            }).then(function () {
                return profile.get('user.following');
            }).then(function (following) {
                return _ember['default'].RSVP.all(following.mapBy('followed'));
            }).then(function (followedUsers) {
                return _ember['default'].RSVP.all(followedUsers.mapBy('profile'));
            });
        },

        model: function model() {
            var profile = this.modelFor('profile'),
                followerUsers = profile.get('user.followers').mapBy('user'),
                followingUsers = profile.get('user.following').mapBy('followed');

            return followingUsers.filter(function (followingUser) {
                return followerUsers.findBy('id', followingUser.get('id'));
            });
        },

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('profile').set('pageTitle', 'Friends');
        }

    });
});