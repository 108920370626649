define("sportly/templates/dashboard", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 35,
              "column": 0
            }
          },
          "moduleName": "sportly/templates/dashboard.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "container page");
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "row");
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "asides");
          var el4 = dom.createTextNode("\n\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "hidden-xs");
          var el5 = dom.createTextNode("\n\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n\n				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n			");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("article");
          dom.setAttribute(el3, "class", "article");
          var el4 = dom.createTextNode("\n\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [5]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(element1, 3, 3);
          morphs[2] = dom.createMorphAt(element2, 1, 1);
          morphs[3] = dom.createMorphAt(element2, 3, 3);
          morphs[4] = dom.createMorphAt(element2, 5, 5);
          morphs[5] = dom.createMorphAt(element2, 7, 7);
          morphs[6] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "x-profiles/upcoming-fixtures", [], ["profile", ["subexpr", "@mut", [["get", "session.user.profile", ["loc", [null, [8, 43], [8, 63]]]]], [], []]], ["loc", [null, [8, 4], [8, 65]]]], ["inline", "render", ["invite-friends-banner"], [], ["loc", [null, [10, 4], [10, 38]]]], ["inline", "x-groups/groups-summary", [], ["user", ["subexpr", "@mut", [["get", "session.user", ["loc", [null, [14, 36], [14, 48]]]]], [], []], "showActions", true], ["loc", [null, [14, 5], [14, 67]]]], ["inline", "x-profiles/friends", [], ["user", ["subexpr", "@mut", [["get", "session.user", ["loc", [null, [16, 31], [16, 43]]]]], [], []]], ["loc", [null, [16, 5], [16, 45]]]], ["inline", "x-profiles/followers", [], ["user", ["subexpr", "@mut", [["get", "session.user", ["loc", [null, [18, 33], [18, 45]]]]], [], []]], ["loc", [null, [18, 5], [18, 47]]]], ["inline", "x-profiles/following", [], ["user", ["subexpr", "@mut", [["get", "session.user", ["loc", [null, [20, 33], [20, 45]]]]], [], []]], ["loc", [null, [20, 5], [20, 47]]]], ["inline", "x-stories/stories", [], ["source", ["subexpr", "@mut", [["get", "session.user", ["loc", [null, [28, 31], [28, 43]]]]], [], []], "sessionCanPost", false], ["loc", [null, [28, 4], [28, 66]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 18
          }
        },
        "moduleName": "sportly/templates/dashboard.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "x-global/navs", [], [], 0, null, ["loc", [null, [1, 0], [35, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});