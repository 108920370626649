define('sportly/routes/group/edit/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        actions: {
            saveChanges: function saveChanges() {

                var group = this.get('currentModel'),
                    route = this;

                if (_ember['default'].isEmpty(group.get('name'))) {
                    group.get('errors').add('name', 'Group name is required');
                }
                if (_ember['default'].isEmpty(group.get('description'))) {
                    group.get('errors').add('description', 'Group description is required');
                }

                if (!group.get('isValid')) {
                    return;
                }

                group.save().then(function () {
                    route.transitionTo('group');
                })['catch'](function () {});
            },

            cancel: function cancel() {
                this.transitionTo('group.index');
            },

            chooseVenue: function chooseVenue() {
                var _this = this;
                this.get('currentModel.venue').then(function (venue) {
                    if (!_ember['default'].isEmpty(venue)) {
                        return _this.transitionTo('group.edit.chooseVenue', {
                            queryParams: {
                                latitude: venue.get('latitude'),
                                longitude: venue.get('longitude')
                            }
                        });
                    }
                    _this.transitionTo('group.edit.chooseVenue');
                });
            }

        }

    });
});