define('sportly/routes/group/create', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsGroupStatus) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        model: function model() {
            return this.store.createRecord('group', {
                status: _sportlyEnumsGroupStatus['default'].NOOB
            });
        },

        deactivate: function deactivate() {

            if (this.get('currentModel').get('isNew')) {
                this.get('currentModel').deleteRecord();
            }
        }
    });
});