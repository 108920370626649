define('sportly/components/x-guest/change-password-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-guest--change-password-form'],

    isPasswordRequiredError: _ember['default'].computed('passwordChange.errors.newPassword.firstObject', function () {
      return this.get('passwordChange.errors.newPassword.firstObject.message') === 'PASSWORD_REQUIRED';
    }),

    isPasswordTooShortError: _ember['default'].computed('passwordChange.errors.newPassword.firstObject', function () {
      return this.get('passwordChange.errors.newPassword.firstObject.message') === 'PASSWORD_TOO_SHORT';
    }),

    hasResetTokenError: _ember['default'].computed.or('isResetTokenRequiredError', 'isResetTokenUsedError', 'isResetTokenExpiredError'),

    isResetTokenRequiredError: _ember['default'].computed('passwordChange.errors.resetTokenGuid.firstObject', function () {
      return this.get('passwordChange.errors.resetTokenGuid.firstObject.message') === 'RESET_TOKEN_REQUIRED';
    }),

    isResetTokenUsedError: _ember['default'].computed('passwordChange.errors.resetTokenGuid.firstObject', function () {
      return this.get('passwordChange.errors.resetTokenGuid.firstObject.message') === 'RESET_TOKEN_USED';
    }),

    isResetTokenExpiredError: _ember['default'].computed('passwordChange.errors.resetTokenGuid.firstObject', function () {
      return this.get('passwordChange.errors.resetTokenGuid.firstObject.message') === 'RESET_TOKEN_EXPIRED';
    })

  });
});