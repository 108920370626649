define('sportly/initializers/patch-cors-support', ['exports', 'ember', 'sportly/config/environment'], function (exports, _ember, _sportlyConfigEnvironment) {
  exports.initialize = initialize;

  function initialize() {
    if (typeof _sportlyConfigEnvironment['default'].xdomain === 'undefined' || _sportlyConfigEnvironment['default'].xdomain.isDisabled === true) {
      return;
    }
    _ember['default'].$.support.cors = true;
  }

  exports['default'] = {
    name: 'patch-with-credentials',
    initialize: initialize
  };
});