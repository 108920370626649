define('sportly/components/x-profiles/followers', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-profiles--followers', 'hasFollowers'],
        user: null,
        hasFollowers: _ember['default'].computed.notEmpty('followerUsers'),

        followerUsers: _ember['default'].computed('user.followers.@each.user', 'user.following.@each.followed', function () {
            var followerUsers = this.get('user.followers').mapBy('user'),
                followingUsers = this.get('user.following').mapBy('followed');
            return followerUsers.filter(function (followerUser) {
                return !followingUsers.isAny('id', followerUser.get('id'));
            });
        })

    });
});