define('sportly/components/x-profiles/header', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-profiles--header'],
        session: _ember['default'].inject.service('session'),
        profile: null,

        isSessionUser: _ember['default'].computed('session.user.id', 'profile.user.id', function () {
            return this.get('session.user.id') === this.get('profile.user.id');
        }),

        profileName: _ember['default'].computed('profile.firstName', 'profile.lastName', function () {
            return this.get('profile.firstName') + ' ' + this.get('profile.lastName');
        }),

        isFriend: _ember['default'].computed('friends.@each.id', 'session.user.id', function () {
            return this.get('friends').isAny('id', this.get('session.user.id'));
        }),

        isFollowee: _ember['default'].computed('profile.user.followers.@each.followed', 'session.user.id', function () {
            return this.get('profile.user.followers').isAny('user.id', this.get('session.user.id'));
        }),

        isFollower: _ember['default'].computed('profile.user.following.@each.followed', 'session.user.id', function () {
            return this.get('profile.user.following').isAny('followed.id', this.get('session.user.id'));
        }),

        friends: _ember['default'].computed('profile.user.followers.@each.user', 'profile.user.following.@each.followed', function () {
            var followerUsers = this.get('profile.user.followers').mapBy('user'),
                followingUsers = this.get('profile.user.following').mapBy('followed');
            return followingUsers.filter(function (followingUser) {
                return followerUsers.findBy('id', followingUser.get('id'));
            });
        }),

        canFollow: _ember['default'].computed('isFollowee', 'isFriend', 'isSessionUser', function () {
            return !this.get('isFollowee') && !this.get('isFriend') && !this.get('isSessionUser');
        }),

        actions: {
            follow: function follow() {
                this.sendAction('follow');
            }
        }

    });
});