define('sportly/routes/football-game/member/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsFootballGameRsvp) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('football-game').set('pageTitle', 'Game member');
        },

        actions: {
            unban: function unban() {
                this.set('currentModel.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING);
                this.get('currentModel').save();
            }
        }
    });
});