define('sportly/routes/group/create-football-game', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/frequency', 'sportly/enums/group/status'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsFootballGameFrequency, _sportlyEnumsGroupStatus) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        afterModel: function afterModel() {
            if (this.modelFor('group').get('status') === _sportlyEnumsGroupStatus['default'].DELETED) {
                return this.transitionTo('group.index');
            }

            return this.modelFor('group').get('members');
        },

        setupController: function setupController(controller) {

            if (!this.controllerFor('group').get('isAdmin')) {
                return this.transitionTo('group.index');
            }

            this.controllerFor('group').set('isHeaderHidden', true);

            controller.get('errors').clear();
            controller.setProperties({
                homeTeamName: '',
                awayTeamName: '',
                venue: this.modelFor('group').get('venue'),
                group: this.modelFor('group'),
                gameTypeId: 4,
                gameLength: 3600,
                playerLimit: 0,
                localTime: '18:00',
                frequency: _sportlyEnumsFootballGameFrequency['default'].SINGLE,
                startDate: moment().add(1, 'd').format('DD/MM/YYYY'),
                finishDate: moment().add(14, 'd').format('DD/MM/YYYY')
            });
        },

        deactivate: function deactivate() {
            this.controllerFor('group').set('isHeaderHidden', false);
        }
    });
});