define('sportly/components/x-ui/email-list-input-results/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-ui--email-list-input-results'],

    showEmailAddresses: false,
    hasEmailAddresses: _ember['default'].computed.notEmpty('emailAddresses'),
    hasMoreThanOne: _ember['default'].computed.gt('emailAddresses.length', 1),

    actions: {
      toggleShowEmailAddresses: function toggleShowEmailAddresses() {
        this.toggleProperty('showEmailAddresses');
      }
    }
  });
});