define('sportly/controllers/group/create-football-game/index', ['exports', 'ember', 'sportly/enums/group/status'], function (exports, _ember, _sportlyEnumsGroupStatus) {
  exports['default'] = _ember['default'].Controller.extend({

    groupCreateFootballGame: _ember['default'].inject.controller('group/create-football-game'),
    errors: _ember['default'].computed.alias('groupCreateFootballGame.errors'),
    homeTeamName: _ember['default'].computed.alias('groupCreateFootballGame.homeTeamName'),
    awayTeamName: _ember['default'].computed.alias('groupCreateFootballGame.awayTeamName'),
    venue: _ember['default'].computed.alias('groupCreateFootballGame.venue'),
    group: _ember['default'].computed.alias('groupCreateFootballGame.group'),
    gameTypeId: _ember['default'].computed.alias('groupCreateFootballGame.gameTypeId'),
    gameLength: _ember['default'].computed.alias('groupCreateFootballGame.gameLength'),
    playerLimit: _ember['default'].computed.alias('groupCreateFootballGame.playerLimit'),
    localTime: _ember['default'].computed.alias('groupCreateFootballGame.localTime'),
    frequency: _ember['default'].computed.alias('groupCreateFootballGame.frequency'),
    startDate: _ember['default'].computed.alias('groupCreateFootballGame.startDate'),
    finishDate: _ember['default'].computed.alias('groupCreateFootballGame.finishDate'),
    isSaving: false,

    isNoob: _ember['default'].computed.equal('group.status', _sportlyEnumsGroupStatus['default'].NOOB),
    cancelLabel: _ember['default'].computed('isNoob', function () {
      if (this.get('isNoob')) {
        return "Skip";
      }
      return "Cancel";
    })

  });
});