define('sportly/routes/guest/sign-in', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], {

        setupController: function setupController(controller) {
            controller.get('errors').clear();
        },

        actions: {
            didTransition: function didTransition() {
                this.controllerFor('guest').set('navIsHidden', true);
                return true;
            },

            willTransition: function willTransition() {
                this.controllerFor('guest').set('navIsHidden', false);
                return true;
            }
        }

    });
});