define('sportly/components/x-venue/card/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-venue--card'],

    mapsService: _ember['default'].inject.service('maps'),

    directionsLink: _ember['default'].computed('venue.latitude', 'venue.longitude', function () {
      return "https://www.google.co.uk/maps/place/" + this.get('venue.latitude') + "+" + this.get('venue.longitude') + "/@" + this.get('venue.latitude') + "," + this.get('venue.longitude') + ",14z/";
    }),

    map: _ember['default'].computed('venue.latitude', 'venue.longitude', function () {
      return this.get('mapsService').staticMap(this.get('venue.latitude'), this.get('venue.longitude'), 500, 300, '2C97DE');
      //
      // return 'https://api.mapbox.com/v4/mapbox.streets/' +
      //   this.get('venue.longitude') + ',' +
      //   this.get('venue.latitude') +
      //   '/500x300.png?access_token=pk.eyJ1Ijoic3BvcnRseSIsImEiOiJWN3JGMERnIn0.PsLYtNfd0U2VBQ1Zwvfqeg';
      //
      // return 'http://api.tiles.mapbox.com/v3/sportly.map-v8jdk228/pin-l+2C97DE('  +
      //   this.get('venue.longitude') + ',' +
      //   this.get('venue.latitude') +')/'  +
      //   this.get('venue.longitude') + ',' +
      //   this.get('venue.latitude') +
      //   ',15/400x200.png';
    })
  });
});