define('sportly/components/x-global/navs/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.isSideMenuVisible = false;
    },

    session: _ember['default'].inject.service('session'),

    user: _ember['default'].computed.alias('session.user'),

    actions: {
      toggleSideMenu: function toggleSideMenu() {
        this.toggleProperty('isSideMenuVisible');
      },
      signOut: function signOut() {
        this.get('session').invalidate();
      }
    }
  });
});