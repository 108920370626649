define('sportly/routes/football-game', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/football-game/member-role', 'sportly/enums/group/member-role', 'sportly/enums/football-game/rsvp'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsFootballGameMemberRole, _sportlyEnumsGroupMemberRole, _sportlyEnumsFootballGameRsvp) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        afterModel: function afterModel(footballGame) {
            return _ember['default'].RSVP.all([footballGame.get('homeTeam'), footballGame.get('awayTeam'), footballGame.get('venue'), footballGame.get('creator')]).then(function () {
                return footballGame.get('members');
            }).then(function (gameMembers) {
                return _ember['default'].RSVP.all(gameMembers.mapBy('user'));
            }).then(function () {
                return footballGame.get('venue');
            }).then(function () {
                return footballGame.get('group');
            }).then(function (group) {
                return group.get('members');
            }).then(function (groupMembers) {
                return _ember['default'].RSVP.all(groupMembers.mapBy('user'));
            }).then(function () {
                return footballGame.get('group.pendingInvites');
            }).then(function (pendingInvites) {
                return _ember['default'].RSVP.all(pendingInvites.mapBy('receiver'));
            });
        },

        actions: {

            join: function join(group) {
                var _this = this,
                    user = this.get('session.user');

                this.get('controller.pendingInvites').forEach(function (invite) {
                    invite.set('status', 3);
                    invite.save();
                });

                this.store.createRecord('group-member', {
                    user: user,
                    group: group,
                    roleId: _sportlyEnumsGroupMemberRole['default'].MEMBER
                }).save().then(function () {
                    return group.get('games').forEach(function (game) {
                        _this.store.createRecord('game-member', {
                            user: user,
                            footballGame: game,
                            rsvpId: _sportlyEnumsFootballGameRsvp['default'].PENDING,
                            roleId: _sportlyEnumsFootballGameMemberRole['default'].MEMBER,
                            playing: false
                        }).save();
                    });
                }).then(function () {
                    return _this.store.createRecord('story/group-join', {
                        user: user,
                        group: group
                    }).save();
                }).then(function (story) {
                    group.get('stories').pushObject(story);
                });
            },

            ignoreInvites: function ignoreInvites() {
                this.get('controller.myPendingInvites').then(function (invites) {
                    return _ember['default'].RSVP.all(invites.map(function (invite) {
                        invite.set('status', 2);
                    }).invoke('save'));
                });
            },

            attend: function attend() {
                var member = this.get('controller.sessionMember');

                if (this.get('controller.isFull')) {
                    member.set('rsvpId', _sportlyEnumsFootballGameRsvp['default'].RESERVE);
                } else {
                    member.set('rsvpId', _sportlyEnumsFootballGameRsvp['default'].PLAYING);
                }
                return member.save()['catch'](function () {
                    member.rollbackAttributes();
                });
            },

            decline: function decline() {
                this.set('controller.sessionMember.rsvpId', _sportlyEnumsFootballGameRsvp['default'].DECLINED);
                return this.get('controller.sessionMember').save();
            },

            changeRSVP: function changeRSVP() {
                this.set('controller.sessionMember.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING);
            }
        }
    });
});