define('sportly/controllers/application', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        ieCheck: _ember['default'].inject.service('ie-check'),

        isTransitioningBack: false,

        isLoading: false,

        isUnsupportedBrowser: _ember['default'].computed(function () {
            return this.get('ieCheck').isIE(8, 'lte');
        }),

        currentPathChanged: _ember['default'].observer('currentPath', function () {

            // window.location gets updated later in the current run loop, so we will
            // wait until the next run loop to inspect its value and make the call
            // to track the page view
            _ember['default'].run.next(function () {
                var page;
                if (typeof window.ga !== 'undefined') {
                    // Assume that if there is a hash component to the url then we are using
                    // the hash location strategy. Otherwise, we'll assume the history
                    // strategy.
                    page = window.location.hash.length > 0 ? window.location.hash.substring(1) : window.location.pathname;

                    window.ga('send', 'pageview', page);
                }
            });
        })
    });
});