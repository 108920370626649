define('sportly/routes/group/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsGroupStatus) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        model: function model() {
            return this.modelFor('group');
        },

        setupController: function setupController(controller, group) {
            this.controllerFor('group').set('isHeaderHidden', true);
            controller.set('model', group);
            if (!this.controllerFor('group').get('isAdmin')) {
                this.transitionTo('group.index');
            }
        },

        afterModel: function afterModel() {
            if (this.modelFor('group').get('status') !== _sportlyEnumsGroupStatus['default'].ACTIVE) {
                return this.transitionTo('group.index');
            }
        },

        deactivate: function deactivate() {
            this.controllerFor('group').set('isHeaderHidden', false);
            if (this.get('currentModel').get('hasDirtyAttributes')) {
                this.get('currentModel').rollbackAttributes();
            }
        }
    });
});