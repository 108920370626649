define('sportly/components/x-football-games/rsvp-has-not-opened', ['exports', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, _ember, _sportlyEnumsFootballGameRsvp) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':x-football-games--rsvp-has-not-opened'],
    member: null,
    rsvpOpenTime: 0,

    isPending: _ember['default'].computed.equal('member.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING),
    hasDeclined: _ember['default'].computed.equal('member.rsvpId', _sportlyEnumsFootballGameRsvp['default'].DECLINED),
    isBanned: _ember['default'].computed.equal('member.rsvpId', _sportlyEnumsFootballGameRsvp['default'].BANNED),

    time: _ember['default'].computed(function () {
      return moment();
    }),

    rsvpOpensToday: _ember['default'].computed('rsvpOpenTime', function () {
      return this.get('time').isSame(moment(this.get('rsvpOpenTime'), 'X'), 'day');
    }),

    actions: {

      decline: function decline() {
        this.sendAction('decline');
      },

      changeRSVP: function changeRSVP() {
        this.sendAction('changeRSVP');
      }
    }
  });
});