define("sportly/templates/football-game/member/ban", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 2
            },
            "end": {
              "line": 33,
              "column": 2
            }
          },
          "moduleName": "sportly/templates/football-game/member/ban.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ban-form-wrapper");
          var el2 = dom.createTextNode("\n  		");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("header");
          var el3 = dom.createTextNode("\n    		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createTextNode("Ban player from this game?");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "message");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" will be banned from just this one game. You can always change your mind and un-ban them later.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    	");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    	");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("form");
          var el3 = dom.createTextNode("\n    		");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          dom.setAttribute(el4, "class", "control-label");
          dom.setAttribute(el4, "for", "cancellation-message-input");
          var el5 = dom.createTextNode("Reason");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("small");
          var el6 = dom.createTextNode("Sent privately");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "value");
          var el5 = dom.createTextNode("\n					");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n				");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n\n	    	");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "actions");
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "btn-ban");
          var el5 = dom.createTextNode("Ban player");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n				");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "btn-cancel");
          var el5 = dom.createTextNode("Cancel");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n			");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n		");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3]);
          var element1 = dom.childAt(element0, [3]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var element4 = dom.childAt(element3, [1]);
          var element5 = dom.childAt(element3, [3]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1, 3]), 0, 0);
          morphs[2] = dom.createAttrMorph(element2, 'class');
          morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
          morphs[4] = dom.createElementMorph(element4);
          morphs[5] = dom.createElementMorph(element5);
          return morphs;
        },
        statements: [["inline", "x-football-games/member-header", [], ["profile", ["subexpr", "@mut", [["get", "profile", ["loc", [null, [6, 11], [6, 18]]]]], [], []], "isPlaying", ["subexpr", "@mut", [["get", "isPlaying", ["loc", [null, [7, 13], [7, 22]]]]], [], []], "isReserve", ["subexpr", "@mut", [["get", "isReserve", ["loc", [null, [8, 13], [8, 22]]]]], [], []], "hasDeclined", ["subexpr", "@mut", [["get", "hasDeclined", ["loc", [null, [9, 15], [9, 26]]]]], [], []], "isPending", ["subexpr", "@mut", [["get", "isPending", ["loc", [null, [10, 13], [10, 22]]]]], [], []], "iCanUnban", ["subexpr", "@mut", [["get", "iCanUnban", ["loc", [null, [11, 13], [11, 22]]]]], [], []]], ["loc", [null, [5, 4], [12, 4]]]], ["inline", "x-profiles/name", [], ["profile", ["subexpr", "@mut", [["get", "model.user.profile", ["loc", [null, [17, 53], [17, 71]]]]], [], []]], ["loc", [null, [17, 27], [17, 73]]]], ["attribute", "class", ["concat", ["form-group ban-reason ", ["subexpr", "if", [["get", "model.errors.banReason", ["loc", [null, [20, 45], [20, 67]]]], "has-error"], [], ["loc", [null, [20, 40], [20, 81]]]]]]], ["inline", "textarea", [], ["value", ["subexpr", "@mut", [["get", "model.banReason", ["loc", [null, [23, 22], [23, 37]]]]], [], []], "id", "ban-reason-input", "class", "form-control", "placeholder", "e.g. Too many players this week"], ["loc", [null, [23, 5], [23, 128]]]], ["element", "action", ["ban"], [], ["loc", [null, [28, 28], [28, 44]]]], ["element", "action", ["cancel"], [], ["loc", [null, [29, 31], [29, 50]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 0
          }
        },
        "moduleName": "sportly/templates/football-game/member/ban.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ban-member");
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["block", "x-forms/full-form", [], [], 0, null, ["loc", [null, [3, 2], [33, 24]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});