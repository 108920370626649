define('sportly/locales/en/translations', ['exports'], function (exports) {
    exports['default'] = {
        // "some.translation.key": "Text for some.translation.key",
        //
        // "a": {
        //   "nested": {
        //     "key": "Text for a.nested.key"
        //   }
        // },
        //
        // "key.with.interpolation": "Text with {{anInterpolation}}"
        'fixture-count': {
            'zero': 'No fixtures',
            'one': '1 fixture',
            'other': '{{count}} fixtures'
        },
        'member-count': {
            'zero': 'No members',
            'one': '1 member',
            'other': '{{count}} members'
        },
        'errors': {
            'failed-to-gelocate': "Sorry, we couldn't find that location. You could try a larger place (e.g a town instead of street name)"
        }
    };
});