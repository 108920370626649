define('sportly/routes/football-game/create/add-venue', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        model: function model(params) {
            return this.store.createRecord('venue', {
                name: '',
                description: '',
                latitude: params.latitude,
                longitude: params.longitude
            });
        },

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('football-game').set('pageTitle', 'Add venue');
        },

        actions: {

            save: function save() {

                var _this = this,
                    venue = this.get('currentModel');

                if (_ember['default'].isEmpty(venue.get('name'))) {
                    venue.get('errors').add('name', 'Name is required');
                }
                if (_ember['default'].isEmpty(venue.get('description'))) {
                    venue.get('errors').add('description', 'Description is required');
                }
                if (!venue.get('isValid')) {
                    return;
                }

                venue.save().then(function (venue) {
                    _this.controllerFor('footballGame.create').set('model.venue', venue);
                    _this.transitionTo('footballGame.create.index');
                })['catch'](function () {});
            },

            cancel: function cancel() {
                this.transitionTo('footballGame.create.chooseVenue');
            }

        },

        deactivate: function deactivate() {
            if (this.get('currentModel').get('isNew')) {
                this.get('currentModel').deleteRecord();
            }
        }
    });
});