define('sportly/components/x-notification', ['exports', 'ember', 'sportly/models/notifications/group-invite'], function (exports, _ember, _sportlyModelsNotificationsGroupInvite) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-notification', 'notification.unread:is-unread'],
        notification: null,

        componentType: _ember['default'].computed('notification', function () {
            var notification = this.get('notification');
            if (notification instanceof _sportlyModelsNotificationsGroupInvite['default']) {
                return 'x-notifications/group-invite';
            }
        })

    });
});