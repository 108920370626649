define('sportly/controllers/invite-friends', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        invited: [],
        emailAddressesInput: null,

        multipleEmailAddresses: _ember['default'].computed('emailAddresses', function () {
            return this.get('emailAddresses.length') > 1;
        }),

        emailAddressesEmpty: _ember['default'].computed('emailAddresses', function () {
            return this.get('emailAddresses.length') === 0;
        }),

        emailAddresses: _ember['default'].computed('emailAddressesInput', function () {
            var emailAddresses = [];
            if (_ember['default'].isEmpty(this.get('emailAddressesInput'))) {
                return [];
            }
            emailAddresses = this.get('emailAddressesInput').match(/([\+a-zA-Z0-9'._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
            if (_ember['default'].isEmpty(emailAddresses)) {
                return [];
            }
            return emailAddresses.uniq();
        })

    });
});