define('sportly/controllers/guest/reset-password/change-password', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    session: _ember['default'].inject.service('session'),

    actions: {
      setPassword: function setPassword(passwordChange) {
        var _this = this,
            mixpanel = this.get('mixpanel'),
            resetToken = this.get('model.resetToken'),
            authenticator = 'authenticator:token';

        if (_ember['default'].isEmpty(passwordChange.get('newPassword'))) {
          passwordChange.get('errors').add('newPassword', 'PASSWORD_REQUIRED');
        } else if (passwordChange.get('newPassword.length') < 7) {
          passwordChange.get('errors').add('newPassword', 'PASSWORD_TOO_SHORT');
        }

        if (!passwordChange.get('isValid')) {
          return;
        }

        passwordChange.save().then(function () {
          _this.get('session').authenticate(authenticator, {
            identification: resetToken.get('user.email'),
            password: passwordChange.get('newPassword')
          });
        }).then(function () {
          mixpanel.identify(resetToken.get('email'));
          mixpanel.peopleSet({
            "$last_login": new Date()
          });
          mixpanel.trackEvent('password changed', {
            email: resetToken.get('user.email')
          });
        });
      }
    }
  });
});