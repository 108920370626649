define("sportly/router", ["exports", "ember", "sportly/config/environment", "sportly/mixins/google-pageview"], function (exports, _ember, _sportlyConfigEnvironment, _sportlyMixinsGooglePageview) {

  var Router = _ember["default"].Router.extend(_sportlyMixinsGooglePageview["default"], {
    location: _sportlyConfigEnvironment["default"].locationType
  });

  Router.map(function () {
    this.route("guest", {
      path: "/",
      resetNamespace: true
    }, function () {
      this.route("signIn", {
        path: "sign-in"
      });

      this.route("guest.join", {
        path: "join",
        resetNamespace: true
      }, function () {
        this.route("invitation", {
          path: ":appInvite_id"
        });
      });

      this.route("terms");

      this.route("privacyPolicy", {
        path: "privacy-policy"
      });
      this.route('reset-password', function () {
        this.route('sent-email');
        this.route('change-password', { path: ":guid" });
        this.route('change-password-error');
      });
    });

    this.route("dashboard", {
      resetNamespace: true
    });
    this.route("notifications", {
      resetNamespace: true
    });
    this.route("help", {
      resetNamespace: true
    });

    this.route("welcome", {
      path: "welcome",
      resetNamespace: true
    }, function () {
      this.route("groups");

      this.route("footballGames", {
        path: "football-games"
      });

      this.route("maps");
      this.route("finished");
      this.route("profile");
    });

    this.route("accountSettings", {
      path: "account-settings",
      resetNamespace: true
    }, function () {
      this.route("general");

      this.route("editGeneral", {
        path: "general/edit"
      });

      this.route("emailAddress", {
        path: "email-address"
      });

      this.route("password");
    });

    this.route("inviteFriends", {
      path: "invite-friends",
      resetNamespace: true
    });

    this.route("profile", {
      path: ":profileUrl",
      resetNamespace: true
    }, function () {
      this.route("groups");
      this.route("fixtures");
      this.route("friends");
      this.route("following");
      this.route("followers");
      this.route('follow');
    });

    this.route("profileImage", {
      path: "profile-image",
      resetNamespace: true
    });

    this.route("groups", {
      resetNamespace: true
    }, function () {
      this.route('find');
    });

    this.route("group", {
      path: "group/:groupSegment",
      resetNamespace: true
    }, function () {
      this.route("about");

      this.route("editAdmins", {
        path: "edit-admins"
      });

      this.route("leave");
      this.route("delete");
      this.route("fixtures");
      this.route("settings");
      this.route('join');

      this.route("group.createFootballGame", {
        path: "add-game",
        resetNamespace: true
      }, function () {
        this.route("chooseVenue", {
          path: "choose-venue"
        });

        this.route("addVenue", {
          path: "add-venue"
        });
      });

      this.route("group.edit", {
        path: "edit",
        resetNamespace: true
      }, function () {
        this.route("chooseVenue", {
          path: "choose-venue"
        });

        this.route("addVenue", {
          path: "add-venue"
        });
      });

      this.route("members");
      this.route('invite');
    });

    this.route("group.create", {
      path: "group/create",
      resetNamespace: true
    }, function () {
      this.route("chooseVenue", {
        path: "choose-venue"
      });

      this.route("addVenue", {
        path: "add-venue"
      });
    });

    this.route("footballGame", {
      path: "football/game/:footballGame_id",
      resetNamespace: true
    }, function () {
      this.route("about");
      this.route("cancel");
      this.route("playing");
      this.route("reserves");
      this.route("pending");
      this.route("declined");
      this.route("settings");
      this.route("play");
      this.route("decline");

      this.route("footballGame.edit", {
        path: "edit",
        resetNamespace: true
      }, function () {
        this.route("chooseVenue", {
          path: "choose-venue"
        });

        this.route("addVenue", {
          path: "add-venue"
        });
      });

      this.route("footballGame.member", {
        path: "member/:gameMember_id",
        resetNamespace: true
      }, function () {
        this.route("ban", {
          path: "ban"
        });
      });
    });

    this.route("footballGame.create", {
      path: "football/game/create",
      resetNamespace: true
    }, function () {
      this.route("chooseVenue", {
        path: "choose-venue"
      });

      this.route("addVenue", {
        path: "add-venue"
      });
    });

    this.route("search", {
      resetNamespace: true
    }, function () {
      this.route("searchPeople", {
        path: "people",
        resetNamespace: true
      }, function () {
        this.route("query", {
          path: ":query"
        });
      });
    });

    this.route("loading");

    this.route('games', function () {
      this.route('fixtures');
      this.route('find');
    });
  });

  exports["default"] = Router;
});