define('sportly/components/x-forms/textfield-inset', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-forms--textfield-inset', 'showBigLabel', 'isValid', 'isError', 'isActive', 'canClear'],
        value: null,
        inputId: null,
        labelText: null,
        isValid: true,
        isActive: false,
        hasFocus: false,
        focusOnInsert: false,

        isError: _ember['default'].computed.not('isValid'),
        isNotEmpty: _ember['default'].computed.notEmpty('value'),

        canClear: _ember['default'].computed.and('hasFocus', 'isNotEmpty'),

        showBigLabel: _ember['default'].computed('value', function () {
            return _ember['default'].isEmpty(this.get('value'));
        }),

        focusOut: function focusOut() {
            this.set('isActive', true);
            this.set('hasFocus', false);
        },

        focusIn: function focusIn() {
            this.set('hasFocus', true);
        },

        actions: {
            clear: function clear() {
                this.set('value', null);
            }
        }

    });
});