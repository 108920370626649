define('sportly/models/football-game', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    homeTeam: _emberData['default'].belongsTo('football-team', {
      async: false
    }),
    awayTeam: _emberData['default'].belongsTo('football-team', {
      async: false
    }),
    group: _emberData['default'].belongsTo('group', {
      async: true,
      inverse: 'games'
    }),
    creator: _emberData['default'].belongsTo('user', {
      async: true
    }),
    gameTypeId: _emberData['default'].attr('number'),
    url: _emberData['default'].attr('string'),
    timestamp: _emberData['default'].attr('number'),
    localTime: _emberData['default'].attr('string'),
    localDate: _emberData['default'].attr('string'),
    rsvpCloseTimeDelta: _emberData['default'].attr('number'),
    rsvpOpenTimeDelta: _emberData['default'].attr('number'),
    cancellationMessage: _emberData['default'].attr('string'),
    sportId: _emberData['default'].attr('number'),
    venue: _emberData['default'].belongsTo('venue', {
      async: false
    }),
    gameLength: _emberData['default'].attr('number'),
    playerLimit: _emberData['default'].attr('number'),
    isCancelled: _emberData['default'].attr('number'),
    isFinished: _emberData['default'].attr('number'),
    members: _emberData['default'].hasMany('game-member', {
      async: true,
      inverse: 'footballGame'
    }),
    stories: _emberData['default'].hasMany('story', {
      async: true,
      polymorphic: true
    })
  });
});