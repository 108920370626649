define('sportly/controllers/profile/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        session: _ember['default'].inject.service('session'),

        isSessionProfile: _ember['default'].computed('session.user.profile.id', 'model.id', function () {
            return this.get('session.user.profile.id') === this.get('model.id');
        }),

        followedUsers: _ember['default'].computed.mapBy('model.user.following', 'followed'),

        isFollower: _ember['default'].computed('followedUsers.@each.id', 'session.user.id', function () {
            return this.get('followedUsers').findBy('id', this.get('session.user.id'));
        }),

        sessionCanPost: _ember['default'].computed.or('isSessionProfile', 'isFollower')
    });
});