define('sportly/routes/groups/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsGroupStatus) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        model: function model() {
            var _this = this;

            return this.get('session.user.groupMembers').then(function (groupMembers) {
                return groupMembers.mapBy('user');
            }).then(function () {
                return _ember['default'].RSVP.all(_this.get('session.user.groupMembers').mapBy('group'));
            }).then(function (groups) {
                return groups.filter(function (group) {
                    return group.get('status') !== _sportlyEnumsGroupStatus['default'].DELETED;
                }).sortBy('name');
            });
        }

    });
});