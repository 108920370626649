define('sportly/controllers/football-game', ['exports', 'sportly/controllers/game', 'ember'], function (exports, _sportlyControllersGame, _ember) {
    exports['default'] = _sportlyControllersGame['default'].extend({

        session: _ember['default'].inject.service('session'),

        group: _ember['default'].computed.alias('model.group'),
        groupMemberUsers: _ember['default'].computed.mapBy('group.members', 'user'),
        isHeaderHidden: false,

        isGroupMember: _ember['default'].computed('groupMemberUsers.@each.id', 'session.user.id', function () {
            return this.get('groupMemberUsers').any(function (user) {
                return user.get('id') === this.get('session.user.id');
            }, this);
        }),

        pendingInvites: _ember['default'].computed('isGroupMember', 'session.isAuthenticated', 'session.user.id', 'group.pendingInvites.@each.receiver', 'group.pendingInvites.@each.status', function () {
            if (this.get('isGroupMember') || !this.get('session.isAuthenticated')) {
                return [];
            }
            return this.get('group.pendingInvites').filter(function (invite) {
                return invite.get('status') === 1 && invite.get('receiver.id') === this.get('session.user.id');
            }, this);
        })
    });
});