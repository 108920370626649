define('sportly/components/x-football-games/header', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        session: _ember['default'].inject.service('session'),

        classNameBindings: [':x-football-games--header', 'footballGame.isCancelled:is-cancelled'],
        footballGame: null,
        group: _ember['default'].computed.alias('footballGame.group'),

        canJoin: _ember['default'].computed.and('isActive', 'sessionIsNotMember'),
        showJoin: _ember['default'].computed.and('canJoin', 'hasNoPendingInvites'),

        groupMemberUsers: _ember['default'].computed.mapBy('group.members', 'user'),

        hasPendingInvites: _ember['default'].computed.notEmpty('pendingInvites'),
        hasNoPendingInvites: _ember['default'].computed.not('hasPendingInvites'),

        notCancelled: _ember['default'].computed.not('footballGame.isCancelled'),
        notFinished: _ember['default'].computed.not('footballGame.isFinished'),
        isActive: _ember['default'].computed.and('notCancelled', 'notFinished'),
        sessionIsNotMember: _ember['default'].computed.not('sessionIsMember'),

        hasLimitedSpaces: _ember['default'].computed.gte('footballGame.playerLimit', 1),

        sessionIsMember: _ember['default'].computed('footballGame.members.@each.user', 'session.user.id', function () {
            var userId = this.get('session.user.id');
            return this.get('footballGame.members').any(function (member) {
                return member.get('user.id') === userId;
            });
        }),

        isGroupMember: _ember['default'].computed('groupMemberUsers.@each.id', 'session.user.id', function () {
            return this.get('groupMemberUsers').any(function (user) {
                return user.get('id') === this.get('session.user.id');
            }, this);
        }),

        pendingInvites: _ember['default'].computed('isGroupMember', 'session.isAuthenticated', 'session.user.id', 'group.pendingInvites.@each.receiver', 'group.pendingInvites.@each.status', function () {
            if (this.get('isGroupMember') || !this.get('session.isAuthenticated')) {
                return [];
            }
            return this.get('group.pendingInvites').filter(function (invite) {
                return invite.get('status') === 1 && invite.get('receiver.id') === this.get('session.user.id');
            }, this);
        }),

        actions: {

            attend: function attend() {
                this.sendAction('attend');
            },

            decline: function decline() {
                this.sendAction('decline');
            },

            changeRSVP: function changeRSVP() {
                this.sendAction('changeRSVP');
            },

            join: function join(group) {
                this.sendAction('join', group);
            }
        }
    });
});