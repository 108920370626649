define('sportly/controllers/group/edit-admins', ['exports', 'ember', 'sportly/enums/group/member-role'], function (exports, _ember, _sportlyEnumsGroupMemberRole) {
  exports['default'] = _ember['default'].Controller.extend({

    admins: _ember['default'].computed.filterBy('model', 'roleId', _sportlyEnumsGroupMemberRole['default'].ADMIN),
    nonAdmins: _ember['default'].computed.filterBy('model', 'roleId', _sportlyEnumsGroupMemberRole['default'].MEMBER),

    hasOneAdmin: _ember['default'].computed.equal('admins.length', 1),

    dirtyMembers: _ember['default'].computed.filterBy('content', 'hasDirtyAttributes', true),
    isSaveDisabled: _ember['default'].computed.empty('dirtyMembers'),
    isSaving: false,

    actions: {
      toggleAdmin: function toggleAdmin(member) {
        if (member.get('roleId') === _sportlyEnumsGroupMemberRole['default'].ADMIN) {
          if (member.get('group.members').filterBy('roleId', _sportlyEnumsGroupMemberRole['default'].ADMIN).length > 1) {
            member.set('roleId', _sportlyEnumsGroupMemberRole['default'].MEMBER);
          }
        } else {
          member.set('roleId', _sportlyEnumsGroupMemberRole['default'].ADMIN);
        }
      },

      save: function save() {
        var controller = this;
        this.set('isSaving', true);
        _ember['default'].RSVP.all(this.get('dirtyMembers').invoke('save')).then(function () {
          controller.set('isSaving', false);
          controller.transitionToRoute('group.index');
        });
      },

      cancel: function cancel() {
        this.transitionToRoute('group.settings');
      }

    }
  });
});