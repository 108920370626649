define("sportly/components/x-dropdown-menu", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    classNameBindings: [":x-dropdown-menu", "isOpen"],

    init: function init() {
      this._super.apply(this, arguments);
      this.isOpen = false;
    },

    click: function click(e) {
      var _this = this;
      e.stopPropagation();
      _ember["default"].$(document).one('click', function () {
        if (_this.get('isDestroyed') === false) {
          _this.set('isOpen', false);
        }
      });
      this.set('isOpen', true);
    }
  });
});