define('sportly/components/x-global/side-menu', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':x-global--side-menu', 'isShowing'],

    unreadNotificationsCount: _ember['default'].computed.alias('unreadNotifications.length'),
    hasUnreadNotifications: _ember['default'].computed.gt('unreadNotifications.length', 0),
    unreadNotifications: _ember['default'].computed.filterBy('user.notifications', 'unread', 1),

    click: function click() {
      this.attrs.toggleSideMenu();
    }

  });
});