define('sportly/controllers/guest/sign-in', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].Controller.extend({

    session: _ember['default'].inject.service(),
    errors: new _emberData['default'].Errors(),

    actions: {
      authenticate: function authenticate() {

        var errors = this.get('errors'),
            mixpanel = this.get('mixpanel'),
            credentials = this.getProperties('identification', 'password'),
            authenticator = 'authenticator:token';

        mixpanel.trackEvent("sign-in: submitted", {
          'email': credentials.identification
        });

        errors.clear();
        if (_ember['default'].isEmpty(credentials.identification) || credentials.identification.length < 5) {
          errors.add('email', "Email is required");
        }
        if (_ember['default'].isEmpty(credentials.password)) {
          errors.add('password', "Password is required");
        }

        if (!errors.get('isEmpty')) {
          mixpanel.trackEvent("sign-in: error", {
            'email': errors.has('email') ? errors.errorsFor('email')[0].message : '',
            'password': errors.has('password') ? errors.errorsFor('password')[0].message : ''
          });
          return new _ember['default'].RSVP.Promise(function (resolve) {
            return resolve('ERROR');
          });
        }

        return this.get('session').authenticate(authenticator, credentials).then(function () {
          mixpanel.identify(credentials.identification);
          mixpanel.peopleSet({
            "$last_login": new Date()
          });
        })['catch'](function (e) {
          e.errors.forEach(function (error) {
            var key = error.source.pointer.substring(16);
            errors.add(key, error.detail);
          });
          mixpanel.trackEvent("sign-in: error", {
            'email': errors.has('email') ? errors.errorsFor('email')[0].message : '',
            'password': errors.has('password') ? errors.errorsFor('password')[0].message : ''
          });
          return 'ERROR';
        });
      }
    }
  });
});