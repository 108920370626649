define('sportly/controllers/group/leave', ['exports', 'ember', 'sportly/controllers/group'], function (exports, _ember, _sportlyControllersGroup) {
  exports['default'] = _sportlyControllersGroup['default'].extend({

    session: _ember['default'].inject.service('session'),

    isLastMember: _ember['default'].computed('members.length', function () {
      return this.get('members.length') === 1;
    }),

    isLastAdmin: _ember['default'].computed('isAdmin', 'admins.length', function () {
      return this.get('isAdmin') && this.get('admins.length') === 1;
    }),

    actions: {
      leave: function leave() {

        var user = this.get('session.user'),
            now = moment().format('X'),
            group = this.get('model'),
            records = [],
            _this = this;

        group.get('games').filter(function (game) {
          return game.get('timestamp') > now;
        }).forEach(function (game) {
          game.get('members').filterBy('user.content.id', user.get('id')).forEach(function (member) {
            member.deleteRecord();
            records.pushObject(member);
          });
        });
        group.get('members').filterBy('user.content.id', user.get('id')).forEach(function (member) {
          member.deleteRecord();
          records.pushObject(member);
        });

        _ember['default'].RSVP.all(records.invoke('save')).then(function () {
          return _this.store.createRecord('story/group-leave', {
            user: user,
            group: group
          }).save();
        }).then(function (story) {
          group.get('stories').pushObject(story);
          _this.transitionToRoute('group.index');
        });
      },

      cancel: function cancel() {
        this.transitionToRoute('group.settings');
      }
    }
  });
});