define('sportly/routes/games/fixtures', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        beforeModel: function beforeModel(transition) {
            var _this = this;
            this._super(transition);
            return this.get('session.user.gameMembers').then(function (gameMembers) {
                return gameMembers.mapBy('user');
            }).then(function () {
                return _ember['default'].RSVP.all(_this.get('session.user.gameMembers').mapBy('footballGame'));
            });
        },

        model: function model() {
            var now = moment().format('X');

            return this.get('session.user.gameMembers').mapBy('footballGame').filter(function (footballGame) {
                return footballGame.get('timestamp') > now;
            });
        }

    });
});