define('sportly/routes/games/find', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        queryParams: {
            latitude: {
                replace: true
            },
            longitude: {
                replace: true
            },
            zoom: {
                replace: true
            },
            venueId: {
                replace: true
            },
            query: {
                replace: true
            }
        }

    });
});