define('sportly/components/x-guest/reset-password-form', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-guest--reset-password-form'],

    isInvalidEmailError: _ember['default'].computed('resetToken.errors.email.firstObject', function () {
      return this.get('resetToken.errors.email.firstObject.message') === 'INVALID_EMAIL';
    }),

    isNoAccountError: _ember['default'].computed('resetToken.errors.email.firstObject', function () {
      return this.get('resetToken.errors.email.firstObject.message') === 'NO_ACCOUNT';
    })
  });
});