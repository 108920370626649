define('sportly/controllers/guest/reset-password/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      resetPassword: function resetPassword(resetToken) {
        var _this = this,
            mixpanel = this.get('mixpanel');

        if (_ember['default'].isEmpty(resetToken.get('email')) || resetToken.get('email.length') < 5) {
          resetToken.get('errors').add('email', 'Email is required');
        }

        if (!resetToken.get('isValid')) {
          return;
        }

        resetToken.save().then(function () {
          mixpanel.trackEvent('Password reset', {
            email: resetToken.get('email')
          });
        }).then(function () {
          _this.transitionToRoute('guest.reset-password.sent-email');
        });
      }
    }
  });
});