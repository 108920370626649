define('sportly/controllers/football-game/create', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
    exports['default'] = _ember['default'].Controller.extend({
        errors: new _emberData['default'].Errors(),
        homeTeamName: null,
        awayTeamName: null,
        venue: null,
        group: null,
        gameTypeId: 0,
        gameLength: 0,
        playerLimit: 0,
        localTime: null,
        frequency: null,
        startDate: null,
        finishDate: null
    });
});