define('sportly/routes/football-game/edit/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('football-game').set('pageTitle', 'Edit game');
        },

        actions: {

            chooseVenue: function chooseVenue() {
                if (!_ember['default'].isEmpty(this.get('currentModel.venue'))) {
                    return this.transitionTo('footballGame.edit.chooseVenue', {
                        queryParams: {
                            latitude: this.get('currentModel.venue.latitude'),
                            longitude: this.get('currentModel.venue.longitude')
                        }
                    });
                }
                this.transitionTo('footballGame.edit.chooseVenue');
            },

            chooseDate: function chooseDate() {
                this.transitionTo('footballGame.edit.chooseDate');
            }

        }
    });
});