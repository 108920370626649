define("sportly/templates/football-game/member/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 3
            },
            "end": {
              "line": 21,
              "column": 3
            }
          },
          "moduleName": "sportly/templates/football-game/member/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("				");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "flaticon stroke right-2");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n				");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("'s profile\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["content", "model.user.profile.firstName", ["loc", [null, [20, 4], [20, 36]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 4
              },
              "end": {
                "line": 27,
                "column": 4
              }
            },
            "moduleName": "sportly/templates/football-game/member/index.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("					");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "flaticon stroke right-2");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n					Ban\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 3
            },
            "end": {
              "line": 28,
              "column": 3
            }
          },
          "moduleName": "sportly/templates/football-game/member/index.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "link-to", ["footballGame.member.ban"], ["class", "ban"], 0, null, ["loc", [null, [24, 4], [27, 16]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 0
          }
        },
        "moduleName": "sportly/templates/football-game/member/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "member");
        var el2 = dom.createTextNode("\n\n	");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("article");
        var el3 = dom.createTextNode("\n\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n		");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("nav");
        var el4 = dom.createTextNode("\n\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n		");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(element0, [3]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createMorphAt(element1, 3, 3);
        return morphs;
      },
      statements: [["inline", "x-football-games/member-header", [], ["profile", ["subexpr", "@mut", [["get", "profile", ["loc", [null, [6, 11], [6, 18]]]]], [], []], "isPlaying", ["subexpr", "@mut", [["get", "isPlaying", ["loc", [null, [7, 13], [7, 22]]]]], [], []], "isReserve", ["subexpr", "@mut", [["get", "isReserve", ["loc", [null, [8, 13], [8, 22]]]]], [], []], "hasDeclined", ["subexpr", "@mut", [["get", "hasDeclined", ["loc", [null, [9, 15], [9, 26]]]]], [], []], "isBanned", ["subexpr", "@mut", [["get", "isBanned", ["loc", [null, [10, 12], [10, 20]]]]], [], []], "isPending", ["subexpr", "@mut", [["get", "isPending", ["loc", [null, [11, 13], [11, 22]]]]], [], []], "iCanUnban", ["subexpr", "@mut", [["get", "iCanUnban", ["loc", [null, [12, 13], [12, 22]]]]], [], []], "unban", ["subexpr", "action", ["unban", ["get", "model", ["loc", [null, [13, 25], [13, 30]]]]], [], ["loc", [null, [13, 9], [13, 31]]]]], ["loc", [null, [5, 2], [14, 4]]]], ["block", "link-to", ["profile.index", ["get", "model.user.profile", ["loc", [null, [18, 30], [18, 48]]]]], ["class", "profile"], 0, null, ["loc", [null, [18, 3], [21, 15]]]], ["block", "if", [["get", "iCanBan", ["loc", [null, [23, 9], [23, 16]]]]], [], 1, null, ["loc", [null, [23, 3], [28, 10]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});