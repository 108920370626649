define('sportly/controllers/group/member', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        ROLE: {
            ADMIN: 1,
            MEMBER: 2
        },

        isAdmin: _ember['default'].computed('model.roleId', function () {
            return this.get('model.roleId') === this.ROLE.ADMIN;
        }),

        actions: {
            toggleAdmin: function toggleAdmin() {

                if (this.get('model.roleId') === this.ROLE.ADMIN) {
                    if (this.get('model.group.members').filterBy('roleId', this.ROLE.ADMIN).length > 1) {
                        this.set('model.roleId', this.ROLE.MEMBER);
                    }
                } else {
                    this.set('model.roleId', this.ROLE.ADMIN);
                }
            }
        }
    });
});