define('sportly/controllers/group', ['exports', 'ember', 'sportly/enums/group/member-role', 'sportly/enums/group/status'], function (exports, _ember, _sportlyEnumsGroupMemberRole, _sportlyEnumsGroupStatus) {
  exports['default'] = _ember['default'].Controller.extend({

    session: _ember['default'].inject.service('session'),

    admins: _ember['default'].computed.filterBy('model.members', 'roleId', _sportlyEnumsGroupMemberRole['default'].ADMIN),
    memberUsers: _ember['default'].computed.mapBy('model.members', 'user'),
    adminUsers: _ember['default'].computed.mapBy('admins', 'user'),

    isAdmin: _ember['default'].computed('adminUsers.@each.id', 'session.user.id', function () {
      return this.get('adminUsers').any(function (user) {
        return user.get('id') === this.get('session.user.id');
      }, this);
    }),

    sessionMember: _ember['default'].computed('memberUsers.@each.id', 'session.user.id', function () {
      var userId = this.get('session.user.id');
      return this.get('model.members').find(function (member) {
        return member.get('user.id') === userId;
      });
    }),

    isMember: _ember['default'].computed('memberUsers.@each.id', 'session.user.id', function () {
      var userId = this.get('session.user.id');
      return this.get('memberUsers').any(function (user) {
        return user.get('id') === userId;
      }, this);
    }),
    isNotMember: _ember['default'].computed.not('isMember'),

    showSetupMode: _ember['default'].computed.and('isNoob', 'isAdmin'),

    isActive: _ember['default'].computed.equal('model.status', _sportlyEnumsGroupStatus['default'].ACTIVE),
    isNotActive: _ember['default'].computed.not('isActive'),
    isDeleted: _ember['default'].computed.equal('model.status', _sportlyEnumsGroupStatus['default'].DELETED),
    isNotDeleted: _ember['default'].computed.not('isDeleted'),
    isNoob: _ember['default'].computed.equal('model.status', _sportlyEnumsGroupStatus['default'].NOOB),

    canCreateGame: _ember['default'].computed.and('isAdmin', 'isActive'),
    canInvite: _ember['default'].computed.and('isMember', 'isNotDeleted'),
    canJoin: _ember['default'].computed.and('isNotMember', 'isActive'),
    canPost: _ember['default'].computed.and('isMember', 'isNotDeleted'),

    myPendingInvites: _ember['default'].computed('isMember', 'session.isAuthenticated', 'session.user.id', 'model.pendingInvites.@each.receiver', 'model.pendingInvites.@each.status', function () {
      if (this.get('isMember') || !this.get('session.isAuthenticated')) {
        return [];
      }
      return this.get('model.pendingInvites').filter(function (invite) {
        return invite.get('status') === 1 && invite.get('receiver.id') === this.get('session.user.id');
      }, this);
    }),

    canShowInvites: _ember['default'].computed.and('isNotMember', 'isActive', 'hasInvites'),
    hasInvites: _ember['default'].computed.bool('myPendingInvites.length'),
    hasMultipleInvites: _ember['default'].computed.gt('myPendingInvites.length', 1),

    inviteCountMinusOne: _ember['default'].computed('myPendingInvites.length', function () {
      return this.get('myPendingInvites.length') - 1;
    }),

    hasFixtures: _ember['default'].computed.bool('fixtures.length'),

    fixtures: _ember['default'].computed('model.games.[]', 'model.games.@each.isCancelled', 'model.games.@each.timestamp', function () {
      var now = moment().format('X');
      return this.get('model.games').filter(function (fixture) {
        if (_ember['default'].isEmpty(fixture) || fixture.get('isCancelled')) {
          return false;
        }
        return fixture.get('timestamp') > now;
      }).sort(function (a, b) {
        return a.get('timestamp') - b.get('timestamp');
      });
    }),

    nextFewFixtures: _ember['default'].computed('fixtures.[]', function () {
      return this.get('fixtures').slice(0, 3);
    }),

    nextGame: _ember['default'].computed('model.games.@each.timestamp', function () {
      var now = moment().format('X');
      return this.get('model.games').filter(function (game) {
        return game.get('timestamp') > now && !game.get('isCancelled');
      }).sort(function (a, b) {
        return a.get('timestamp') - b.get('timestamp');
      }).get('firstObject');
    }),

    actions: {
      selectMembers: function selectMembers() {
        this.transitionToRoute('group.members', this.get('model'));
      },
      inviteMembers: function inviteMembers() {
        this.transitionToRoute('group.invite', this.get('model'));
      },
      join: function join() {
        var group = this.get('model');
        this.store.createRecord('group-member', {
          user: this.get('session.user'),
          group: group,
          roleId: _sportlyEnumsGroupMemberRole['default'].MEMBER
        }).save().then(function (groupMember) {
          group.get('members').pushObject(groupMember);
        });
      }
    },

    //Deprecated
    isHeaderHidden: false

  });
});