define('sportly/controllers/group/create-football-game/choose-venue', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['latitude', 'longitude', 'zoom', 'query'],

        latitude: 0,
        longitude: 0,
        zoom: 13,
        venueId: null,
        query: '',

        latitudeStart: 0,
        latitudeFinish: 0,
        longitudeStart: 0,
        longitudeFinish: 0,

        searchQuery: '',
        isLocatingUser: false,

        venues: [],

        fetchVenues: function fetchVenues() {
            var _this = this;
            this.store.query('venue', {
                where: {
                    latitude: {
                        '>': this.get('latitudeStart'),
                        '<': this.get('latitudeFinish')
                    },
                    longitude: {
                        '>': this.get('longitudeStart'),
                        '<': this.get('longitudeFinish')
                    }
                }
            }).then(function (venues) {
                _this.set('venues', venues);
            });
        },

        actions: {
            onMapMove: function onMapMove(props) {
                this.setProperties({
                    'latitude': props.latitude,
                    'longitude': props.longitude,
                    'zoom': props.zoom,
                    'latitudeStart': props.latitudeStart,
                    'latitudeFinish': props.latitudeFinish,
                    'longitudeStart': props.longitudeStart,
                    'longitudeFinish': props.longitudeFinish
                });

                this.fetchVenues();
            },

            onSearch: function onSearch(query) {
                this.set('searchQuery', query);
            },

            onLocateUser: function onLocateUser() {
                this.set('isLocatingUser', true);
            }
        }
    });
});