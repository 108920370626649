define('sportly/components/x-football-games/is-invited', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-football-games--is-invited'],
        invites: [],

        group: _ember['default'].computed.alias('invites.lastObject.group'),
        primarySender: _ember['default'].computed.alias('invites.lastObject.sender'),
        hasMultipleInvites: _ember['default'].computed.gt('invites.length', 1),

        othersCount: _ember['default'].computed('invites.length', function () {
            return this.get('invites.length') - 1;
        }),

        actions: {
            join: function join() {
                this.sendAction('join', this.get('group'));
            }
        }

    });
});