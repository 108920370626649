define('sportly/components/x-football-games/fixtures', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-football-games--fixtures', 'hasFixtures'],
        fixtures: null,
        sortProperties: ['timestamp:asc'],
        sortedFixtures: _ember['default'].computed.sort('fixtures', 'sortProperties'),

        hasFixtures: _ember['default'].computed.notEmpty('fixtures')

    });
});