define('sportly/routes/group/delete', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsGroupStatus) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        afterModel: function afterModel(group) {
            if (group.get('status') !== _sportlyEnumsGroupStatus['default'].ACTIVE) {
                return this.transitionTo('group.index');
            }

            return group.get('games').then(function (games) {
                return games.mapBy('members');
            });
        },

        setupController: function setupController(controller, model) {
            controller.set('model', model);
            if (!this.controllerFor('group').get('isAdmin')) {
                this.transitionTo('group.index', this.modelFor('group'));
            }
        }

    });
});