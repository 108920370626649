define('sportly/controllers/profile/groups', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    profileController: _ember['default'].inject.controller('profile'),
    profile: _ember['default'].computed.alias('profileController.model'),

    sortProperties: ['name'],
    sortAscending: true,

    hasGroups: _ember['default'].computed.notEmpty('content')

  });
});