define('sportly/components/x-football-games/rsvp-is-open', ['exports', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, _ember, _sportlyEnumsFootballGameRsvp) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: [':x-football-games--rsvp-is-open'],
    member: null,
    rsvpCloseTime: null,
    rsvpCloseTimeDelta: 0,

    isPending: _ember['default'].computed.equal('member.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING),
    isPlaying: _ember['default'].computed.equal('member.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PLAYING),
    hasDeclined: _ember['default'].computed.equal('member.rsvpId', _sportlyEnumsFootballGameRsvp['default'].DECLINED),
    isReserve: _ember['default'].computed.equal('member.rsvpId', _sportlyEnumsFootballGameRsvp['default'].RESERVE),
    isBanned: _ember['default'].computed.equal('member.rsvpId', _sportlyEnumsFootballGameRsvp['default'].BANNED),

    time: _ember['default'].computed(function () {
      return moment();
    }),

    showRSVPCloseTimeMessage: _ember['default'].computed('rsvpCloseTimeDelta', 'rsvpClosesToday', function () {
      if (this.get('rsvpCloseTimeDelta') === 0) {
        return false;
      }
      return this.get('rsvpClosesToday');
    }),

    rsvpClosesToday: _ember['default'].computed('rsvpCloseTime', function () {
      return this.get('time').isSame(moment(this.get('rsvpCloseTime'), 'X'), 'day');
    }),

    actions: {
      attend: function attend() {
        this.sendAction('attend');
      },

      decline: function decline() {
        this.sendAction('decline');
      },

      changeRSVP: function changeRSVP() {
        this.sendAction('changeRSVP');
      }
    }
  });
});