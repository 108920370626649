define('sportly/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        profile: _emberData['default'].belongsTo('profile', { async: true }),
        password: _emberData['default'].attr('string'),
        newPassword: _emberData['default'].attr('string'),
        email: _emberData['default'].attr('string'),
        appInvite: _emberData['default'].belongsTo('app-invite', {
            inverse: 'receiver',
            async: false
        }),
        notifications: _emberData['default'].hasMany('notification', { async: true, polymorphic: true, inverse: 'user' }),
        followers: _emberData['default'].hasMany('follower', { async: true, inverse: 'followed' }),
        following: _emberData['default'].hasMany('follower', { async: true, inverse: 'user' }),
        groupMembers: _emberData['default'].hasMany('group-member', { async: true, inverse: 'user' }),
        gameMembers: _emberData['default'].hasMany('game-member', { async: true, inverse: 'user' }),
        groupInvites: _emberData['default'].hasMany('group-invite', { async: true, inverse: 'receiver' }),
        stories: _emberData['default'].hasMany('story', { async: true, polymorphic: true })
    });
});