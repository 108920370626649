define('sportly/components/x-posts/composer', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-posts--composer', 'isActive'],
        isActive: false,
        body: null,

        isPostDisabled: _ember['default'].computed.empty('body'),

        actions: {
            post: function post() {
                this.sendAction('didPost', this.get('body'));
                this.set('body', null);
                this.set('isActive', false);
            },

            cancel: function cancel() {
                this.set('body', null);
                this.set('isActive', false);
            }
        },

        keyUp: function keyUp(e) {
            if (e.keyCode === 27) {
                this.$('.body-input').blur();
            }
        },

        click: function click(e) {
            if (this.$(e.target).is('.body-input')) {
                this.set('isActive', true);
            }
        },

        didInsertElement: function didInsertElement() {

            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                this.$('.body-input').autosize();
            });
        },

        activeChanged: _ember['default'].observer('isActive', function () {
            _ember['default'].run.scheduleOnce('afterRender', this, function () {
                this.$('.body-input').trigger('autosize.resize');
            });
        })

    });
});