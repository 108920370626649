define('sportly/controllers/group/about', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    group: _ember['default'].inject.controller(),

    canInviteFriends: _ember['default'].computed.alias('group.isActive'),
    canShowInvitations: _ember['default'].computed.alias('group.canShowInvites'),
    hasNoInvitations: _ember['default'].computed.not('canShowInvitations'),
    showJoinInHeader: _ember['default'].computed.and('hasNoInvitations', 'group.canJoin'),
    myPendingInvites: _ember['default'].computed.alias('group.myPendingInvites')

  });
});