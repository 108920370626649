define('sportly/services/mixpanel', ['exports', 'ember', 'sportly/config/environment'], function (exports, _ember, _sportlyConfigEnvironment) {
    exports['default'] = _ember['default'].Service.extend({
        pageHasAnalytics: function pageHasAnalytics() {
            return window.mixpanel && typeof window.mixpanel === "object" && _sportlyConfigEnvironment['default'].mixpanel.enabled;
        },

        logTrackingEnabled: function logTrackingEnabled() {
            return !!_sportlyConfigEnvironment['default'] && !!_sportlyConfigEnvironment['default'].mixpanel.LOG_EVENT_TRACKING;
        },

        logTracking: function logTracking() {
            _ember['default'].Logger.info('[Mixpanel] ', arguments);
        },

        trackPageView: function trackPageView(page) {
            if (this.pageHasAnalytics()) {
                if (!page) {
                    var loc = window.location;
                    page = loc.hash ? loc.hash.substring(1) : loc.pathname + loc.search;
                }

                window.mixpanel.track("visit", { pageName: page });
            }

            if (this.logTrackingEnabled()) {
                this.logTracking('page view', page);
            }
        },

        trackEvent: function trackEvent(event, properties, options, callback) {
            if (this.pageHasAnalytics()) {
                window.mixpanel.track(event, properties, options, callback);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking(event, properties, options);
            }
        },

        identify: function identify(userId, traits, options, callback) {
            if (this.pageHasAnalytics()) {
                window.mixpanel.identify(userId, traits, options, callback);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking('identify user', userId, traits, options);
            }
        },

        alias: function alias(userId, previousId, options, callback) {
            if (this.pageHasAnalytics()) {
                window.mixpanel.alias(userId, previousId, options, callback);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking('alias user', userId, previousId, options);
            }
        },

        register: function register(traits, options, callback) {
            if (this.pageHasAnalytics()) {
                window.mixpanel.register(traits, options, callback);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking('register user', traits, options);
            }
        },

        peopleSet: function peopleSet(attributes) {

            if (this.pageHasAnalytics()) {
                window.mixpanel.people.set(attributes);
            }

            if (this.logTrackingEnabled()) {
                this.logTracking('people.set', attributes);
            }
        }
    });
});