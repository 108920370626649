define('sportly/components/x-stories/story', ['exports', 'ember', 'sportly/models/story/status-update', 'sportly/models/story/app-join', 'sportly/models/story/football-game-cancel', 'sportly/models/story/football-game-post', 'sportly/models/story/group-create', 'sportly/models/story/group-join', 'sportly/models/story/group-leave', 'sportly/models/story/group-post', 'sportly/models/story/user-follow', 'sportly/models/story/profile-image-change', 'sportly/models/story/profile-post'], function (exports, _ember, _sportlyModelsStoryStatusUpdate, _sportlyModelsStoryAppJoin, _sportlyModelsStoryFootballGameCancel, _sportlyModelsStoryFootballGamePost, _sportlyModelsStoryGroupCreate, _sportlyModelsStoryGroupJoin, _sportlyModelsStoryGroupLeave, _sportlyModelsStoryGroupPost, _sportlyModelsStoryUserFollow, _sportlyModelsStoryProfileImageChange, _sportlyModelsStoryProfilePost) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-stories--story'],
        story: null,
        source: null,

        componentType: _ember['default'].computed('story', function () {
            var story = this.get('story');
            if (story instanceof _sportlyModelsStoryStatusUpdate['default']) {
                return 'x-stories/status-update-story';
            }
            if (story instanceof _sportlyModelsStoryAppJoin['default']) {
                return 'x-stories/app-join-story';
            }
            if (story instanceof _sportlyModelsStoryFootballGameCancel['default']) {
                return 'x-stories/football-game-cancel-story';
            }
            if (story instanceof _sportlyModelsStoryFootballGamePost['default']) {
                return 'x-stories/football-game-post-story';
            }
            if (story instanceof _sportlyModelsStoryGroupCreate['default']) {
                return 'x-stories/group-create-story';
            }
            if (story instanceof _sportlyModelsStoryGroupJoin['default']) {
                return 'x-stories/group-join-story';
            }
            if (story instanceof _sportlyModelsStoryGroupLeave['default']) {
                return 'x-stories/group-leave-story';
            }
            if (story instanceof _sportlyModelsStoryGroupPost['default']) {
                return 'x-stories/group-post-story';
            }
            if (story instanceof _sportlyModelsStoryUserFollow['default']) {
                return 'x-stories/user-follow-story';
            }
            if (story instanceof _sportlyModelsStoryProfileImageChange['default']) {
                return 'x-stories/profile-image-change-story';
            }
            if (story instanceof _sportlyModelsStoryProfilePost['default']) {
                return 'x-stories/profile-post-story';
            }
        })

    });
});