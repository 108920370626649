define('sportly/controllers/group/fixtures', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        group: _ember['default'].inject.controller('group'),

        hasFixtures: _ember['default'].computed.notEmpty('model'),

        isPlural: _ember['default'].computed.gt('model.length', 1),

        showCreateGame: _ember['default'].computed.and('group.isAdmin', 'group.isActive'),

        canShowInvitations: _ember['default'].computed.alias('group.canShowInvites'),
        hasNoInvitations: _ember['default'].computed.not('canShowInvitations'),
        showJoinInHeader: _ember['default'].computed.and('hasNoInvitations', 'group.canJoin'),
        myPendingInvites: _ember['default'].computed.alias('group.myPendingInvites')

    });
});