define('sportly/components/x-groups/header', ['exports', 'ember', 'sportly/enums/group/member-role', 'sportly/enums/group/status'], function (exports, _ember, _sportlyEnumsGroupMemberRole, _sportlyEnumsGroupStatus) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-groups--header', 'isDeleted', 'inSetupMode'],
        session: _ember['default'].inject.service('session'),
        group: null,

        isNotMember: _ember['default'].computed.not('isMember'),
        isNoob: _ember['default'].computed.equal('group.status', _sportlyEnumsGroupStatus['default'].NOOB),
        isActive: _ember['default'].computed.equal('group.status', _sportlyEnumsGroupStatus['default'].ACTIVE),
        isDeleted: _ember['default'].computed.equal('group.status', _sportlyEnumsGroupStatus['default'].DELETED),
        showJoin: _ember['default'].computed.and('isNotMember', 'isActive'),
        showSettings: _ember['default'].computed.and('isMember', 'isActive'),

        inSetupMode: _ember['default'].computed.and('isNoob', 'isAdmin'),

        isAdmin: _ember['default'].computed('group.members.@each.user', 'group.members.@each.roleId', 'session.user.id', function () {
            return this.get('group.members').filterBy('roleId', _sportlyEnumsGroupMemberRole['default'].ADMIN).any(function (member) {
                return member.get('user.id') === this.get('session.user.id');
            }, this);
        }),

        isMember: _ember['default'].computed('group.members.@each.user', 'session.user.id', function () {
            return this.get('group.members').any(function (member) {
                return member.get('user.id') === this.get('session.user.id');
            }, this);
        }),

        actions: {
            join: function join() {
                this.sendAction('join');
            }
        }

    });
});