define('sportly/controllers/football-game/member', ['exports', 'ember', 'sportly/enums/football-game/rsvp'], function (exports, _ember, _sportlyEnumsFootballGameRsvp) {
    exports['default'] = _ember['default'].Controller.extend({

        session: _ember['default'].inject.service('session'),

        footballGame: _ember['default'].inject.controller(),

        isMe: _ember['default'].computed('model.profile.user.id', 'session.user.id', function () {
            return this.get('model.profile.user.id') === this.get('session.user.id');
        }),
        isNotMe: _ember['default'].computed.not('isMe'),
        isAdmin: _ember['default'].computed.equal('model.roleId', _sportlyEnumsFootballGameRsvp['default'].ADMIN),

        canRSVP: _ember['default'].computed.and('footballGame.isActive', 'isNotBanned'),
        isPlaying: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PLAYING),
        isReserve: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].RESERVE),
        isPending: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].PENDING),
        hasDeclined: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].DECLINED),
        isBanned: _ember['default'].computed.equal('model.rsvpId', _sportlyEnumsFootballGameRsvp['default'].BANNED),
        isNotBanned: _ember['default'].computed.not('isBanned'),
        isAttending: _ember['default'].computed.or('isPlaying', 'isReserve'),
        isNotAttending: _ember['default'].computed.or('isPending', 'hasDeclined'),

        iCanUnBan: _ember['default'].computed.and('footballGame.iAmAdmin', 'isBanned'),
        iCanBan: _ember['default'].computed.and('footballGame.iAmAdmin', 'isNotMe', 'isNotBanned')

    });
});