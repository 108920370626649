define('sportly/routes/football-game/reserves', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        model: function model() {
            return this.modelFor('footballGame').get('members');
        },

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('football-game').set('pageTitle', 'Reserves');
        }

    });
});