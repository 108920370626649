define('sportly/components/x-group/invite-friends', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-group--invite-friends'],

    group: _ember['default'].computed.alias('member.group'),
    user: _ember['default'].computed.alias('member.user'),
    followerUsers: _ember['default'].computed.mapBy('user.followers', 'user'),
    followingUsers: _ember['default'].computed.mapBy('user.following', 'followed'),
    groupMembers: _ember['default'].computed.alias('group.members'),
    pendingInvites: _ember['default'].computed.alias('group.pendingInvites'),

    inviteables: _ember['default'].computed('friends.[]', 'groupMembers.[]', 'pendingInvites.[]', 'attrs.query', function () {
      var groupMembers = this.get('groupMembers'),
          pendingInvites = this.get('pendingInvites');
      return this.get('friends').filter(function (friend) {
        if (groupMembers.findBy('user.id', friend.get('id'))) {
          return false;
        }
        if (pendingInvites.findBy('receiver.id', friend.get('id'))) {
          return false;
        }
        return true;
      });
    }),

    friends: _ember['default'].computed('followerUsers.[]', 'followingUsers.[]', function () {
      var followers = this.get('followerUsers'),
          following = this.get('followingUsers');
      return following.filter(function (followingUser) {
        return followers.findBy('id', followingUser.get('id'));
      });
    }),

    hasFriends: _ember['default'].computed.notEmpty('friends')

  });
});