define('sportly/routes/account-settings/general', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        model: function model() {
            return this.get('session.user.profile');
        },

        setupController: function setupController() {
            this._super.apply(this, arguments);
            this.controllerFor('account-settings').set('pageTitle', 'General settings');
        }
    });
});