define('sportly/controllers/football-game/create/add-venue', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({

        queryParams: ['latitude', 'longitude', 'zoom', 'query'],

        latitude: 0,
        longitude: 0,
        zoom: 13,
        venueId: null,
        query: '',

        latitudeStart: 0,
        latitudeFinish: 0,
        longitudeStart: 0,
        longitudeFinish: 0,

        markerIconOptions: {
            'marker-size': 'large',
            'marker-symbol': 'hospital',
            'marker-color': '51a351'
        },
        markerOptions: {
            draggable: true,
            zIndexOffset: 9999
        },

        searchQuery: '',
        isLocatingUser: false,

        places: _ember['default'].computed('model', function () {
            return [this.get('model')];
        }),

        actions: {
            onMapMove: function onMapMove(props) {
                this.setProperties({
                    'latitude': props.latitude,
                    'longitude': props.longitude,
                    'zoom': props.zoom,
                    'latitudeStart': props.latitudeStart,
                    'latitudeFinish': props.latitudeFinish,
                    'longitudeStart': props.longitudeStart,
                    'longitudeFinish': props.longitudeFinish
                });
            },

            onSearch: function onSearch(query) {
                this.set('searchQuery', query);
            },

            onLocateUser: function onLocateUser() {
                this.set('isLocatingUser', true);
            }
        }
    });
});