define('sportly/routes/group', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        model: function model(params) {
            return this.store.query('group', {
                url: params.groupSegment
            }).then(function (groups) {
                return groups.get('firstObject');
            });
        },

        afterModel: function afterModel(group) {
            return group.get('members').then(function (members) {
                return _ember['default'].RSVP.all(members.mapBy('user'));
            }).then(function () {
                return group.get('games');
            }).then(function () {
                return group.get('venue');
            }).then(function () {
                return group.get('pendingInvites');
            }).then(function (pendingInvites) {
                return _ember['default'].RSVP.all(pendingInvites.mapBy('receiver'));
            });
        },

        setupController: function setupController(controller, group) {
            controller.set('model', group);
            if (controller.get('isNoob') && controller.get('isAdmin')) {
                this.transitionTo('group.invite', group);
            }
        },

        serialize: function serialize(group) {
            return { groupSegment: _ember['default'].get(group, "url") };
        },

        actions: {

            ignoreInvites: function ignoreInvites() {
                this.get('controller.myPendingInvites').forEach(function (invite) {
                    invite.set('status', 2);
                    invite.save();
                });
            }
        }

    });
});