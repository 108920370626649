define('sportly/components/x-utils/spinner', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['x-utils--spinner'],
    attributeBindings: ['style'],

    width: '50px',
    height: '50px',
    borderWidth: '3px',
    color: '#fff',

    style: _ember['default'].computed('width', 'height', 'borderWidth', 'color', function () {
      var width = this.get('width');
      var height = this.get('height');
      var borderWidth = this.get('borderWidth');
      var color = this.get('color');
      return new _ember['default'].Handlebars.SafeString("width: " + width + "; height: " + height + "; border: solid " + color + "; " + "; border-width: " + borderWidth + "  0px 0px 0px;");
    })
  });
});