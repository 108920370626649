define('sportly/components/x-stories/stories', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-stories--stories', 'sessionCanPost'],
        source: null,
        timer: null,
        count: 20,
        perPage: 20,
        interval: 30000,
        sessionCanPost: true,

        sourceUnwrapped: _ember['default'].computed('source', 'source.content', function () {
            if (!_ember['default'].isEmpty(this.get('source.content'))) {
                return this.get('source.content');
            }
            return this.get('source');
        }),

        stories: _ember['default'].computed('sourceUnwrapped.stories.[]', 'sourceUnwrapped.stories.@each.updated', 'count', function () {
            return this.get('sourceUnwrapped.stories').toArray().sort(function (a, b) {
                if (a.get('updated') === b.get('updated')) {
                    return b.get('id') - a.get('id');
                }
                return b.get('updated') - a.get('updated');
            }).slice(0, this.get('count'));
        }),

        hasMore: _ember['default'].computed('sourceUnwrapped.stories.length', 'count', function () {
            return this.get('count') < this.get('sourceUnwrapped.stories.length');
        }),

        didInsertElement: function didInsertElement() {
            var _this = this;
            this.set('timer', setInterval(function () {
                _this.get('sourceUnwrapped').reload();
            }, this.get('interval')));
            this.get('sourceUnwrapped').reload();
        },

        willDestroyElement: function willDestroyElement() {
            clearInterval(this.get('timer'));
        },

        actions: {
            showMore: function showMore() {
                this.incrementProperty('count', this.get('perPage'));
            }
        }
    });
});