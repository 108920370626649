define('sportly/routes/dashboard', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        session: _ember['default'].inject.service('session'),

        beforeModel: function beforeModel(transition) {
            var user = this.get('session.user'),
                profile = null;

            this._super(transition);

            return user.then(function (user) {
                return user.get('profile');
            }).then(function (fulfilled) {
                profile = fulfilled;
                return user.get('gameMembers');
            }).then(function (gameMembers) {
                return _ember['default'].RSVP.all(gameMembers.mapBy('footballGame'));
            }).then(function () {
                return user.get('groupMembers');
            }).then(function (groupMembers) {
                return _ember['default'].RSVP.all(groupMembers.mapBy('group'));
            }).then(function () {
                return user.get('stories');
            }).then(function () {
                return _ember['default'].RSVP.all([profile.get('photo'), user.get('followers'), user.get('following')]);
            });
        },

        model: function model() {
            return this.get('session.user.profile');
        },

        afterModel: function afterModel(profile) {
            if (profile.get('onboarding') === 1) {
                this.transitionTo('welcome.index');
            }
        },

        actions: {

            dismissIntro: function dismissIntro() {
                this.get('session.user.profile').set('isNoob', false);
                this.get('session.user.profile').save();
            }

        }
    });
});