define('sportly/components/x-global/page', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNameBindings: [':x-global--page', 'hasOverlay', 'isShiftedOut'],
        hasOverlay: false,
        isShiftedOut: false,

        click: function click(e) {

            if (_ember['default'].$(e.target).closest('.global-menu-trigger').length > 0) {
                return;
            }

            if (this.get('isShiftedOut')) {
                this.sendAction('toggleSideMenu');
                e.preventDefault();
            }
        },

        actions: {
            toggleSideMenu: function toggleSideMenu() {
                this.sendAction('toggleSideMenu');
            }
        }
    });
});