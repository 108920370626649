define('sportly/services/maps', ['exports', 'ember', 'sportly/config/environment'], function (exports, _ember, _sportlyConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({

    accessToken: _sportlyConfigEnvironment['default'].APP['mapbox-access-token'],

    staticMap: function staticMap(latitude, longitude, width, height, pinColour) {

      return 'https://api.mapbox.com/v4/mapbox.streets/' + 'pin-l+' + pinColour + '(' + longitude + ',' + latitude + '/' + longitude + ',' + latitude + ',15/400x200@2x.png' + '?access_token=' + this.get('accessToken');
    }
  });
});