define('sportly/routes/group/edit-admins', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember', 'sportly/enums/group/status'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember, _sportlyEnumsGroupStatus) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    beforeModel: function beforeModel(transition) {
      this._super(transition);
      return this.modelFor('group').get('members').then(function (members) {
        return _ember['default'].RSVP.all(members.mapBy('user'));
      });
    },

    model: function model() {
      return this.modelFor('group').get('members');
    },

    afterModel: function afterModel() {
      if (this.modelFor('group').get('status') !== _sportlyEnumsGroupStatus['default'].ACTIVE) {
        return this.transitionTo('group.index');
      }
    },

    setupController: function setupController(controller, members) {
      controller.set('model', members);
      if (!this.controllerFor('group').get('isAdmin')) {
        this.transitionTo('group.index');
      }
    },

    actions: {

      willTransition: function willTransition() {
        this.get('controller.content').filterBy('hasDirtyAttributes').invoke('rollbackAttributes');
        return true;
      }
    }

  });
});