define('sportly/routes/loading', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        activate: function activate() {
            this.controllerFor('application').set('isLoading', true);
        },

        deactivate: function deactivate() {
            this.controllerFor('application').set('isLoading', false);
        }
    });
});