define('sportly/components/x-football-games/settings', ['exports', 'ember', 'sportly/enums/football-game/member-role'], function (exports, _ember, _sportlyEnumsFootballGameMemberRole) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-football-games--settings'],
        session: _ember['default'].inject.service('session'),
        footballGame: null,

        notCancelled: _ember['default'].computed.not('footballGame.isCancelled'),
        notFinished: _ember['default'].computed.not('footballGame.isFinished'),
        isActive: _ember['default'].computed.and('notCancelled', 'notFinished'),
        showSettings: _ember['default'].computed.and('isActive', 'sessionIsAdmin'),

        sessionIsAdmin: _ember['default'].computed('footballGame.members.@each.roleId', 'footballGame.members.@each.user', 'session.user.id', function () {
            var userId = this.get('session.user.id');
            return this.get('footballGame.members').filterBy('roleId', _sportlyEnumsFootballGameMemberRole['default'].ADMIN).any(function (member) {
                return member.get('user.id') === userId;
            });
        })
    });
});