define("sportly/templates/football-game", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "sportly/templates/football-game.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("	");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n		");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n	");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          morphs[2] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["page container football-game ", ["subexpr", "if", [["get", "model.isCancelled", ["loc", [null, [4, 47], [4, 64]]]], "is-cancelled"], [], ["loc", [null, [4, 42], [4, 81]]]], " ", ["subexpr", "if", [["get", "isHeaderHidden", ["loc", [null, [4, 87], [4, 101]]]], "header-is-hidden"], [], ["loc", [null, [4, 82], [4, 122]]]]]]], ["inline", "x-football-games/header", [], ["footballGame", ["subexpr", "@mut", [["get", "model", ["loc", [null, [7, 16], [7, 21]]]]], [], []], "join", "join", "attend", "attend", "decline", "decline", "changeRSVP", "changeRSVP"], ["loc", [null, [6, 2], [12, 4]]]], ["content", "outlet", ["loc", [null, [14, 2], [14, 12]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 18
          }
        },
        "moduleName": "sportly/templates/football-game.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "x-global/navs", [], ["pageTitle", ["subexpr", "@mut", [["get", "pageTitle", ["loc", [null, [2, 11], [2, 20]]]]], [], []]], 0, null, ["loc", [null, [1, 0], [17, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});