define('sportly/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsApplicationRouteMixin, _ember) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], {

    session: _ember['default'].inject.service('session'),

    model: function model() {
      if (this.get('session.isAuthenticated')) {
        return this.get('session.user');
      }
    },

    afterModel: function afterModel(user, transition) {
      var _this = this;
      if (!_ember['default'].isEmpty(user)) {
        return user.get('profile').then(function (profile) {
          if (profile.get('onboarding') === 1) {
            if (transition.targetName.split('.')[0] !== 'welcome') {
              _this.transitionTo('welcome.index');
            }
          }
        });
      }
    },

    setupController: function setupController(controller) {
      this.router.on('didTransition', function () {
        if (!controller.get('isTransitioningBack')) {
          window.scrollTo(0, 0);
        } else {
          controller.set('isTransitioningBack', false);
        }
      });
    }

  });
});