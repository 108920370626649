define('sportly/routes/group/fixtures', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember'], function (exports, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _ember) {
    exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

        model: function model() {

            return this.modelFor('group').get('games').then(function (games) {
                var now = moment().format('X');
                return games.filter(function (game) {
                    return game.get('timestamp') > now;
                });
            });
        },

        afterModel: function afterModel(fixtures) {
            return _ember['default'].RSVP.all([fixtures.mapBy('homeTeam'), fixtures.mapBy('awayTeam'), fixtures.mapBy('members'), fixtures.mapBy('creator'), fixtures.mapBy('group')]);
        }

    });
});