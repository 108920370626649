define('sportly/controllers/account-settings/edit-general', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Controller.extend({
        genders: _ember['default'].computed(function () {
            return [{ label: 'Male', value: 'm' }, { label: 'Female', value: 'f' }];
        }),

        dobDays: _ember['default'].computed('model.dobMonth', 'model.dobYear', function () {
            var daysInMonth = null,
                days = [];

            daysInMonth = moment('YYYY-01', 'YYYY-MM').daysInMonth();
            if (!_ember['default'].isEmpty(this.get('model.dobYear')) && !_ember['default'].isEmpty(this.get('model.dobMonth'))) {
                daysInMonth = moment(this.get('model.dobYear') + '-' + this.get('model.dobMonth'), 'YYYY-MM').daysInMonth();
            }

            for (var day = 1; day <= daysInMonth; day++) {
                days.pushObject({ label: day, value: day });
            }
            return days;
        }),

        dobMonths: _ember['default'].computed(function () {
            return [{ label: 'January', value: 1 }, { label: 'February', value: 2 }, { label: 'March', value: 3 }, { label: 'April', value: 4 }, { label: 'May', value: 5 }, { label: 'June', value: 6 }, { label: 'July', value: 7 }, { label: 'August', value: 8 }, { label: 'September', value: 9 }, { label: 'October', value: 10 }, { label: 'November', value: 11 }, { label: 'December', value: 12 }];
        }),

        dobYears: _ember['default'].computed(function () {
            var years = [],
                lastYear = moment().format('YYYY') - 1;
            for (var year = lastYear; year > 1900; year--) {
                years.pushObject({
                    label: year, value: year
                });
            }
            return years;
        }),

        hasDobError: _ember['default'].computed('model.errors.dobDay', 'model.errors.dobMonth', 'model.errors.dobYear', function () {
            return !_ember['default'].isEmpty(this.get('model.errors.dobDay')) || !_ember['default'].isEmpty(this.get('model.errors.dobMonth')) || !_ember['default'].isEmpty(this.get('model.errors.dobyear'));
        }),

        actions: {
            changeGender: function changeGender(gender) {
                this.set('model.gender', gender);
            }
        }
    });
});