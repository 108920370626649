define('sportly/components/x-profiles/profile-thumb', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        classNames: ['x-profiles--profile-thumb'],
        user: null,
        profile: _ember['default'].computed.alias('user.profile'),
        photo: _ember['default'].computed.alias('profile.photo.square'),

        name: _ember['default'].computed('profile.firstName', 'profile.lastName', function () {
            return this.get('profile.firstName') + ' ' + this.get('profile.lastName');
        })

    });
});